import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '@components/Layout'
import { connect } from 'react-redux'
import { iAppState } from '../../configureReducer'
import { iConfig } from '../../containers/configStore'

interface IProps {
  config: iConfig
}

class Contacts extends React.Component<IProps> {

  render() {
    const { basicConfig } = this.props.config
    return (
      <Layout>
        <div className="about">
          <h2>Contact Us?</h2>
          <p>
            <label className="item-left">Sales:</label>
            <label className="text-obvious">{basicConfig.serviceEmail}</label>
          </p>
          <p>
            <label className="item-left">Accountant:</label>
            <label className="text-obvious">accountant@motarro.nz</label>
          </p>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = (state: iAppState) => ({
  config: state.config
})

export default connect(mapStateToProps)(Contacts)