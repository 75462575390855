import React from 'react'
import request from '../util/request'
import { API_URL } from '../config'
import { Link, RouteComponentProps } from 'react-router-dom'
import Loading from '../common/loading/index'
import { isEmailFormatValid } from '../util/util'
import { iConfig } from '../containers/configStore'

interface IProps extends RouteComponentProps{
  setLoading: (loading: boolean) => void,
  config: iConfig,
  controlModal: (showModal: boolean, message: string| JSX.Element) => void
}

interface IState {
  [key: string]: string | boolean
  firstName: string,
  surName: string,
  cellPhone: string,
  email: string,
  password: string,
  confirmPassword: string,
  errorMessage: string,
  accepted: boolean
}

export default class Register extends React.Component<IProps,IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      firstName: '',
      surName: '',
      cellPhone: '',
      email: '',
      password: '',
      confirmPassword: '',
      companyName: '',
      tradingName: '',
      companyNumber: '',
      NZBN: '',
      phoneNumber: '',
      deliveryAddress: '',
      postalAddress: '',
      errorMessage: '',
      accepted: false
    }
  }

  bindState = (label: keyof IState) => {
    let that = this
    return function(e: React.ChangeEvent<HTMLInputElement>) {
      let value = e.target.value
      if (label !== 'accepted') {
        that.setState({[label]: value, errorMessage: ''} as Pick<IState, typeof label>)
      }
    }
  }

  checkInput = () => {
    let errorMessage: string = ''
    for (let key in this.state) {
      if (key !== 'errorMessage' && key !== 'accepted') {
        if (this.state[key] === '') {
          errorMessage = key + ' cannot be blank'
          return errorMessage
        }
      }
    }
    if (!isEmailFormatValid(this.state.email)) {
      errorMessage = 'Invalid email format'
    }
    if (this.state.password !== this.state.confirmPassword) {
      errorMessage = 'password and confirm password differ'
    }
    return errorMessage
  }

  onRegisterClick = () => {
    let that = this
    let errorMessage: string = that.checkInput()
    if (errorMessage) {
      that.setState({errorMessage: errorMessage})
      return
    }
    if (!that.state.accepted) {
      that.setState({errorMessage: 'Please accept and check the terms'})
      return
    }
    that.props.setLoading(true)
    request(
      `${API_URL}/basic/wholesale/register`,
      {
        method: "POST",
        body: JSON.stringify({
          firstName: that.state.firstName,
          surName: that.state.surName,
          cellPhone: that.state.cellPhone,
          email: that.state.email,
          password: that.state.password,
          companyName: that.state.companyName,
          tradingName: that.state.tradingName,
          companyNumber: that.state.companyNumber,
          NZBN: that.state.NZBN,
          phoneNumber: that.state.phoneNumber,
          deliveryAddress: that.state.deliveryAddress,
          postalAddress: that.state.postalAddress,
        })
      }
    ).then(function() {
      let promptMessage = (<span className='text_center'><span>Well done!</span><br />
        Thanks for your wholesale user registration!<br />
        We will contact you in 5 working days.</span>)
      that.props.controlModal(true, promptMessage)
      that.props.setLoading(false)
      that.props.history.push('/login')
    }, function(resp) {
      let errorMessage = resp.data
      if (resp.data.indexOf('Traceback') != -1) {
        errorMessage = 'Sorry, please check your email.'
      }
      that.setState({errorMessage: errorMessage})
      that.props.setLoading(false)
    })
  }

  onCheckClick = () => {
    let accepted: boolean = !this.state.accepted
    this.setState({accepted: accepted})
  }

  render() {
    const { basicConfig } = this.props.config
    return (
      <div>
        <div className="bar bar-external">
          <Link className="bar__logo" to="/"></Link>
        </div>
        <div className="registerLogin">
          <div className="registerLogin__box">
            <div className="registerLogin__box__hop">Account? <Link to="/login">Login</Link></div>
            <h2>Register</h2>
            <div className="registerLogin__content">
              <div className="registerLogin__content__part">
                <div className="registerLogin__box__line">
                  <input type="text" placeholder="first name" onChange={this.bindState('firstName')} />
                </div>
                <div className="registerLogin__box__line">
                  <input type="text" placeholder="last name" onChange={this.bindState('surName')} />
                </div>
                <div className="registerLogin__box__line">
                  <input type="text" placeholder="cell phone" onChange={this.bindState('cellPhone')} />
                </div>
                <div className="registerLogin__box__line">
                  <input type="text" placeholder="email" onChange={this.bindState('email')} />
                </div>
                <div className="registerLogin__box__line">
                  <input type="password" placeholder="password" onChange={this.bindState('password')} />
                </div>
                <div className="registerLogin__box__line">
                  <input type="password" placeholder="confirm password" onChange={this.bindState('confirmPassword')} />
                </div>
              </div>
              <div className="registerLogin__content__part">
                <div className="registerLogin__box__line line-short">
                  <input type="text" placeholder="Company Name" onChange={this.bindState('companyName')} />
                </div>
                <div className="registerLogin__box__line line-short">
                  <input type="text" placeholder="Trading Name" onChange={this.bindState('tradingName')} />
                </div>
                <div className="registerLogin__box__line line-short">
                  <input type="text" placeholder="Company Number" onChange={this.bindState('companyNumber')} />
                </div>
                <div className="registerLogin__box__line line-short">
                  <input type="text" placeholder="NZBN" onChange={this.bindState('NZBN')} />
                </div>
                <div className="registerLogin__box__line line-short">
                  <input type="text" placeholder="Phone Number" onChange={this.bindState('phoneNumber')} />
                </div>
                <div className="registerLogin__box__line line-short">
                  <input type="text" placeholder="Delivery Address" onChange={this.bindState('deliveryAddress')} />
                </div>
                <div className="registerLogin__box__line line-short">
                  <input type="text" placeholder="Postal Address" onChange={this.bindState('postalAddress')} />
                </div>
              </div>
            </div>
            <div className="registerLogin__box__terms">
              <input id="accept" type="checkbox" checked={this.state.accepted}
                onClick={this.onCheckClick} />
              <label htmlFor="accept">I have read and accept the<Link to="/terms" className="text-obvious" target="_blank">User Agreement and Terms</Link></label>
            </div>
            <div className="button" onClick={this.onRegisterClick}>Register</div>
            <p className="error">{this.state.errorMessage || ''}</p>
          </div>
        </div>
        <Loading />
        {
          basicConfig.closeRegister === 'true'
          ? <div>
              <div className="modal">
                <div className="modal-body">
                  <p className="reminder-text">Sorry, we temporarily close the registration, will open soon!</p>
                  <p className="reminder-text"><Link to="/">Back to Home page.</Link></p>
                </div>
              </div>
              <div className="modal-backdrop"></div>
            </div> : ''
        }
      </div>
    )
  }
}