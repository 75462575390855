import React from 'react'

export default class BackToTop extends React.Component {
  goBackToTop() {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    })
  }
  render() {
    return (
      <div className="back-to-top" onClick={this.goBackToTop}>
        <span className="icon-chevron-circle-up"></span>
      </div>
    )
  }
}