import React from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'

import Layout from '@components/Layout'
import UserLayout from './UserLayout'

class User extends React.Component<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props)
  }

  render() {
    const { location } = this.props
    let currentMenu: string = location.pathname.split('/')[2]
    return (
      <Layout>
        <div className="user">
          <div className="list user__list">
            <div className="user__list__title">
              <span className="icon-user"></span>
              <label>User Center</label>
            </div>
            <h3>Account</h3>
            <ul>
              <li>
                <Link to='/user/userInfo' className={currentMenu == 'userInfo' ? 'current' : ''}>
                  My Account
                </Link>
              </li>
              <li>
                <Link to='/user/password' className={currentMenu == 'password' ? 'current' : ''}>
                  Modify Password
                </Link>
              </li>
            </ul>
            <h3>Order Center</h3>
            <li>
              <Link to='/user/order' className={currentMenu == 'order' ? ' current' : ''}>
                My Order
              </Link>
            </li>
            <li>
              <Link to="/user/address" className={currentMenu == 'address' ? 'current' : ''}>
                My Address
              </Link>
            </li>
          </div>
          <UserLayout />
        </div>
      </Layout>
    )
  }
}

export default withRouter(User)