import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../../../configureReducer'
import { getUserInfo } from './action'
import UserInfo from './UserInfo'

const mapStateToProps = (state: iAppState) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators ({
  getUserInfo
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo)