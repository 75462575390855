import React from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import request from '../util/request'
import { API_URL } from '../config'
import { Link, RouteComponentProps } from 'react-router-dom'
import { ILoginResponse } from '../types/response'
import Loading from '../common/loading/index'
import { iAppState } from '../configureReducer'
import { getUserInfo } from './user/userInfo/action'
import { setLoading } from '../common/loading/action'
import { getCartDetail } from './cart/action'
import GlobalModal from '../common/globalModal/GlobalModal'

export interface IProps extends RouteComponentProps {
  getUserInfo: () => void,
  setLoading: (loading: boolean) => void,
  getCartDetail: () => void
}

interface IState {
  email: string,
  password: string,
  serverError: string
}

class Login extends React.Component<IProps,IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      email: '',
      password: '',
      serverError: ''
    }
  }

  bindState = (label: keyof IState) => {
    let that = this;
    return function(e: React.ChangeEvent<HTMLInputElement>) {
      let value = e.target.value
      if (label !== 'serverError') {
        that.setState({[label]: value} as Pick<IState, typeof label>)
      }
    }
  }

  onLoginClick = () => {
    let that = this
    if (that.state.email === '') {
      that.setState({serverError: 'Email cannot be blank'})
      return
    }
    that.props.setLoading(true)
    request(
      `${API_URL}/basic/webstore/login`,
      {
        method: "POST",
        body: JSON.stringify({
          email: this.state.email,
          password: this.state.password
        })
      }
    ).then(function(resp: ILoginResponse) {
      if (!resp) {
        return
      }
      localStorage.setItem('token', resp.token)
      that.props.getUserInfo()
      that.props.getCartDetail()
      that.props.history.push('/')
      that.props.setLoading(false)
    }, function(resp) {
      console.log(resp)
      that.setState({serverError: resp.data.message})
      that.props.setLoading(false)
    })
  }

  onLoginKeyUp = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      this.onLoginClick()
    }
  }

  render() {
    return (
      <div>
        <div className="bar bar-external">
          <Link className="bar__logo" to="/"></Link>
        </div>
        <div className="registerLogin">
          <div className="registerLogin__box box_login">
            <div className="registerLogin__box__hop">No Account? <Link to="/register">Register</Link></div>
            <h2>Login</h2>
            <div className="registerLogin__box__line">
              <input type="text" placeholder="email" onChange={this.bindState('email')} />
            </div>
            <div className="registerLogin__box__line">
              <input type="password" placeholder="password" onChange={this.bindState('password')} onKeyUp={this.onLoginKeyUp} />
            </div>
            <div className="button" onClick={this.onLoginClick}>Login</div>
            <p className="error">{this.state.serverError}</p>
            <p className="registerLogin__box__foot">
              <Link to="/password/lost">Lost your password?</Link>
            </p>
          </div>
        </div>
        <Loading />
        <GlobalModal />
      </div>
    )
  }
}

const mapStateToProps = (state: iAppState) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators ({
  getUserInfo,
  setLoading,
  getCartDetail
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Login)