import React from 'react'
import Layout from '@components/Layout'

export default class StoreLocator extends React.Component {

  render() {
    return (
      <Layout>
        <div className="about">
          <h2>Store Locator</h2>
          <div className="about__block">
            <p className="about__obvious">Qstore HeadOffice and Warehouse:</p>
            <p>17 Grasslands Place, Frankton, Hamilton 3204</p>
          </div>
          <div className="about__block">
            <p className="about__obvious">Qstore Te Kuiti:</p>
            <p>9 King Street East, Te Kuiti 3910</p>
          </div>
          <div className="about__block">
            <p className="about__obvious">Qstore Taumarunui:</p>
            <p>95 Hakiaha Street, Taumarunui 3920</p>
          </div>
          <div className="about__block">
            <p className="about__obvious">Qstore Hawera:</p>
            <p>120 High Street, Hawera 4610</p>
          </div>
          <div className="about__block">
            <p className="about__obvious">Qstore Wanganui:</p>
            <p>197 Victoria Avenue, Whanganui 4500</p>
          </div>
          <div className="about__block">
            <p className="about__obvious">Qstore Levin:</p>
            <p>265 Oxford Street, Levin 5510</p>
          </div>
          <div className="about__block">
            <p className="about__obvious">Qstore Hastings:</p>
            <p>205 Avenue Road West, Hastings 4122</p>
          </div>
          <div className="about__block">
            <p className="about__obvious">Qstore Nelson:</p>
            <p>60 Achilles Avenue, Nelson 7010</p>
          </div>
          <div className="about__block">
            <p className="about__obvious">Qstore Christchurch:</p>
            <p>19/21 Marshland Road, Shirley, Christchurch 8061</p>
          </div>
        </div>
      </Layout>
    )
  }
}