import { IGET_ORDER_DETAILAction, GET_ORDER_DETAIL } from './action'
import { iOrder, iAddress, iOrderProductMap, iCoupon } from '../../../types'

export interface iOrderPart {
  orderInfo: iOrder,
  address: iAddress,
  coupon: iCoupon,
  orderProductMap: iOrderProductMap
}

export default (
  state = {orderInfo: {}, address: {}, coupon: null, orderProductMap: {}}, action: IGET_ORDER_DETAILAction
) => {
  switch (action.type) {
    case GET_ORDER_DETAIL:
      return Object.assign({}, state, {orderInfo: action.order,
        address: action.address,
        coupon: action.coupon,
        orderProductMap: action.orderProductMap
      })
    default:
      return state
  }
}