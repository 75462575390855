import React from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { iAppState } from '../../configureReducer'
import { controlModal, iGlobalModal } from './store'

interface IProps {
  globalModal: iGlobalModal,
  controlModal: (showModal: boolean, message: string) => void
}

class GlobalModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
  }

  render() {
    const { globalModal, controlModal } = this.props
    let showButton = true
    if (globalModal.message === 'Added') {
      showButton = false
    }
    return (
      <div>
      {
        globalModal.showModal ?
          <div>
            <div className="modal">
              <div className="modal-body">
                <p className="reminder-text">{globalModal.message}</p>
              </div>
              {
              showButton
              ? <div className="modal-footer">
                  <span className="button button-small" onClick={()=>
                    controlModal(false, '')}>OK</span>
                </div> : ''
              }
            </div>
            <div className="modal-backdrop"></div>
          </div> : ''
      }
      </div>
    )
  }
}

const mapStateToProps = (state: iAppState) => ({
  globalModal: state.globalModal
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators ({
  controlModal
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(GlobalModal)