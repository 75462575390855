import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import request from '../../util/request'
import { URL, API_URL } from '../../config'
import Layout from '@components/Layout'
import { iCartPart } from './reducer'
import { getSelectedCount, getAmount, getGst, getSubtotal, getSelectedTotal, getAmountAfterDis } from '../../util/calculation'
import { iUserPart } from '../user/userInfo/reducer'
import ShoppingNav from '@components/ShoppingNav'
import Loading from '../../common/loading/Loading'
import DeleteConfirm from '../../common/deleteConfirm/index'
import { getFixedQuantity, purchaseDisable } from '../../util/util'
import { displayPromotion } from '../../util/promotion'
import { iConfig } from '../configStore'

interface IProps extends RouteComponentProps {
  cart: iCartPart,
  getCartDetail: (page: string) => void,
  increment: (numberEachBatch: number, index: number) => void,
  decrement: (numberEachBatch: number, index: number) => void,
  setQuantity: (addQuantity: number, index: number) => void,
  getCartItemsCount: () =>void,
  cartItemsCount: number,
  onCheckClick: (index: number, isSetChecked: boolean) =>void,
  onAllCheckClick: (setTrue: boolean) => void,
  user: iUserPart,
  setCartLoading: (loading: boolean) => void,
  deleteCartItem: (cartItemId: number) => Promise<void>,
  controlDeleteModal: (onDelete: () => void, showModal: boolean) => void,
  config: iConfig
}

interface IState {
  showModal: boolean,
  modalType: string,
  fixedSKU: string
}

interface iModalMap {
  [key: string]: JSX.Element
}

export default class ShoppingCart extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    props.setCartLoading(true)
    props.getCartDetail('cart')
    props.getCartItemsCount()
    this.state = {
      showModal: false,
      modalType: '',
      fixedSKU: ''
    }
  }

  onPurQuantityChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, stock: number) => {
    const { basicConfig } = this.props.config
    let value = Number(e.target.value)
    if (isNaN(value)) {
      return
    }
    if (value === 0) {
      this.openModal('notZero')
      return
    }
    if (purchaseDisable(stock, value, Number(basicConfig.lowStockThreshold))) {
      this.openModal('notEnough')
      return
    }
    this.props.setQuantity(value, index)
    this.props.user.hasGotUserInfo ? this.shoppingCartUpdate(index) : this.props.getCartItemsCount()
  }

  onChangeButtonClick = (index: number, isPlus: boolean) => {
    const { decrement, increment, getCartItemsCount, cart, config } = this.props
    let { cartItems, cartProMap } = cart
    let currentProductID = cartItems[index].productID
    let stock = cartProMap[currentProductID].productBranch[0].quantity
    let numberEachBatch = cartProMap[currentProductID].numberEachBatch
    let addedQuantity = cartItems[index].addedQuantity
    let futureQuantity = isPlus ? (addedQuantity + numberEachBatch) : (addedQuantity - numberEachBatch)
    if (purchaseDisable(stock, futureQuantity, Number(config.basicConfig.lowStockThreshold))) {
      this.openModal('notEnough')
      return
    }
    if (futureQuantity <= 0) {
      this.openModal('few')
      return
    }
    isPlus ? increment(numberEachBatch, index) : decrement(numberEachBatch, index)
    this.props.user.hasGotUserInfo ? this.shoppingCartUpdate(index) : getCartItemsCount()
  }

  shoppingCartUpdate = (index: number) => {
    const { cart, setCartLoading, getCartItemsCount, onCheckClick } = this.props
    setCartLoading(true)
    request(
      `${API_URL}/basic/webstore/shoppingCart/update`,
      {
        method: "POST",
        body: JSON.stringify({
          id: cart.cartItems[index].id,
          addedQuantity: cart.cartItems[index].addedQuantity
        })
      }
    ).then(function() {
      getCartItemsCount()
      setCartLoading(false)
      onCheckClick(index, true)
    }, function() {
      window.alert('Update Failed')
      console.log(arguments)
      setCartLoading(false)
    })
  }

  onDelete = (index: number) => {
    let { cart, setCartLoading, getCartItemsCount, user, deleteCartItem } = this.props
    if (user.hasGotUserInfo) {
      setCartLoading(true)
      deleteCartItem(cart.cartItems[index].id).then(function() {
        cart.cartItems.splice(index, 1)
        getCartItemsCount()
        setCartLoading(false)
      }, function() {
        console.log(arguments)
      })
    } else {
      delete(cart.cartProMap[cart.cartItems[index].productID])
      cart.cartItems.splice(index, 1)
      getCartItemsCount()
    }
  }

  onDeleteClick = (index: number) => {
    this.props.controlDeleteModal(()=>this.onDelete(index), true)
  }

  turnToCheckout = () => {
    const { cart, config } = this.props
    let { cartItems, cartProMap } = cart
    let selectedCount: number = getSelectedCount(cartItems)
    let total = getSelectedTotal(cartItems, cartProMap)
    if (selectedCount === 0) {
      this.openModal('needSelect')
      return
    }
    if (Number(total) < Number(config.basicConfig.minimumPurchase)) {
      this.openModal('needMore')
      return
    }
    if (this.needFixQuantity()) {
      this.openModal('fixedPrompt')
      return
    }
    this.props.history.push('/checkout/detail')
  }

  needFixQuantity = () => {
    let that = this
    let { cartItems, cartProMap } = this.props.cart
    let lowStockThreshold = Number(this.props.config.basicConfig.lowStockThreshold)
    let needFixedQty = 0
    let fixedSKU = ''
    cartItems.map((item, index) => {
      let numberEachBatch = cartProMap[item.productID].numberEachBatch
      let stock = cartProMap[item.productID].productBranch[0].quantity
      let productCode = cartProMap[item.productID].productCode
      if (item.addedQuantity % numberEachBatch > 0) {
        needFixedQty ++
        let fixedQty = getFixedQuantity(stock, lowStockThreshold, item.addedQuantity, numberEachBatch)
        fixedSKU = (fixedSKU + (fixedSKU ? ',' : '') + `${productCode} from ${item.addedQuantity} to ${fixedQty}`)
        that.props.setQuantity(fixedQty, index)
        that.shoppingCartUpdate(index)
      }
    })
    if (needFixedQty > 0) {
      getSelectedCount(cartItems)
      that.setState({fixedSKU: fixedSKU})
      return true
    } else {
      return false
    }
  }

  openModal = (type: string) => {
    this.setState({showModal: true, modalType: type})
  }

  closeModal = () => {
    if (this.state.modalType === 'fixedPrompt') {
      this.props.history.push('/checkout/detail')
    }
    this.setState({showModal: false, modalType: ''})
  }

  hasDiscount = (promotionType: string, clubPrice: number) => {
    if (promotionType || clubPrice) {
      return true
    }
  }

  render() {
    const { cart, cartItemsCount, onCheckClick, onAllCheckClick, config } = this.props
    const modalMap: iModalMap = {
      needSelect: (
        <div className="modal-body">
          <p className="reminder-text">Please select at least 1 product, thanks!</p>
        </div>
      ),
      needMore: (
        <div className="modal-body">
          <p className="reminder-text">Sorry, minimum purchase of ${config.basicConfig.minimumPurchase} is required for shipping!</p>
          <p className="reminder-text">Maybe you can go to our offline stores, click <a>Store Locator</a> to find more.</p>
        </div>
      ),
      notEnough: (
        <div className="modal-body">
          <p className="reminder-text">Sorry, no enough stock!</p>
        </div>
      ),
      notZero: (
        <div className="modal-body">
          <p className="reminder-text">Sorry, at least 1 piece!</p>
        </div>
      ),
      few: (
        <div className="modal-body">
          <p className="reminder-text">Sorry, cannot be less than one pack!</p>
        </div>
      ),
      fixedPrompt: (
        <div className="modal-body">
          <p className="reminder-text">Please note quantity that does not comply with wholesale rules has been adjusted:</p>
          {this.state.fixedSKU.split(',').map((item) => (<p className="reminder-text">{item}</p>))}
        </div>
      )
    }
    let amountTotal = getSelectedTotal(cart.cartItems, cart.cartProMap)
    let addGst = getGst(Number(amountTotal))
    let lowStockThreshold = Number(config.basicConfig.lowStockThreshold)
    return (
      <Layout>
        <div className="cart">
          <ShoppingNav />
          <div className="table__line table__header">
            <div className="col-1">
              <input type="checkbox" checked={cart.allChecked} onClick={()=>onAllCheckClick(false)} />
            </div>
            <div className="col-3">Product</div>
            <div className="col-2">SKU</div>
            <div className="col-1">Price</div>
            <div className="col-2">Quantity</div>
            <div className="col-2">Total</div>
            <div className="col-1"></div>
          </div>
          {
            cart.cartItems.length > 0 && Object.keys(cart.cartProMap).length > 0
            && cart.cartItems.map((item, index) => {
              let cartProduct = cart.cartProMap[item.productID]
              let productStock = 0
              if (cartProduct.productBranch.length > 0) {
                productStock = cartProduct.productBranch[0].quantity
              }
              let isPurchaseDisable = purchaseDisable(productStock, item.addedQuantity,
                lowStockThreshold, cartProduct.pickupOnly)
              let productBranch = cartProduct.productBranch[0]
              let appliedClass = 'table__line table__detail'
              if (isPurchaseDisable) {
                appliedClass += ' line-disabled'
              }
              if (this.hasDiscount(productBranch.promotionType, cartProduct.clubPrice)) {
                appliedClass += ' line-higher'
              }
              return (
                <div key={index} className={appliedClass}>
                  <div className="row">
                    <div className="col-1 table__detail__normal">
                      <input type="checkbox" checked={item.checked} disabled={isPurchaseDisable}
                        onClick={()=>onCheckClick(index, false)} />
                    </div>
                    <div className="col-3 table__detail__name cart__detail__name">
                      <Link to={'/product/detail/' + cartProduct.productID} target="_blank">
                        {
                          cartProduct.picPath
                          ? <img src={URL + cartProduct.picPath} width="80" />
                          : <img src="../../styles/images/example-image.jpg" width="80" />
                        }
                        <span>{cartProduct.productName}</span>
                        {
                          isPurchaseDisable
                          ? ((cartProduct.pickupOnly && localStorage.getItem('branchID') === '')
                            ? <label>Pickup Only</label> :
                              <label>Out stock!
                                {
                                  (productStock > lowStockThreshold
                                    ? (' Quantity avalable: '
                                      + (productStock - lowStockThreshold)) : '')
                                }
                              </label>) : ''
                        }
                      </Link>
                    </div>
                    <div className="col-2 table__detail__normal">
                      {cartProduct.productCode}
                    </div>
                    <div className="col-1 table__detail__normal product__obvious">
                      ${cartProduct.productBranch.length > 0
                        ? cartProduct.productBranch[0].salePrice.toFixed(2)
                        : cartProduct.recommandPrice}
                    </div>
                    <div className="col-2 table__detail__normal">
                      <span className="product__quantity">
                        {
                          item.addedQuantity > 1
                          ? <span className="product__quantity__minus" onClick={()=>this.onChangeButtonClick(index, false)}>-</span>
                          : <span className="product__quantity__minus button-disabled">-</span>
                        }
                        <input type="text" value={item.addedQuantity} onChange={(e)=>
                          this.onPurQuantityChange(e, index, productStock)} />
                        <span className="product__quantity__plus" onClick={()=>
                          this.onChangeButtonClick(index, true)}>+</span>
                      </span>
                    </div>
                    <div className="col-2 table__detail__normal product__obvious">
                      <span className={this.hasDiscount(productBranch.promotionType,
                        cartProduct.clubPrice) ? 'product__price-deleted ' : ''}>
                          ${getAmount(item, cartProduct)}
                      </span>
                    </div>
                    <div className="col-1 table__detail__normal">
                      <span className="icon-trash-o icon__font" onClick={()=>this.onDeleteClick(index)}></span>
                    </div>
                  </div>
                  {
                    this.hasDiscount(productBranch.promotionType, cartProduct.clubPrice)
                    ? <div className="row">
                        <div className="col-9 table__detail-prompt">
                        {
                          cartProduct.clubPrice
                          ? ('Club Price: $' + cartProduct.clubPrice)
                          : displayPromotion(productBranch)
                        }
                        </div>
                        <div className="col-2 product__obvious">
                          ${getAmountAfterDis(item, cartProduct)}
                        </div>
                      </div> : ''
                  }
                </div>
              )
            })
          }
          <div className="cart__count">
            <div className="cart__count__selected">
              <span>{cartItemsCount || 0}</span>
              <label>Items in Total, Selected</label>
              <span>{getSelectedCount(cart.cartItems)}</span>
              <label>Items</label>
            </div>
            <div className="cart__count__total">
              <div className="total__detail">
                <div className="total__detail__line">
                  <label>Original Total:</label>
                  <span>${amountTotal}</span>
                </div>
                <div className="total__detail__line">
                  <label>Add GST:</label>
                  <span>${addGst}</span>
                </div>
                <div className="total__detail__line">
                  <label>Sub Total:</label>
                  <span>${getSubtotal(Number(amountTotal), Number(addGst))}</span>
                </div>
              </div>
              <div className="button" onClick={this.turnToCheckout}>CHECKOUT</div>
            </div>
          </div>
        </div>
        {
          this.state.showModal ?
          <div>
            <div className="modal">
              {modalMap[this.state.modalType]}
              <div className="modal-footer">
                <span className="button button-small" onClick={this.closeModal}>OK</span>
              </div>
            </div>
            <div className="modal-backdrop"></div>
          </div> : ''
        }
        <DeleteConfirm />
        <Loading loading={cart.loading} />
      </Layout>
    );
  }
}