import request from '../../../util/request'
import { API_URL } from '../../../config'
import { Dispatch } from 'redux'
import { iAddress } from '../../../types'
import { iAppState } from '../../../configureReducer'
import { iAddressCreateRes } from '../../../types/response'
import { setLoading } from '../../../common/loading/action'
import { isEmailFormatValid } from '../../../util/util'

export const CHANGE_ADDRESS_ITEM = "CHANGE_ADDRESS_ITEM"
export type CHANGE_ADDRESS_ITEM = typeof CHANGE_ADDRESS_ITEM

export const GET_EDITING_ITEM = "GET_EDITING_ITEM"
export type GET_EDITING_ITEM = typeof GET_EDITING_ITEM

export const CLEAR_ADDRESS_DETAIL = "CLEAR_ADDRESS_DETAIL"
export type CLEAR_ADDRESS_DETAIL = typeof CLEAR_ADDRESS_DETAIL

export interface ICHANGE_ADDRESS_ITEMAction {
  type: CHANGE_ADDRESS_ITEM,
  addressDetail: iAddress
}

export interface IGET_EDITING_ITEMAction {
  type: GET_EDITING_ITEM,
  addressDetail: iAddress,
  addressId: number
}

export interface ICLEAR_ADDRESS_DETAILAction {
  type: CLEAR_ADDRESS_DETAIL
}

export function changeAddressItem(label: keyof iAddress, value: string) {
  return (dispatch: Dispatch, getState: ()=> iAppState) => {
    const { address } = getState()
    let addressDetail: iAddress = Object.assign({}, address.addressDetail)
    if (label !== 'id' && label !== 'memberID' && label !== 'mouseOver') {
      addressDetail[label] = value
    }
    dispatch({
      type: CHANGE_ADDRESS_ITEM,
      addressDetail
    })
  }
}

interface iFieldMap {
  [key: string]: string
}

const expressField: iFieldMap = {
  firstName: 'First Name',
  lastName: 'Last Name',
  companyName: 'Trade Name',
  mobilePhone: 'Mobile Phone',
  email: 'Email',
  address: 'Delivery address',
  postcode: 'Postcode / ZIP',
  townOrCity: 'Town / City',
  stateOrCounty: 'State / County'
}

const pickupField: iFieldMap = {
  firstName: 'First Name',
  lastName: 'Last Name',
  mobilePhone: 'Mobile Phone',
  email: 'Email'
}

export function checkAddressInput() {
  return (dispatch: Dispatch, getState: ()=> iAppState) => {
    const { address } = getState()
    let addressDetail: iAddress = Object.assign({}, address.addressDetail)
    let errorMessage: string = ''
    let fieldMap: iFieldMap = expressField
    if (localStorage.getItem('branchID')) {
      fieldMap = pickupField
    }
    for (let key in fieldMap) {
      if (addressDetail[key] === '' || addressDetail[key] === null) {
        errorMessage = fieldMap[key] + ' cannot be blank'
        return errorMessage
      }
    }
    if (!isEmailFormatValid(addressDetail.email)) {
      errorMessage = 'Invalid email format'
      return errorMessage
    }
  }
}

export function saveAddress(addressId?: number) {
  return (dispatch: Dispatch, getState: ()=> iAppState): Promise<iAddressCreateRes> => {
    const { address } = getState()
    let addressDetail: iAddress = Object.assign({}, address.addressDetail)
    addressDetail.country = "New Zealand"
    if (addressId) {
      addressDetail.id = addressId
    }
    return request(
      addressId
        ? `${API_URL}/basic/webstore/shippingAddress/update`
        : `${API_URL}/basic/webstore/shippingAddress/create`,
      {
        method: "POST",
        body: JSON.stringify(addressDetail)
      }
    )
  }
}

export function getEditingItem(addressId: number) {
  return (dispatch: Dispatch) => {
    request(
      `${API_URL}/basic/webstore/shippingAddress/select?id=${addressId}`
    ).then((addressDetail: iAddress) => {
      dispatch(setLoading(false))
      dispatch({
        type: GET_EDITING_ITEM,
        addressDetail,
        addressId
      })
    }, function() {
      dispatch(setLoading(false))
    })
  }
}

export const clearAddressDetail = () : ICLEAR_ADDRESS_DETAILAction => ({
  type: CLEAR_ADDRESS_DETAIL
})

export type AddressAction = ICHANGE_ADDRESS_ITEMAction | IGET_EDITING_ITEMAction
  | ICLEAR_ADDRESS_DETAILAction