import request from '../../util/request'
import { API_URL } from '../../config'
import { Dispatch } from 'redux'
import { RouteComponentProps } from 'react-router-dom'
import { iProduct } from '../../types'
import { IProductListResponse } from '../../types/response'
import { iListRouterParams } from '../../types/router'
import { setLoading } from '../../common/loading/action'
import { iAppState } from '../../configureReducer'
import { condition as branchCondition } from '../branchStore'

export const GET_LIST_PRODUCTS = "GET_LIST_PRODUCTS"
export type GET_LIST_PRODUCTS = typeof GET_LIST_PRODUCTS

export interface IGET_LIST_PRODUCTSAction {
  type: GET_LIST_PRODUCTS,
  products: Array<iProduct>,
  curPage: string
  allPage: string
}

export function getListProducts(props: RouteComponentProps<iListRouterParams>) {
  return (dispatch: Dispatch) => {
    let condition = ''
    if (props.match) {
      const { params } = props.match
      let curPage = params.page || 1
      condition = condition + 'curPage=' + curPage
      if (params.selectString) {
        let selectString = params.selectString
        if (selectString.split('&')[0] === 'filter') {
          condition = condition + `&type=${selectString}`
        } else {
          let searchTarget = selectString.split('&')[1].split('=')[0]
          let searchText = selectString.split('&')[1].split('=')[1]
          condition = condition + `&type=search&keyWord=${searchTarget}&keyValue=${searchText}`
        }
      }
      if (params.sortString) {
        condition = condition + `&sortBy=${params.sortString}`
      }
    }
    dispatch(setLoading(true))
    request(
      `${API_URL}/basic/wholesale/productList/select?${condition}${branchCondition ? ('&' + branchCondition) : ''}`
    ).then((res: IProductListResponse) => {
      dispatch({
        type: GET_LIST_PRODUCTS,
        products: res.products,
        curPage: res.curPage,
        allPage: res.allPage
      })
      dispatch(setLoading(false))
    }, function() {
      dispatch(setLoading(false))
    })
  }
}

export function isClubPriceDisplay(activityStatus: string) {
  return (dispatch: Dispatch, getState: () => iAppState) => {
    const { user } = getState()
    let isClubPriceDisplay = false
    if (user.hasGotUserInfo) {
      if (user.userInfo.card.clubStatus === 'actived' && activityStatus === 'active') {
        isClubPriceDisplay = true
      }
    }
    return isClubPriceDisplay
  }
}

export type ListAction = IGET_LIST_PRODUCTSAction