import React from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { Dispatch, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { iAppState } from '../configureReducer'
import { iConfig, getBasicConfig } from '../containers/configStore'
import { iBranch, getBranches } from '../containers/branchStore'

interface IProps extends RouteComponentProps {
  config: iConfig,
  branch: iBranch,
  getBasicConfig: () => void,
  getBranches: () => void
}

interface IState {
  branchID: string
}

class Header extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    props.getBasicConfig()
    props.getBranches()
    this.state = {
      branchID: localStorage.getItem('branchID') || ''
    }
  }

  onBranchIDChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let value = event.target.value
    this.setState({branchID: value})
    localStorage.setItem('branchID', value)
    window.location.reload()
  }

  render() {
    const { basicConfig } = this.props.config
    const { branches } = this.props.branch
    let pickupBranch = basicConfig.pickupBranch && basicConfig.pickupBranch.split(',')
    return (
      <div className="header">
        <Link className="header__name" to="/">{basicConfig.storeDesc}</Link>
        <span className="header__socail">
          <a className="icon-facebook-f"></a>
          <a className="icon-instagram"></a>
          <a className="icon-youtube-play"></a>
        </span>
        <span className="header__action">
          <Link to="/contacts">Contact Us</Link>
        </span>
      </div>
    )
  }
}

const mapStateToProps = (state: iAppState) => ({
  config: state.config,
  branch: state.branch
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators ({
  getBranches,
  getBasicConfig
}, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))