import React from 'react'
import Layout from '@components/Layout'
import HomeCarousel from '@components/Carousel'
import { iHomePage } from './store'
import { iProduct } from '../../types'
import { iConfig } from '../configStore'
import ProductCard from '@components/ProductCard'
import { controlModal } from '../../common/globalModal/store'
import { iUserPart } from '../user/userInfo/reducer'

interface IProps {
  home: iHomePage,
  getCarousel: () => void,
  getNewArrivals: () => void,
  getHotProducts: () => void,
  addToCart: (productID: number, quantity: number) => void,
  config: iConfig,
  user: iUserPart
}

export default class Home extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    props.getCarousel()
    props.getNewArrivals()
    props.getHotProducts()
  }

  render() {
    const { home, config, user } = this.props
    return (
      <Layout>
        {
          config.basicConfig.announcement
          ? <div className="annoucement">{config.basicConfig.announcement}</div> : ''
        }
        <div className="home">
          <HomeCarousel />
          <div className="home__block">
            <div className="home__block__title">NEW ARRIVALS</div>
            <div className="home__block__detail">
              {
                home.newArrivals.map((product: iProduct) => (
                  <ProductCard product={product} config={config} controlModal={controlModal}
                    appliedStyleClass="content__products__detail block__product"
                    hasGotUserInfo={user.hasGotUserInfo} />
                ))
              }
            </div>
          </div>
          <div className="home__block">
            <div className="home__block__title">HOT PRODUCTS</div>
            <div className="home__block__detail">
              {
                home.hotProducts.map((product: iProduct) => (
                  <ProductCard product={product} config={config} controlModal={controlModal}
                    appliedStyleClass="content__products__detail block__product"
                    hasGotUserInfo={user.hasGotUserInfo} />
                ))
              }
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}