import request from '../../util/request'
import { API_URL } from '../../config'
import { Dispatch } from 'redux'
import { iProductDetail, iCartItem, iCartProMap } from '../../types'
import { iAppState } from '../../configureReducer'
import { iCartSelectResponse } from '../../types/response'
import { purchaseDisable } from '../../util/util'
import { setLoading } from '../../common/loading/action'
import { controlModal } from '../../common/globalModal/store'
import { getProductBranchNotInBranch } from '../branchStore'
import { setPrompt } from '../product/action'

export const GET_CART_DETAIL = "GET_CART_DETAIL"
export type GET_CART_DETAIL = typeof GET_CART_DETAIL

export const CART_QUANTITY_INCREMENT = "CART_QUANTITY_INCREMENT"
export type CART_QUANTITY_INCREMENT = typeof CART_QUANTITY_INCREMENT

export const CART_QUANTITY_DECREMENT = "CART_QUANTITY_DECREMENT"
export type CART_QUANTITY_DECREMENT = typeof CART_QUANTITY_DECREMENT

export const SET_CART_QUANTITY = "SET_CART_QUANTITY"
export type SET_CART_QUANTITY = typeof SET_CART_QUANTITY

export const CHECK_CLICK = "CHECK_CLICK"
export type CHECK_CLICK = typeof CHECK_CLICK

export const SET_CART_LOADING = "SET_CART_LOADING"
export type SET_CART_LOADING = typeof SET_CART_LOADING

export interface IGET_CART_DETAILAction {
  type: GET_CART_DETAIL,
  cartItems: Array<iCartItem>,
  cartProMap: iCartProMap
}

export function getCartDetail(page?: string) {
  return (dispatch: Dispatch<any>, getState: () => iAppState) => {
    const { cart } = getState()
    request(
      `${API_URL}/basic/webstore/shoppingCart/select`
    ).then((cartItems: iCartSelectResponse) => {
      dispatch(getCartProductMap(cartItems, page))
    }, function() {
      dispatch(getCartProductMap(cart.cartItems, page))
      console.log(arguments)
    })
  }
}

export function getCartProductMap(cartItems: Array<iCartItem>, page?: string) {
  return (dispatch: Dispatch<any>, getState: () => iAppState) => {
    const { user } = getState()
    let cartProMap: iCartProMap = {}
    let cartItemSelectPromise: Array<Promise<void>> = []
    cartItems.length > 0 && cartItems.map((item: iCartItem) => {
      cartItemSelectPromise.push(
        request(
          `${API_URL}/basic/wholesale/productBranch/select?productID=${item.productID}`
        ).then(function(product: iProductDetail) {
          if (product.productBranch.length === 0) {
            product.productBranch[0] = getProductBranchNotInBranch(product.recommandPrice)
          }
          if (user.hasGotUserInfo && user.userInfo.card.clubStatus ==='actived'
            && product.productBranch[0].activityStatus === 'active') {
              product.clubPrice = product.productBranch[0].activityDiscountPrice
            }
          cartProMap[product.productID] = product
        })
      )
    })
    Promise.all(cartItemSelectPromise).then(function() {
      dispatch({
        type: GET_CART_DETAIL,
        cartItems,
        cartProMap
      })
      cartItems.length > 0 && dispatch(allCheckClick(true))
      switch(page) {
        case 'cart':
          dispatch(setCartLoading(false))
          break
        case 'add':
          dispatch(setLoading(false))
          dispatch(controlModal(true, 'Added'))
          setTimeout(() => dispatch(controlModal(false, '')), 800)
          dispatch(setPrompt(''))
          break
      }
    }, function() {
      page === 'cart' && dispatch(setCartLoading(false))
      page === 'add' && dispatch(setLoading(false))
    })
  }
}

export interface IINCREMENTAction {
  type: CART_QUANTITY_INCREMENT,
  index: number,
  numberEachBatch: number
}

export interface IDECREMENTAction {
  type: CART_QUANTITY_DECREMENT,
  index: number,
  numberEachBatch: number
}

export interface ISETQUANTITYAction {
  type: SET_CART_QUANTITY,
  addedQuantity: number,
  index: number
}

export const increment = (numberEachBatch: number, index: number): IINCREMENTAction => ({
  type: CART_QUANTITY_INCREMENT,
  index,
  numberEachBatch
})

export const decrement = (numberEachBatch: number, index: number): IDECREMENTAction => ({
  type: CART_QUANTITY_DECREMENT,
  index,
  numberEachBatch
})

export const setQuantity = (addedQuantity: number, index: number): ISETQUANTITYAction => ({
  type: SET_CART_QUANTITY,
  addedQuantity,
  index
})

export interface ICHECK_CLICKAction {
  type: CHECK_CLICK,
  cartItems: Array<iCartItem>,
  allChecked: boolean
}

export function checkClick(index: number, isSetChecked: boolean) {
  return (dispatch: Dispatch, getState: () => iAppState) => {
    const { cart } = getState()
    let cartItems: Array<iCartItem> = [...cart.cartItems]
    if (isSetChecked) {
      cartItems[index].checked = true
    } else {
      cartItems[index].checked = cart.cartItems[index].checked ? false : true
    }
    let checkedQuantity: number = 0
    let allChecked: boolean = false
    cartItems.map((item) => {
      if (item.checked) {
        checkedQuantity ++
      }
    })
    if (checkedQuantity === cartItems.length) {
      allChecked = true
    }
    dispatch({
      type: CHECK_CLICK,
      cartItems,
      allChecked
    })
  }
}

export function allCheckClick(setTrue: boolean) {
  return (dispatch: Dispatch, getState: () => iAppState) => {
    const { cart, config } = getState()
    let cartProMap = Object.assign({}, cart.cartProMap)
    let cartItems: Array<iCartItem> = [...cart.cartItems]
    let allChecked: boolean = cart.allChecked
    allChecked = allChecked ? false : true
    if (setTrue) {
      allChecked = true
    }
    if (allChecked) {
      cartItems.map((item) => {
        let stock = cartProMap[item.productID].productBranch[0].quantity
        if (!purchaseDisable(stock, item.addedQuantity, Number(config.basicConfig.lowStockThreshold), cartProMap[item.productID].pickupOnly)) {
          item.checked = true
        }
      })
    } else {
      cartItems.map((item) => {
        item.checked = false
      })
    }
    dispatch({
      type: CHECK_CLICK,
      cartItems,
      allChecked
    })
  }
}

export function deleteCartItem(cartItemId: number) {
  return (dispatch: Dispatch) => {
    return request(
      `${API_URL}/basic/webstore/shoppingCart/delete?id=${cartItemId}`
    )
  }
}

export interface ISET_CART_LOADINGAction {
  type: SET_CART_LOADING,
  loading: boolean
}

export const setCartLoading = (loading: boolean): ISET_CART_LOADINGAction => ({
  type: SET_CART_LOADING,
  loading
})

export type CartAction = IGET_CART_DETAILAction | IINCREMENTAction | IDECREMENTAction
  | ISETQUANTITYAction | ICHECK_CLICKAction | ISET_CART_LOADINGAction