import { Dispatch } from 'redux'
import { iAppState } from '../../configureReducer'

export const CONTROL_DELETE_MODAL = "CONTROL_DELETE_MODAL"
export type CONTROL_DELETE_MODAL = typeof CONTROL_DELETE_MODAL

export interface ICONTROL_DELETE_MODALAction {
  type: CONTROL_DELETE_MODAL,
  onDelete: () => void,
  showModal: boolean
}

export const controlDeleteModal = (onDelete: () => void, showModal: boolean): ICONTROL_DELETE_MODALAction => ({
  type: CONTROL_DELETE_MODAL,
  onDelete,
  showModal
})    

export function confirmDelete() {
  return (dispatch: Dispatch, getState: () => iAppState) => {
    const { deleteModal } = getState()
    deleteModal.onDelete()
    dispatch(controlDeleteModal(() => {}, false))
  }
}

export interface iDeleteModal {
  onDelete: () => void,
  showModal: boolean
}

export default (
  state = {onDelete: null, showModal: false}, action: ICONTROL_DELETE_MODALAction
) => {
  switch (action.type) {
    case CONTROL_DELETE_MODAL:
      return Object.assign({}, state, {onDelete: action.onDelete, showModal: action.showModal})
    default:
      return state
  }
}