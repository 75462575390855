import React from 'react'
import Layout from '@components/Layout'
import { connect } from 'react-redux'
import { iAppState } from '../../configureReducer'
import { iConfig } from '../../containers/configStore'

interface IProps {
  config: iConfig
}

class PaymentEnquiries extends React.Component<IProps> {

  render() {
    const { basicConfig } = this.props.config
    return (
      <Layout>
        <div className="about">
          <h2>Payment Enquiries</h2>
          <div className="about__block">
            <p>Pay in advance before delivery, or sign as a wholesale contract.</p>
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = (state: iAppState) => ({
  config: state.config
})

export default connect(mapStateToProps)(PaymentEnquiries)