import request from '../../util/request'
import { API_URL } from '../../config'
import { iCartItem, iCartProMap } from '../../types'
import { Dispatch } from 'redux'
import { iAppState } from '../../configureReducer'

export const SET_CHECKOUT_DETAIL = "SET_CHECKOUT_DETAIL"
export type SET_CHECKOUT_DETAIL = typeof SET_CHECKOUT_DETAIL

export interface ISET_CHECKOUT_DETAILAction {
  type: SET_CHECKOUT_DETAIL,
  checkoutItems: Array<iCartItem>,
  checkoutProMap: iCartProMap
}

export function setCheckoutDetail() {
  return (dispatch: Dispatch, getState: () => iAppState) => {
    const { cart } = getState()
    let cartItems: Array<iCartItem> = [...cart.cartItems]
    let cartProMap: iCartProMap = Object.assign({}, cart.cartProMap)
    let checkoutItems: Array<iCartItem> = []
    let checkoutProMap: iCartProMap = {}
    cartItems.map((item) => {
      if (item.checked) {
        checkoutItems.push(item)
        checkoutProMap[item.productID] = cartProMap[item.productID]
      }
    })
    dispatch({
      type: SET_CHECKOUT_DETAIL,
      checkoutItems,
      checkoutProMap
    })
  }
}

export function getCoupon(couponCode: string) {
  return (dispatch: Dispatch) => {
    return request(
      `${API_URL}/basic/webstore/coupon/select?couponCode=${couponCode}`
    )
  }
}

export function getMemberDis() {
  return (dispatch: Dispatch, getState: () => iAppState) => {
    const { user } = getState()
    let discount = user.hasGotUserInfo ? user.userInfo.card.discount : 0
    return discount
  }
}
