import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../../configureReducer'
import { getListProducts, isClubPriceDisplay } from './action'
import { addToCart } from '../product/action'
import { setLoading } from '../../common/loading/action'
import List from './List'

const mapStateToProps = (state: iAppState) => ({
  list: state.list,
  config: state.config,
  user: state.user
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators<any, {}> ({
  getListProducts,
  addToCart,
  setLoading,
  isClubPriceDisplay
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(List)