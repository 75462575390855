import request from '../../util/request'
import { API_URL } from '../../config'
import { Dispatch } from 'redux'
import { iPaymentResponse } from '../../types/response'
import { setLoading } from '../../common/loading/action'
import { iAddress } from '../../types'

export const DIRECT_TO_PAYMENT = "DIRECT_TO_PAYMENT"
export type DIRECT_TO_PAYMENT = typeof DIRECT_TO_PAYMENT

export const GET_PAYMENT_RESPONSE = "GET_PAYMENT_RESPONSE"
export type GET_PAYMENT_RESPONSE = typeof GET_PAYMENT_RESPONSE

export interface IDIRECT_TO_PAYMENTAction {
  type: DIRECT_TO_PAYMENT
}

export function directToPayment(receiptNumber: string, amountTotal: number, address?: iAddress) {
  return (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    request(
      `${API_URL}/basic/webstore/getPaymentPage`,
      {
        method: "POST",
        body: JSON.stringify({
          billCreateType: 'wholesale',
          TxnType: 'Purchase',
          MerchantReference: receiptNumber,
          AmountInput: amountTotal.toFixed(2),
          CurrencyInput: 'NZD',
          TxnData1: (address && address.lastName) || '',
          TxnData2: (address && address.address) || '',
          TxnData3: (address && address.mobilePhone) || '',
          EmailAddress: (address && address.email) || ''
        })
      }
    ).then(function(res: iPaymentResponse) {
      window.location.href = res.URI
    }, function() {
      console.log(arguments)
      dispatch(setLoading(false))
    })
  }
}

export interface IGET_PAYMENT_RESPONSEAction {
  type: GET_PAYMENT_RESPONSE,
  paymentRes: string
}

export function getPaymentResponse(receiptNumber: string, result: string) {
  return (dispatch: Dispatch) => {
    return request(
      `${API_URL}/basic/webstore/getPaymentResponse?receiptNumber=${receiptNumber}&result=${result}`
    )
  }
}
