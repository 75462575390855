import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../../../configureReducer'
import { getAddressList, deleteAddress, mouseChange } from './action'
import AddressList from './AddressList'
import { saveAddress, getEditingItem, clearAddressDetail, checkAddressInput } from '../address/action'
import { setLoading } from '../../../common/loading/action'
import { controlDeleteModal } from '../../../common/deleteConfirm/store'
import { iAddressCreateRes } from '../../../types/response'

const mapStateToProps = (state: iAppState) => ({
  addressList: state.addressList,
  address: state.address,
  loading: state.loading
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators<any, {
  getAddressList: () => void,
  saveAddress: (addressId?: number) => Promise<iAddressCreateRes>,
  getEditingItem: (addressId: number)=> void,
  clearAddressDetail: () => void,
  setLoading: (loading: boolean) => void,
  deleteAddress: (addressId: number) => void,
  onMouseChange: (index: number, mouseOver: boolean) => void,
  controlDeleteModal: (onDelete: () => void, showModal: boolean) => void,
  checkAddressInput: () => string
}>({
  getAddressList,
  saveAddress,
  getEditingItem,
  clearAddressDetail,
  setLoading,
  deleteAddress,
  onMouseChange: mouseChange,
  controlDeleteModal,
  checkAddressInput
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AddressList)