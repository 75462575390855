import React from 'react'
import { iAddressPart } from './reducer'
import Loading from '../../../common/loading/index'

interface IProps {
  address: iAddressPart,
  changeAddressItem: (label: string, value: string) => void
}

interface regionMap {
  [key: string]: string
}

export const regionMap: regionMap = {
  1: "Northland",
  2: "Auckland",
  3: "Waikato",
  4: "Bay of Plenty",
  5: "Gisborne",
  6: "Hawke's Bay",
  7: "Taranaki",
  8: "Manawatu-Wanganui",
  9: "Wellington",
  10: "Tasman",
  11: "Nelson",
  12: "Marlborough",
  13: "West Coast",
  14: "Canterbury",
  15: "Otago",
  16: "Southland"
}

export default class Address extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
  }

  render() {
    const { address, changeAddressItem } = this.props
    const { addressDetail } = address
    return (
      <div className="checkout__address">
        <div className="checkout__address__line">
          <div className="col-4">
            <label>First Name<span>*</span></label>
            <input type="text" value={addressDetail.firstName} onChange={(e)=>changeAddressItem('firstName', e.target.value)} />
          </div>
          <div className="col-4">
            <label>Last Name<span>*</span></label>
            <input type="text" value={addressDetail.lastName} onChange={(e)=>changeAddressItem('lastName', e.target.value)} />
          </div>
          <div className="col-4">
            <label>Company Trade Name<span>*</span></label>
            <input type="text" value={addressDetail.companyName} onChange={(e)=>changeAddressItem('companyName', e.target.value)} />
          </div>
        </div>
        <div className="checkout__address__line">
          <div className="col-4">
            <label>Phone<span>*</span></label>
            <input type="text" value={addressDetail.mobilePhone} onChange={(e)=>changeAddressItem('mobilePhone', e.target.value)} />
          </div>
          <div className="col-4 address__item">
            <label>Email<span>*</span></label>
            <input type="text" value={addressDetail.email} onChange={(e)=>changeAddressItem('email', e.target.value)} />
          </div>
          <div className="col-4 address__item">
            <label>Country</label>
            <input type="text" defaultValue="New Zealand" disabled />
          </div>
        </div>
        {
          localStorage.getItem('branchID') ? '' :
            <div className="checkout__address__line">
              <label>Delivery address (we do not deliver to p.o box)<span>*</span></label>
              <input type="text" className="input-long" value={addressDetail.address}
                onChange={(e)=>changeAddressItem('address', e.target.value)} placeholder="No. Street, Suburb (eg.)1 Test Street/Road, Mount Eden" />
            </div>
        }
        {
          localStorage.getItem('branchID') ? '' :
          <div className="checkout__address__line">
            <div className="col-4">
              <label>Town / City<span>*</span></label>
              <input type="text" value={addressDetail.townOrCity} onChange={(e)=>changeAddressItem('townOrCity', e.target.value)}
                placeholder="Auckland(eg.)" />
            </div>
            <div className="col-4">
              <label>State / County<span>*</span></label>
              <select value={addressDetail.stateOrCounty} onChange={(e)=>changeAddressItem('stateOrCounty', e.target.value)}>
                <option value="">Select an option...</option>
                {
                  Object.keys(regionMap).map((key) => (
                    <option value={key}>{regionMap[key]}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-4">
              <label>Postcode / ZIP<span>*</span></label>
              <input type="text" value={addressDetail.postcode} onChange={(e)=>changeAddressItem('postcode', e.target.value)} />
            </div>
          </div>
        }
        <Loading />
      </div>
    )
  }
}