import request from '../../../util/request'
import { API_URL } from '../../../config'
import { Dispatch } from 'redux'
import { iOrder, iAddress, iOrderProductMap, iProductDetail, iCoupon } from '../../../types'
import { setLoading } from '../../../common/loading/action'

export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL"
export type GET_ORDER_DETAIL = typeof GET_ORDER_DETAIL

export interface IGET_ORDER_DETAILAction {
  type: GET_ORDER_DETAIL,
  order: iOrder,
  address: iAddress,
  coupon: iCoupon
  orderProductMap: iOrderProductMap
}

export function getOrderDetail(receiptNumber: string) {
  return (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    request(
      `${API_URL}/basic/webstore/bill/select?receiptNumber=${receiptNumber}`
    ).then((order: iOrder) => {
      let orderProductMap: iOrderProductMap = {}
      let productSelectPromiseArray: Array<Promise<void>> = []
      order.billContents.map((item) => {
        productSelectPromiseArray.push(
          request(
            `${API_URL}/basic/wholesale/productBranch/select?productID=${item.productID}`
          ).then(function(product: iProductDetail) {
            orderProductMap[product.productID] = product
          })
        )
      })
      Promise.all(productSelectPromiseArray).then(function() {
        dispatch({
          type: GET_ORDER_DETAIL,
          order,
          address: order.address,
          coupon: order.coupon,
          orderProductMap
        })
        dispatch(setLoading(false))
      }, function() {
        dispatch(setLoading(false))
      })
    }, function() {
      dispatch(setLoading(false))
    })
  }
}

export function updateOrderStatus(receiptNumber: string, status: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true))
    return request(
      `${API_URL}/basic/webstore/bill/${status}`,
      {
        method: "POST",
        body: JSON.stringify({
          receiptNumber: receiptNumber
        })
      }
    ).then(function() {
      window.location.reload()
    }, function(resp) {
      console.log(resp)
      window.alert('Operate Failed')
      dispatch(setLoading(false))
    })
  }
}
