const CONTROL_MODAL = "CONTROL_MODAL"
type CONTROL_MODAL = typeof CONTROL_MODAL

export interface ICONTROL_MODALAction {
  type: CONTROL_MODAL,
  showModal: boolean,
  message: string | JSX.Element
}

export const controlModal = (showModal: boolean, message: string | JSX.Element): ICONTROL_MODALAction => ({
  type: CONTROL_MODAL,
  showModal,
  message
})


export interface iGlobalModal {
  showModal: boolean,
  message: string
}

export default (
  state = {showModal: false, message: ''}, action: ICONTROL_MODALAction
) => {
  switch (action.type) {
    case CONTROL_MODAL:
      return Object.assign({}, state, {showModal: action.showModal, message: action.message})
    default:
      return state
  }
}