import { iProductBranch, iOrderContent } from "../types"

export function displayPromotion(productBranch: iProductBranch | iOrderContent) {
  switch (productBranch.promotionType) {
    case 'discount':
      return 'All Store ' + productBranch.promotionSaleDiscount + '% off'
    case 'special':
      return 'Sepcial: ' + productBranch.promotionSaleSpecial + '% off'
    case 'buyNGiveM':
      return 'Buy ' + productBranch.promotionSaleBuyGiveN + ' get '
        + productBranch.promotionSaleBuyGiveM + ' free'
    case 'buyNDiscount':
      return 'Buy ' + productBranch.promotionSaleBuyDiscountN + ' get '
        + productBranch.promotionSaleBuyDiscountD + '% off'
    case 'buyGiveOther':
      return 'Buy ' + productBranch.buyGiveOtherBuyNum + ' get '
        + productBranch.buyGiveOtherGiveNum + ' other free'
  }
}