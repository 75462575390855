import React from 'react'
import Layout from '@components/Layout'

export default class DeliveryAndReturns extends React.Component {

  render() {
    return (
      <Layout>
        <div className="about">
          <h2>Shipping Prices & Information</h2>
          <div className="about__block">
            <p>MOTARRO is pleased to be able to offer a delivery service nationwide in New Zealand.</p>
            <p>On any orders over $1500 for all NZ.</p>
            <p>Once your order has been dispatched, you’ll receive an email from us with the link to track your delivery.</p>
            <ul>
                <li>
                  <label className="item-left">North Island:</label>
                  <label className="text-obvious">From 5 working days</label>
                </li>
                <li>
                  <label className="item-left">SouthIsland:</label>
                  <label className="text-obvious">From 7 working days</label>
                </li>
                <li>
                  <label className="item-left">Rural Delivery:</label>
                  <label className="text-obvious">Whether you are in the North Island or the South Island please allow up to an additional 2 working days for delivery.</label>
                </li>
                <li>
                  <label className="item-left">P.O Boxes:</label>
                  <label className="text-obvious">Please note that our courier is unable to deliver to P.O Boxes. Please provide a physical street address in New Zealand.</label>
                </li>
            </ul>
          </div>
          <div className="about__block">
            <h3>Delivery Terms</h3>
            <p>These time frames are, of course, subjected to product availability. Furthermore, from time to time, issus might arise which means we can’t meet these time frames but, if that’s the case, we will contact you to let you know as soon as possible.</p>
            <p>Goods will not be delivered on Saturday, Sunday, or public holidays. Please contact us to arrange urgent Saturday delivery.</p>
            <p>It is required that you put correct delivery address when you order as we will not be responsible for undelivered goods caused by customer error.</p>
          </div>
          <div className="about__block">
            <h3>Track an Order</h3>
            <p>Once your order has been dispatched, you’ll receive an email from us with the tracking number for delivery. You can track your parcel under my account if you have registered on our website with us or with the Track and Trace service.</p>
            <p>If you have not received or have lost your tracking number, please contact us on <label className="text-obvious">service@motarro.nz</label> and we'll be more than happy to help you with your query.</p>
          </div>
        </div>
      </Layout>
    )
  }
}