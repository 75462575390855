import React from 'react'
import request from '../util/request'
import { API_URL } from '../config'
import Layout from './Layout'
import { isEmailFormatValid } from '../util/util'

interface IProps {
  setLoading: (loading: boolean) => void,
  controlModal: (showModal: boolean, message: string) => void
}

interface IState {
  [key: string]: string
}

export default class PasswordLost extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      email: '',
      errorMessage: ''
    }
  }

  onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({email: e.target.value})
  }

  onResetClick = () => {
    let that = this
    if (this.state.email === '') {
      that.setState({errorMessage: 'Email cannot be blank'})
      return
    }
    if (!isEmailFormatValid(this.state.email)) {
      that.setState({errorMessage: 'Invalid email format'})
      return
    }
    that.props.setLoading(true)
    request(
      `${API_URL}/basic/webstore/password/sendResetLink?email=${this.state.email}`
    ).then(function() {
      that.props.controlModal(true, 'Send success! Please check your email, thanks')
      that.props.setLoading(false)
    }, function(resp) {
      console.log(resp)
      that.props.setLoading(false)
      that.setState({errorMessage: resp.data})
    })
  }

  render() {
    return (
      <Layout>
        <div className="password-reset">
          <div className="password-reset__title">Reset Password</div>
          <div className="password-reset__detail">
            <p>Lost your password? Please enter your email address. You will 
              receive a link to create a new password via email.</p>
            <label>Registered Email</label>
            <input type="text" value={this.state.email} onChange={this.onEmailChange} />
            <div className="button button-middle" onClick={this.onResetClick}>Reset Password</div>
            <p className="error">{this.state.errorMessage}</p>
          </div>
        </div>
      </Layout>
    )
  }
}