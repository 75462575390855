import React from 'react'
import request from '../../../util/request'
import { API_URL } from '../../../config'
import { iUserPart } from '../userInfo/reducer'

interface IProps {
  user: iUserPart,
  getUserInfo: () => void,
  setLoading: (loading: boolean) => void
}

interface IState {
  [key: string]: string
}

export default class Password extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    props.getUserInfo()

    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      errorMessage: ''
    }
  }

  bindState = (label: keyof IState) => {
    let that = this
    return function(e: React.ChangeEvent<HTMLInputElement>) {
      that.setState({[label]: e.target.value, errorMessage: ''})
    }
  }

  checkInput = () => {
    let errorMessage: string = ''
    for (let key in this.state) {
      if (key !== 'errorMessage') {
        if (this.state[key] === '') {
          errorMessage = key + ' cannot be blank'
          return errorMessage
        }
      }
    }
    if (this.state.newPassword !== this.state.confirmPassword) {
      errorMessage = 'New password and confirm password differ'
    }
    return errorMessage
  }

  onModifyClick = () => {
    let that = this
    let errorMessage: string = that.checkInput()
    if (errorMessage !== '') {
      that.setState({errorMessage: errorMessage})
      return
    }
    that.props.setLoading(true)
    request(
      `${API_URL}/basic/webstore/password/update`,
      {
        method: "POST",
        body: JSON.stringify({
          currentPassword: that.state.currentPassword,
          newPassword: that.state.newPassword
        })
      }
    ).then(function() {
      window.alert('Modify Success')
      that.setState({currentPassword: '', newPassword: '', confirmPassword: ''})
      that.props.setLoading(false)
    }, function(resp) {
      console.log(resp)
      that.setState({errorMessage: resp.data})
      that.props.setLoading(false)
    })
  }

  render() {
    const { user } = this.props
    const { member } = user.userInfo
    return (
      <div className="user__content">
        <div className="user__basic user__password">
          <div className="user__basic__line">
            <label>Current Account:</label>
            <span>{user.hasGotUserInfo ? member.email : ''}</span>
          </div>
          <div className="user__basic__line">
            <label>Current Password<span className="must">*</span>:</label>
            <input type="password" value={this.state.currentPassword} onChange={this.bindState('currentPassword')} />
          </div>
          <div className="user__basic__line">
            <label>New Password<span className="must">*</span>:</label>
            <input type="password" value={this.state.newPassword} onChange={this.bindState('newPassword')} />
          </div>
          <div className="user__basic__line">
            <label>Confirm Password<span className="must">*</span>:</label>
            <input type="password" value={this.state.confirmPassword} onChange={this.bindState('confirmPassword')} />
          </div>
          <div className="user__password__operation">
            <span className="button button-small" onClick={this.onModifyClick}>Modify</span>
          </div>
          <p className="error">{this.state.errorMessage}</p>
        </div>
      </div>
    )
  }
}