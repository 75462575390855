import { combineReducers } from 'redux'

import list, { iList } from './containers/list/reducer'
import category, { iCategoryPart } from './containers/category/reducer'
import product, { iProductPart } from './containers/product/reducer'
import cart, { iCartPart } from './containers/cart/reducer'
import cartItemsCount from './common/cartItemsCount/reducer'
import checkout, { iCheckoutPart } from './containers/checkout/reducer'
import orderList, { iOrderListPart } from './containers/user/orderList/reducer'
import order, { iOrderPart } from './containers/user/order/reducer'
import addressList, { iAddressListPart } from './containers/user/addressList/reducer'
import address, { iAddressPart } from './containers/user/address/reducer'
import user, { iUserPart } from './containers/user/userInfo/reducer'
import loading from './common/loading/reducer'
import deleteModal, { iDeleteModal } from './common/deleteConfirm/store'
import home, { iHomePage } from './containers/home/store'
import config, { iConfig } from './containers/configStore'
import globalModal, { iGlobalModal } from './common/globalModal/store'
import branch, { iBranch } from './containers/branchStore'


const rootReducer = combineReducers({
  list,
  category,
  product,
  cart,
  cartItemsCount,
  checkout,
  orderList,
  order,
  addressList,
  address,
  user,
  loading,
  deleteModal,
  home,
  config,
  globalModal,
  branch
})

export interface iAppState {
  list: iList,
  category: iCategoryPart,
  product: iProductPart,
  cart: iCartPart,
  cartItemsCount: number,
  checkout: iCheckoutPart,
  orderList: iOrderListPart,
  order: iOrderPart,
  addressList: iAddressListPart,
  address: iAddressPart,
  user: iUserPart,
  loading: boolean,
  deleteModal: iDeleteModal,
  home: iHomePage,
  config: iConfig,
  globalModal: iGlobalModal,
  branch: iBranch
}

export default rootReducer