import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../../configureReducer'
import { getCategories } from '../category/action'
import Menu from './Menu'

const mapStateToProps = (state: iAppState) => ({
  category: state.category
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators ({
  getCategories
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Menu)