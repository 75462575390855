import { iAddress } from '../../../types'
import { AddressAction, CHANGE_ADDRESS_ITEM, GET_EDITING_ITEM, CLEAR_ADDRESS_DETAIL } from './action'

export interface iAddressPart {
  addressDetail: iAddress,
  addressId: number
}

let blankAddressDetail = {
  firstName: '',
  lastName: '',
  mobilePhone: '',
  email: '',
  address: '',
  postcode: '',
  townOrCity: '',
  stateOrCounty: '',
  companyName: ''
}

export default (
  state = {addressDetail: blankAddressDetail, addressId: null}, action: AddressAction
) => {
  switch (action.type) {
    case CHANGE_ADDRESS_ITEM:
      return Object.assign({}, state, {addressDetail: action.addressDetail})
    case GET_EDITING_ITEM:
      return Object.assign({}, state, {addressDetail: action.addressDetail, addressId: action.addressId})
    case CLEAR_ADDRESS_DETAIL:
      return Object.assign({}, state, {addressDetail: blankAddressDetail, addressId: null})
    default:
      return state
  }
}