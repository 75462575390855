import { ProductAction, SET_PRODUCT_DETAIL, INCREMENT, DECREMENT, SET_VALUE, SET_PROMPT } from './action'
import { iProductDetail } from '../../types'

export interface iProductPart {
  productInfo: iProductDetail,
  addedQuantity: number,
  promptMessage: string
}

export default (
  state = {productInfo: {}, addedQuantity: 1, promotMesage: ''}, action: ProductAction
) => {
  switch (action.type) {
    case SET_PRODUCT_DETAIL:
      return Object.assign({}, state, {productInfo: action.product})
    case INCREMENT:
      return Object.assign({}, state, {addedQuantity: state.addedQuantity + action.numberEachBatch})
    case DECREMENT:
      return Object.assign({}, state, {addedQuantity: state.addedQuantity - action.numberEachBatch})
    case SET_VALUE:
      return Object.assign({}, state, {addedQuantity: action.addedQuantity})
    case SET_PROMPT:
      return Object.assign({}, state, {promptMessage: action.promptMessage})
    default:
      return state
  }
}