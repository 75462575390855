import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../configureReducer'
import PasswordLost from '@components/PasswordLost'
import { setLoading } from '../common/loading/action'
import { controlModal } from '../common/globalModal/store'

const mapStateToProps = (state: iAppState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators ({
  setLoading,
  controlModal
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PasswordLost)