import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../../configureReducer'
import { getCategories, onExpandClick } from './action'
import Category from './Category'
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state: iAppState) => ({
  category: state.category
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators ({
  getCategories,
  onExpandClick
}, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Category))