import request from '../../../util/request'
import { API_URL } from '../../../config'
import { Dispatch } from 'redux'
import { iUserInfo } from '../../../types'
import { iAppState } from '../../../configureReducer'

export const GET_USER_INFO = "GET_USER_INFO"
export type GET_USER_INFO = typeof GET_USER_INFO

export const CLEAR_USER_INFO = "CLEAR_USER_INFO"
export type CLEAR_USER_INFO = typeof CLEAR_USER_INFO

export interface IGET_USER_INFOAction {
  type: GET_USER_INFO,
  userInfo: iUserInfo
}

export interface ICLEAR_USER_INFOAction {
  type: CLEAR_USER_INFO
}

export function getUserInfo() {
  return (dispatch: Dispatch, getState: () => iAppState) => {
    const { user } = getState()
    if (user.hasGotUserInfo) {
      return
    }
    request(
      `${API_URL}/basic/webstore/userInfo/get`
    ).then((userInfo: iUserInfo) => {
      if (Object.keys(userInfo).length > 0) {
        dispatch({
          type: GET_USER_INFO,
          userInfo
        })
      }
    })
  }
}

export const clearUserInfo = () : ICLEAR_USER_INFOAction => ({
  type: CLEAR_USER_INFO
})

export type UserAction = IGET_USER_INFOAction | ICLEAR_USER_INFOAction
