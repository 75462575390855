import request from '../../util/request'
import { Dispatch } from 'redux'
import { API_URL } from '../../config'
import { iCartCountRes } from '../../types/response'
import { iAppState } from '../../configureReducer'

export const GET_CART_ITEMS_COUNT = "GET_CART_ITEMS_COUNT"
export type GET_CART_ITEMS_COUNT = typeof GET_CART_ITEMS_COUNT

export interface IGET_CART_ITEMS_COUNTAction {
  type: GET_CART_ITEMS_COUNT,
  cartItemsCount: number
}

export function getCartItemsCount() {
  return (dispatch: Dispatch, getState: () => iAppState) => {
    request(
      `${API_URL}/basic/webstore/shoppingCart/count`
    ).then(function(res: iCartCountRes) {
      dispatch({
        type: GET_CART_ITEMS_COUNT,
        cartItemsCount: res.count
      })
    }, () => {
      const { cart } = getState()
      let itemsCount: number = 0
      cart.cartItems.length > 0 && cart.cartItems.map((item) => {
        itemsCount += item.addedQuantity
      })
      dispatch({
        type: GET_CART_ITEMS_COUNT,
        cartItemsCount: itemsCount
      })
    })
  }
}