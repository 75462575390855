import React from 'react'
import { Link } from 'react-router-dom'
import { iCategoryPart } from '../category/reducer'
import { iCategory } from '../../types'

export interface IProps {
  category: iCategoryPart,
  getCategories: () => void
}

export default class Menu extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    props.getCategories()
  }

  render() {
    const { category } = this.props
    const filterPathPrefix = '/list/filter&categoryLevelOne='
    return (
      <div className="menu">
        {
          category.categories.length > 0 &&
          category.categories.map((category: iCategory) =>
            (
              <div key={category.id} className="menu__item">
                <Link className="menu__item__text"
                  to={filterPathPrefix + category.categoryName}>
                  {category.categoryName}
                </Link>
                <div className="menu__item__block">
                {
                  category.children && category.children.map((seCategory: iCategory, index: number) =>
                    (
                      <div className="menu__item__sub">
                        <Link key={index} className="menu__item__sub__text"
                          to={filterPathPrefix + category.categoryName + '&categoryLevelTwo=' + seCategory.categoryName}>
                          {seCategory.categoryName}
                        </Link>
                        <div className="menu__block-second">
                          {
                            seCategory.children && seCategory.children.map((thCategory: iCategory, index: number) => (
                              <div className="menu__item__sub-second">
                                <Link key={index}
                                  to={
                                    filterPathPrefix + category.categoryName
                                    + '&categoryLevelTwo=' + seCategory.categoryName
                                    + '&categoryLevelThree=' + thCategory.categoryName
                                  }>
                                  {thCategory.categoryName}
                                </Link>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    )
                  )
                }
                </div>
              </div>
            )
          )
        }
      </div>
    );
  }
}