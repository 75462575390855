import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import request from '../../util/request'
import { URL, API_URL } from '../../config'
import Layout from '@components/Layout'
import { iCheckoutPart } from './reducer'
import { getAmount, getSelectedCount, getSelectedTotal, getShipping, getSelectedTotalAfterDis, getAmountAfterDis, getGst, getSubtotal } from '../../util/calculation'
import { iAddressCreateRes, iBillInsertRes } from '../../types/response'
import { iBillProduct, iAddress, iCoupon } from '../../types'
import AddressConnect from '../user/address/index'
import { regionMap } from '../user/address/Address'
import { iAddressPart } from '../user/address/reducer'
import AdrressListConnect from '../user/addressList/index'
import { iUserPart } from '../user/userInfo/reducer'
import ShoppingNav from '@components/ShoppingNav'
import { iConfig } from '..//configStore'

const promotionArray: Array<string> = ['promotionType', 'promotionSaleDiscount',
  'promotionSaleSpecial', 'promotionSaleBuyGiveM', 'promotionSaleBuyGiveN',
  'promotionSaleBuyDiscountD', 'promotionSaleBuyDiscountN',
  'buyGiveOtherBuyNum', 'buyGiveOtherGiveNum', 'buyGiveOtherGiveProducts']

interface IProps extends RouteComponentProps {
  checkout: iCheckoutPart,
  setCheckoutDetail: () => void,
  address: iAddressPart,
  saveAddress: (addressId?: number) => Promise<iAddressCreateRes>,
  user: iUserPart,
  setLoading: (loading: boolean) => void,
  deleteCartItem: (cartItemId: number) => Promise<void>,
  checkAddressInput: () => string,
  clearAddressDetail: () => void,
  getCartDetail: () => void,
  directToPayment: (receiptNumber: string, amountTotal: number, address: iAddress) => void,
  getCoupon: (couponCode: string) => Promise<iCoupon>,
  getMemberDis: () => number,
  controlModal: (showModal: boolean, message: string) => void,
  config: iConfig,
}

interface IState {
  note: string,
  showModal: boolean,
  couponCode: string,
  couponValue: number,
  couponId: number
}

export default class Checkout extends React.Component<IProps,IState> {
  constructor(props: IProps) {
    super(props)
    props.setCheckoutDetail()
    this.state = {
      note: '',
      showModal: false,
      couponCode: '',
      couponValue: 0,
      couponId: 0
    }
  }

  onNoteChange = (value: string) => {
    this.setState({note: value})
  }

  onCouponChange = (value: string) => {
    this.setState({couponCode: value})
  }

  confirmOrder = () => {
    let that = this
    const { addressId } = that.props.address
    let errorMessage = that.props.checkAddressInput()
    if (that.props.checkout.checkoutItems.length === 0) {
      that.props.controlModal(true, 'No Products!')
      return
    }
    if (errorMessage) {
      that.props.controlModal(true, errorMessage)
      return
    }
    that.props.setLoading(true)
    that.props.saveAddress(addressId ? addressId : undefined).then(function(res: iAddressCreateRes) {
      that.createBill(res.id)
    }, function() {
      console.log(arguments)
      that.props.setLoading(false)
    })
  }

  getBillProducts() {
    const { checkoutItems, checkoutProMap } = this.props.checkout
    let billProducts: Array<iBillProduct> = []
    checkoutItems.map((item, index) => {
      let product = checkoutProMap[item.productID]
      billProducts[index] = {
        productID: item.productID,
        purchasePrice: product.productBranch[0].salePrice,
        quantity: item.addedQuantity,
        discount: 0,
        amount: getAmountAfterDis(item, product),
        productName: product.productName
      }
      if (product.clubPrice) {
        billProducts[index].clubPrice = product.clubPrice
      }
      if (product.productBranch[0].promotionType) {
        promotionArray.map((label) => {
          billProducts[index][label] = product.productBranch[0][label]
        })
      }
    })
    return billProducts
  }

  finishBill(res: iBillInsertRes, amountTotal: number) {
    const { user, checkout, deleteCartItem, getCartDetail, clearAddressDetail,
      directToPayment, address } = this.props
    let that = this
    if (user.hasGotUserInfo) {
      let deletePromiseArray: Array<Promise<void>> = []
      checkout.checkoutItems.map((item) => {
        deletePromiseArray.push(deleteCartItem(item.id))
      })
      Promise.all(deletePromiseArray).then(function() {
        getCartDetail()
        that.props.history.push('/user/order/detail/' + res.receiptNumber)
        clearAddressDetail()
      })
    } else {
      directToPayment(res.receiptNumber, amountTotal, address.addressDetail)
    }
  }

  createBill = (addressId: number) => {
    let that = this
    const { checkout, address, config, user } = that.props
    let billProducts = that.getBillProducts()
    let region = Number(address.addressDetail.stateOrCounty)
    let originalTotal: number = Number(getSelectedTotal(checkout.checkoutItems, checkout.checkoutProMap))
    let addGst = getGst(Number(originalTotal))
    // free shipping is decided by actual total
    let freightTotal: number = getShipping(originalTotal, region, config.basicConfig)
    let amountTotal = Number(originalTotal) + Number(addGst) + freightTotal
    if (that.state.couponValue) {
      amountTotal = amountTotal - that.state.couponValue
    }
    let creditDeduct = 0
    if (user.hasGotUserInfo && user.userInfo.wholesale.credit) {
      creditDeduct =  user.userInfo.wholesale.credit
    }
    let payload = {
      billCreateType: 'wholesale',
      paymentStatus: 'N',
      billStatus: 'new',
      subTotal: originalTotal,
      taxTotal: addGst,
      amountTotal: amountTotal.toFixed(2),
      paymentMethod: "eftpos",
      eftpos: 0,
      cashOut: 0,
      cashIn: 0,
      cashReturn: 0,
      discount: 0,
      freight: freightTotal,
      products: billProducts,
      addressID: addressId,
      couponID: that.state.couponId || '',
      note: that.state.note,
      branchID: localStorage.getItem('branchID') || null,
      billCreatorID: '',
      billPayType: 'prepay',
      creditDeduct: creditDeduct
    }
    let { branchCashier } = that.props.config.basicConfig
    if (localStorage.getItem('branchID')) {
      let branchID = Number(localStorage.getItem('branchID')) || 0
      payload.billCreatorID = branchCashier[branchID]
      payload.paymentMethod = 'online'
      payload.eftpos = 0
    }
    if (user.hasGotUserInfo && user.userInfo.wholesale.afterPay) {
      payload.billPayType = 'afterpay'
      payload.billStatus = 'paid'
    }
    request(
      `${API_URL}/basic/webstore/billInsert`,
      {
        method: "POST",
        body: JSON.stringify(payload)
      }
    ).then(function(res: iBillInsertRes) {
      that.finishBill(res, amountTotal)
    }, function() {
      console.log(arguments)
    })
  }

  openModal = () => {
    this.setState({showModal: true})
  }

  closeModal = () => {
    this.setState({showModal: false})
  }

  onCouponApply = () => {
    let that = this
    let couponCode = that.state.couponCode
    const { getCoupon, setLoading, user, controlModal } = that.props
    if (!user.hasGotUserInfo) {
      controlModal(true, 'Welcome to register and login to use our coupon, thanks!')
      that.setState({couponCode: ''})
      return
    }
    let totalAfterDis: number = Number(that.getTotalAfterDis())
    setLoading(true)
    getCoupon(couponCode).then((coupon: iCoupon) => {
      setLoading(false)
      if (totalAfterDis < coupon.minConsume) {
        controlModal(true,
          'Need to buy $' + coupon.minConsume + ' to use coupon ' + couponCode)
        return
      }
      that.setState({couponValue: coupon.value, couponId: coupon.id})
      controlModal(true, 'Apply Success!')
    }, function(resp) {
      controlModal(true, resp.data)
      setLoading(false)
    })
  }

  getTotalAfterDis = () => {
    const { checkout, getMemberDis } = this.props
    let totalAfterDis = getSelectedTotalAfterDis(
      checkout.checkoutItems,
      checkout.checkoutProMap,
      getMemberDis()
    )
    return totalAfterDis
  }

  render() {
    const { checkout, address, user, config } = this.props
    const { addressDetail } = address
    let basicConfig = config.basicConfig
    let couponValue = this.state.couponValue
    let originalTotal = getSelectedTotal(checkout.checkoutItems, checkout.checkoutProMap)
    let addGst = getGst(Number(originalTotal))
    let finalTotal = getSubtotal(Number(originalTotal), Number(getGst(Number(originalTotal))))
    if (couponValue) {
      finalTotal = (Number(finalTotal) - couponValue).toFixed(2)
    }
    let shippingTotal = getShipping(Number(originalTotal), Number(addressDetail.stateOrCounty), basicConfig)
    let amountTotal = (Number(finalTotal) + shippingTotal).toFixed(2)
    if (user.hasGotUserInfo && user.userInfo.wholesale.credit > 0) {
      amountTotal = (Number(amountTotal) - user.userInfo.wholesale.credit).toFixed(2)
    }
    return (
      <Layout>
        <div className="checkout">
          <ShoppingNav />
          {
            user.hasGotUserInfo
            ? <div className="button button-middle" onClick={this.openModal}>My Address</div>
            : ''
          }
          <AddressConnect />
          <div className="checkout__cart">
            <div className="table__line table__header">
              <div className="col-3">Product</div>
              <div className="col-2">SKU</div>
              <div className="col-2">Price</div>
              <div className="col-2">Quantity</div>
              <div className="col-3">Total</div>
            </div>
            {
              checkout && checkout.checkoutItems.map((item) => {
                let checkoutProduct = checkout.checkoutProMap[item.productID]
                return (
                  <div key={item.id} className="table__line table__detail">
                    <div className="col-3 table__detail__name">
                      <Link to={'/product/detail/' + checkoutProduct.productID} target="_blank">
                        {
                          checkoutProduct.picPath
                          ? <img src={URL + checkoutProduct.picPath} width="80" />
                          : <img src="../../styles/images/example-image.jpg" width="80" />
                        }
                        <span>{checkoutProduct.productName}</span>
                      </Link>
                    </div>
                    <div className="col-2 table__detail__normal">
                      {checkoutProduct.productCode}
                    </div>
                    <div className="col-2 table__detail__normal product__obvious">
                      ${checkoutProduct.productBranch[0].salePrice.toFixed(2)}
                    </div>
                    <div className="col-2 table__detail__normal product__obvious">
                      {item.addedQuantity}
                    </div>
                    <div className="col-3 table__detail__normal product__obvious">
                      ${getAmount(item, checkoutProduct)}
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="checkout__count cart__count">
            <div className="checkout__count__coupon">
              <label className="count__label">Coupon:<span className="icon-tag"></span></label>
              <input type="text" value={this.state.couponCode} onChange={(e)=>this.onCouponChange(e.target.value)} />
              <button onClick={this.onCouponApply}>Apply</button>
            </div>
            <div className="checkout__count__note">
              <label className="count__label">Note:</label>
              <textarea value={this.state.note} onChange={(e)=>this.onNoteChange(e.target.value)}></textarea>
            </div>
            <Link to="/cart">Back to Cart</Link>
            <div className="checkout__count__confirm">
              <div className="cart__count__total">
                <div className="total__detail">
                  <div className="total__detail__line">
                    <label>Quantity:</label>
                    <span>{getSelectedCount(checkout.checkoutItems)}</span>
                  </div>
                  <div className="total__detail__line">
                    <label>Original Total:</label>
                    <span>${originalTotal}</span>
                  </div>
                  <div className="total__detail__line">
                    <label>Add GST:</label>
                    <span>${addGst}</span>
                  </div>
                  <div className="total__detail__line">
                    <label>Sub Total:</label>
                    <span>${finalTotal}</span>
                  </div>
                  {
                    couponValue
                    ? <div className="total__detail__line">
                        <label>Coupon Saved:</label>
                        <span>-${couponValue.toFixed(2)}</span>
                      </div> : ''
                  }
                  {
                    user.hasGotUserInfo && user.userInfo.wholesale.credit > 0 ? 
                    <div className="total__detail__line">
                      <label>Credit:</label>
                      <span>-${user.userInfo.wholesale.credit}</span>
                    </div> : ''
                  }
                  <div className="total__detail__line">
                    <label>
                      Shipping:
                      <span className="icon-question-circle"></span>
                      <div className="question-block">
                        <p>Northland: Original Total x {basicConfig.northShippingRate}%, Over ${basicConfig.northFreeShipping} free shipping</p>
                        <p>Southland: Original Total x {basicConfig.southShippingRate}%, Over ${basicConfig.southFreeShipping} free shipping</p>
                      </div>
                    </label>
                    <span>${addressDetail.stateOrCounty ? shippingTotal.toFixed(2) : 0}</span>
                  </div>
                  <div className="total__detail__line">
                    <label>Total:</label>
                    <span>${amountTotal}</span>
                  </div>
                </div>
              </div>
              <div className="confirm__address">
                <p>
                {
                  Object.keys(addressDetail).length > 0
                  ? (addressDetail.firstName + ' ' + addressDetail.lastName
                      + (addressDetail.mobilePhone ? (', ' + addressDetail.mobilePhone) : '')
                      + (addressDetail.email ? (', ' + addressDetail.email) : ''))
                  : ''
                }
                </p>
                <p>
                {
                  Object.keys(addressDetail).length > 0
                  ? (addressDetail.address
                      + (addressDetail.townOrCity ? (', ' + addressDetail.townOrCity) : '')
                      + (addressDetail.stateOrCounty ? (', ' + regionMap[addressDetail.stateOrCounty]) : '')
                      + (addressDetail.postcode ? (', ' + addressDetail.postcode) : ''))
                  : ''
                }
                </p>
              </div>
              <div className="button" onClick={this.confirmOrder}>CONFIRM ORDER</div>
            </div>
          </div>
        </div>
        {
          this.state.showModal ?
          <div>
            <div className="modal modal-middle">
              <div className="modal-body">
                <AdrressListConnect closeAddressList={this.closeModal} isCheckoutPage={true} />
              </div>
              <div className="modal-footer">
                <span className="button button-small" onClick={this.closeModal}>Cancel</span>
              </div>
            </div>
            <div className="modal-backdrop"></div>
          </div> : ''
        }
      </Layout>
    );
  }
}