import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../configureReducer'
import Bar from '@components/Bar'
import { getCartItemsCount } from '../common/cartItemsCount/action'
import { getUserInfo, clearUserInfo } from './user/userInfo/action'
import { controlModal } from '../common/globalModal/store'
import { setLoading } from '../common/loading/action'

const mapStateToProps = (state: iAppState) => ({
  cartItemsCount: state.cartItemsCount,
  user: state.user,
  cart: state.cart,
  config: state.config,
  loading: state.loading
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators<any, {
  getCartItemsCount: () => void,
  getUserInfo: () => void,
  clearUserInfo: () => void,
  controlModal: (showModal: boolean, message: string | JSX.Element) => void,
  setLoading: (loading: boolean) => void
}> ({
  getCartItemsCount,
  getUserInfo,
  clearUserInfo,
  controlModal,
  setLoading
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Bar)