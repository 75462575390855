import React from 'react'
import request from '../util/request'
import { API_URL } from '../config'
import { iActivateRouterParams } from '../types/router'
import { match } from 'react-router-dom'
import { History } from 'history'

export interface IProps {
  match: match<iActivateRouterParams>
  history: History
}

export default class RegisterActivate extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    request(
      `${API_URL}/basic/webstore/register/activate?${props.match.params.activateString}`
    ).then((resp) => {
      window.alert(resp)
      props.history.push('/login')
    }, function(resp) {
      window.alert(resp.data)
      props.history.push('/login')
    })
  }

  render() {
    return (
      <div className="registerLogin__activate">
        <img src="../../styles/images/processing.png" />
      </div>
    );
  }
}