import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import request from '../../util/request'
import { API_URL } from '../../config'
import Layout from '@components/Layout'
import { iPaymentRouterParams } from '../../types/router'
import ShoppingNav from '@components/ShoppingNav'
import { iUserPart } from '../user/userInfo/reducer'
import { iUserInfo, iAddress } from '../../types'
import { iPaymentErrorRes } from '../../types/response'

interface IProps extends RouteComponentProps<iPaymentRouterParams> {
  getPaymentResponse: (receiptNumber: string, result: string | null) => Promise<void>,
  setLoading: (loading: boolean) => void,
  user: iUserPart,
  directToPayment: (receiptNumber: string, amountTotal: number, address?: iAddress) => void,
  controlModal: (showModal: boolean, message: string | JSX.Element) => void
}

interface IState {
  payAgain: boolean,
  amountTotal: string
}

export default class Payment extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      payAgain: false,
      amountTotal: ''
    }
  }

  componentDidMount() {
    const { match, location, getPaymentResponse, history, setLoading, controlModal } = this.props
    let receiptNumber = match.params.receiptNumber
    let searchParams = new URLSearchParams(location.search)
    let result = searchParams.get("result")
    let that = this
    setLoading(true)
    request(
      `${API_URL}/basic/webstore/userInfo/get`
    ).then((userInfo: iUserInfo) => {
      getPaymentResponse(receiptNumber, result).then(function() {
        let successMessage = (<span>Your order {receiptNumber} has been paid successfully, thank you!<br />
          We have sent you an email, check spam or junk email if you can’t find it.<br />
          Please give us some time, we will send you another email when job is done.</span>)
        controlModal(true, successMessage)
        if (Object.keys(userInfo).length > 0) {
          history.push('/user/order/detail/' + receiptNumber)
        } else {
          setLoading(false)
          history.push('/')
        }
      }, function(resp: iPaymentErrorRes) {
        console.log(arguments)
        if (Object.keys(resp.data).length > 0) {
          controlModal( true, 'Sorry, ' + receiptNumber + ' Payment Failed')
          if (Object.keys(userInfo).length > 0) {
            history.push('/user/order/detail/' + receiptNumber)
          } else {
            that.setState({payAgain: true, amountTotal: resp.data.AmountSettlement})
            setLoading(false)
          }
        } else {
          controlModal(true, "Please refresh this page")
        }
      })
    }, function() {
      setLoading(false)
      controlModal(true, "Please refresh this page")
    })
  }

  onPayClick = () => {
    const { directToPayment, match } = this.props
    let receiptNumber = match.params.receiptNumber
    directToPayment(receiptNumber, Number(this.state.amountTotal))
  }

  render() {
    return (
      <Layout>
        <div className="about">
          <ShoppingNav />
          <h2>Payment</h2>
          <p>
            <label className="item-left">Order:</label>
            {this.props.match.params.receiptNumber}
          </p>
          {
            this.state.payAgain
            ? <div className="button button-middle" onClick={this.onPayClick}>Pay Again</div>
            : <p>Please wait a moment!</p>
          }
        </div>
      </Layout>
    );
  }
}