import { iCartItem, iCartProMap } from '../../types'
import { ISET_CHECKOUT_DETAILAction, SET_CHECKOUT_DETAIL } from './action'

export interface iCheckoutPart {
  checkoutItems: Array<iCartItem>,
  checkoutProMap: iCartProMap
}

export default (
  state = {checkoutItems: [], checkoutProMap: {}}, action: ISET_CHECKOUT_DETAILAction
) => {
  switch (action.type) {
    case SET_CHECKOUT_DETAIL:
      return Object.assign({}, state, {checkoutItems: action.checkoutItems, checkoutProMap: action.checkoutProMap})
    default:
      return state
  }
}