import React from 'react'
import request from '../util/request'
import { RouteComponentProps } from 'react-router-dom'
import { API_URL } from '../config'
import Layout from './Layout'
import { iPasswordResetParams } from '../types/router'

interface IProps extends RouteComponentProps<iPasswordResetParams> {
  setLoading: (loading: boolean) => void
}

interface IState {
  [key: string]: string
}

interface iParamsMap {
  [key: string]: string
}

export default class PasswordReset extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      newPassword: '',
      confirmPassword: ''
    }
  }

  bindState = (label: keyof IState) => {
    let that = this
    return function(e: React.ChangeEvent<HTMLInputElement>) {
      that.setState({[label]: e.target.value, errorMessage: ''})
    }
  }

  onResetClick = () => {
    let that = this
    const { newPassword, confirmPassword } = that.state
    if (newPassword === '') {
      that.setState({errorMessage: 'New Password cannot be blank'})
      return
    }
    if (newPassword !== confirmPassword) {
      that.setState({errorMessage: 'New password and confirm password differ'})
      return
    }
    let paramsMap: iParamsMap = {}
    this.props.match.params.resetString.split('&').map((part) => {
      let paramsKey = part.split('=')[0]
      let paramsValue = part.split('=')[1]
      paramsMap[paramsKey] = paramsValue
    })
    that.props.setLoading(true)
    request(
      `${API_URL}/basic/webstore/password/reset`,
      {
        method: "POST",
        body: JSON.stringify({
          userId: paramsMap.userId,
          resetCode: paramsMap.resetCode,
          newPassword: that.state.newPassword
        })
      }
    ).then(function() {
      window.alert('Reset password success! You can login with your new password now.')
      that.props.history.push('/login')
      that.props.setLoading(false)
    }, function(resp) {
      console.log(resp)
      that.props.setLoading(false)
      that.setState({errorMessage: resp.data})
    })
  }

  render() {
    return (
      <Layout>
        <div className="password-reset">
          <div className="password-reset__title">Reset Password</div>
          <div className="password-reset__detail">
            <p>Now you are resetting your password. Please fill in your new password.</p>
            <label>New Password</label>
            <input type="password" value={this.state.newPassword} onChange={this.bindState('newPassword')} />
            <label>Confirm New Password</label>
            <input type="password" value={this.state.confirmPassword} onChange={this.bindState('confirmPassword')} />
            <div className="button button-middle" onClick={this.onResetClick}>Reset Password</div>
            <p className="error">{this.state.errorMessage}</p>
          </div>
        </div>
      </Layout>
    )
  }
}