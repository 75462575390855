import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../configureReducer'
import { setLoading } from '../common/loading/action'
import Register from '@components/Register'
import { controlModal } from '../common/globalModal/store'

const mapStateToProps = (state: iAppState) => ({
  config: state.config
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators ({
  setLoading,
  controlModal
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Register)