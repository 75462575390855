import { Dispatch } from 'redux'
import request from '../util/request'
import { API_URL } from '../config'

interface iBranchCashier {
  [branchID: number]: string
}

export interface iBasicConfig {
  autoUserRegisterEmail: string,
  brand: string,
  closeRegister: string,
  lowStockThreshold: string,
  minimumPurchase: string,
  offerTime: string,
  openEmailVerify: string,
  serviceEmail: string,
  serviceHotline: string,
  sku: string,
  stock: string,
  stockManage: string,
  stockRelatedBranchID: string,
  storeDesc: string,
  storeKey: string,
  storeName: string,
  storeTitle: string,
  weight: string,
  announcement: string,
  pickupBranch: string,
  pickupCashier: string,
  branchCashier: iBranchCashier,
  northFreeShipping: string,
  northShippingRate: string,
  southFreeShipping: string,
  southShippingRate: string
}

const GET_BASIC_CONFIG = "GET_BASIC_CONFIG"
type GET_BASIC_CONFIG = typeof GET_BASIC_CONFIG

interface IGET_BASIC_CONFIGAction {
  type: GET_BASIC_CONFIG,
  basicConfig: iBasicConfig
}

export function getBasicConfig() {
  return (dispatch: Dispatch) => {
    request(
      `${API_URL}/basic/webstore/config/select?mainType=wholesale`
    ).then((basicConfig: iBasicConfig) => {
      document.getElementsByTagName('title')[0].innerText = basicConfig.storeTitle
      dispatch({
        type: GET_BASIC_CONFIG,
        basicConfig
      })
    })
  }
}

export interface iConfig {
  basicConfig: iBasicConfig
}

export default (
  state = {basicConfig: {}}, action: IGET_BASIC_CONFIGAction
) => {
  switch (action.type) {
    case GET_BASIC_CONFIG:
      return Object.assign({}, state, {basicConfig: action.basicConfig})
    default:
      return state
  }
}