import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { iHomePage } from '../containers/home/store'
import { iAppState } from '../configureReducer'
import { URL } from '../config'

interface iCategoryMap {
  [key: string]: string
}

const categoryMap: iCategoryMap = {
  1: 'categoryLevelOne',
  2: 'categoryLevelTwo',
  3: 'categoryLevelThree'
}

interface IProps extends RouteComponentProps {
  home: iHomePage
}

class HomeCarousel extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
  }

  onPicClick = (name: string, level: string) => {
    this.props.history.push(`/list/filter&${categoryMap[level]}=${name}`)
  }

  render() {
    const { carousel } = this.props.home
    return (
      <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
        {
          carousel && carousel.length > 0
          ? carousel.map((item) => (
              <div className="carousel-item-wrapper" onClick={()=>
                this.onPicClick(item.categoryName, item.categoryLevel)}>
                <img src={URL + item.picPath} />
                <p className="legend">{item.categoryName}</p>
              </div>
            ))
          : <div className="carousel-item-wrapper">
              <img />
            </div>
        }
      </Carousel>
    )
  }
}

const mapStateToProps = (state: iAppState) => ({
  home: state.home
})

export default withRouter(connect(mapStateToProps)(HomeCarousel))