export const SET_LOADING = "SET_LOADING"
export type SET_LOADING = typeof SET_LOADING

export interface ISET_LOADINGAction {
  type: SET_LOADING,
  loading: boolean
}

export const setLoading = (loading: boolean): ISET_LOADINGAction => ({
  type: SET_LOADING,
  loading
})