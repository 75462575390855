import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../../configureReducer'
import { controlDeleteModal, confirmDelete } from './store'
import DeleteConfirm from './DeleteConfirm'

const mapStateToProps = (state: iAppState) => ({
  deleteModal: state.deleteModal
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators ({
  controlDeleteModal,
  confirmDelete
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DeleteConfirm)