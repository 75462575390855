import { Dispatch } from 'redux'
import { iCategory } from '../../types'
import request from '../../util/request'
import { API_URL } from '../../config'
import { IProductCategoryResponse } from '../../types/response'
import { iAppState } from '../../configureReducer'

export const SET_CATEGORY_CATEGORIES = "SET_CATEGORY_CATEGORIES"
export type SET_CATEGORY_CATEGORIES = typeof SET_CATEGORY_CATEGORIES

export const EXPAND_CLICK = "EXPAND_CLICK"
export type EXPAND_CLICK = typeof EXPAND_CLICK

export interface iExpandedMap {
  [categoryId: number]: boolean
}

export interface ISET_CATEGORY_CATEGORIESAction {
  type: SET_CATEGORY_CATEGORIES,
  categories: Array<iCategory>
}

export const getCategories = () => {
  return (dispatch: Dispatch<any>) => {
    request(
      `${API_URL}/basic/wholesale/productCategory/select`
    ).then((res: IProductCategoryResponse) => {
      dispatch(setCategoryCategories(res))
    })
  }
}

export const setCategoryCategories = 
  (categories: Array<iCategory>) : ISET_CATEGORY_CATEGORIESAction => ({
    type: SET_CATEGORY_CATEGORIES,
    categories
  })

export interface IEXPAND_CLICKAction {
  type: EXPAND_CLICK,
  expandedMap: iExpandedMap
}

export const onExpandClick = (categoryId: number) => {
  return (dispatch: Dispatch, getState: () => iAppState) => {
    const { category } = getState()
    let expandedMap: iExpandedMap = Object.assign({}, category.expandedMap)
    let expanded = expandedMap[categoryId]
    expandedMap[categoryId] = !expanded
    dispatch({
      type: EXPAND_CLICK,
      expandedMap
    })
  }
}

export type CategoryAction = ISET_CATEGORY_CATEGORIESAction | IEXPAND_CLICKAction