import { UserAction, GET_USER_INFO, CLEAR_USER_INFO } from './action'
import { iUserInfo } from '../../../types'

export interface iUserPart {
  userInfo: iUserInfo,
  hasGotUserInfo: boolean
}

export default (
  state = {userInfo: {}, hasGotUserInfo: false}, action: UserAction
) => {
  switch (action.type) {
    case GET_USER_INFO:
      return Object.assign({}, state, {userInfo: action.userInfo, hasGotUserInfo: true})
    case CLEAR_USER_INFO:
      return Object.assign({}, state, {userInfo: {}, hasGotUserInfo: false})
    default:
      return state
  }
}