import React from 'react'
import { Link } from 'react-router-dom'

function getCurrentYear() {
  let today = new Date()
  return today.getFullYear()
}

export default class Footer extends React.Component {
  constructor(props: {}) {
    super(props)
  }

  render() {
    let currentYear = getCurrentYear()
    return (
      <div className="footer">
        <div className="footer__detail">
          <div className="footer__detail__item">
            <h3>COMPANY INFO</h3>
            <Link to="/aboutUs">About Us</Link>
            <a>Careers</a>
            <Link to="/privacyPolicy">Privacy Policy</Link>
          </div>
          <div className="footer__detail__item">
            <h3>SERVICE & SUPPORT</h3>
            <Link to="/contacts">Contact Us</Link>
            <Link to="/deliveryReturns">Shipping Prices & Information</Link>
            <Link to="/paymentEnquiries">Payment Enquiries</Link>
          </div>
          <div>
            <h3>FOLLOW US ON</h3>
            <div className="footer__social">
              <a className="icon-facebook-f"></a>
              <a className="icon-instagram"></a>
              <a className="icon-youtube-play"></a>
            </div>
            <a>#MotarroWholesale</a>
          </div>
        </div>
        <p>© Copyright Motarro Wholesale {currentYear}</p>
      </div>
    )
  }
}