import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import OrderListConnect from './orderList/index'
import OrderConnect from './order/index'
import AddressListConnect from './addressList/index'
import UserInfoConnect from './userInfo/index'
import PasswordConnect from './password/index'
import Invoice from './orderList/Invoice'

class UserLayout extends React.Component<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props)
  }

  render() {
    const { match } = this.props
    return (
      <div>
        <Switch>
          <Route path={match.path + '/userInfo'} exact component={UserInfoConnect} />
          <Route path={match.path + '/order/:curPage?'} exact component={OrderListConnect} />
          <Route path={match.path + '/order/detail/:receiptNumber'} component={OrderConnect} />
          <Route path={match.path + '/address'} component={AddressListConnect} />
          <Route path={match.path + '/password'} component={PasswordConnect} />
          <Route path={match.path + '/invoice/:receiptNumber'} component={Invoice} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(UserLayout)