import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../../configureReducer'
import { getCartDetail, increment, decrement, setQuantity, checkClick, allCheckClick, deleteCartItem, setCartLoading } from './action'
import ShoppingCart from './ShoppingCart'
import { getCartItemsCount } from '../../common/cartItemsCount/action'
import { controlDeleteModal } from '../../common/deleteConfirm/store'
import { getMemberDis } from '../checkout/action'

const mapStateToProps = (state: iAppState) => ({
  cart: state.cart,
  cartItemsCount: state.cartItemsCount,
  user: state.user,
  config: state.config
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators<any, {}>({
  getCartDetail,
  increment,
  decrement,
  setQuantity,
  getCartItemsCount,
  onCheckClick: checkClick,
  onAllCheckClick: allCheckClick,
  setCartLoading,
  deleteCartItem,
  controlDeleteModal,
  getMemberDis
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)