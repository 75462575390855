import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../../../configureReducer'
import { getOrderList } from './action'
import OrderList from './OrderList'
import { directToPayment } from '../../payment/action'
import { setLoading } from '../../../common/loading/action'
import { updateOrderStatus } from '../order/action'
import { controlDeleteModal } from '../../../common/deleteConfirm/store'

const mapStateToProps = (state: iAppState) => ({
  orderList: state.orderList,
  loading: state.loading
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getOrderList,
  directToPayment,
  setLoading,
  updateOrderStatus,
  controlDeleteModal
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(OrderList)