import React from 'react'
import { Link } from 'react-router-dom'
import { iCategoryPart } from './reducer'
import { iCategory } from '../../types'
import { iListRouterParams } from '../../types/router'
import { RouteComponentProps } from 'react-router-dom'

export interface IProps extends RouteComponentProps<iListRouterParams> {
  category: iCategoryPart,
  getCategories: () => void,
  onExpandClick: (categoryId: number) => void
}

interface iFilterMap {
  [propName: string]: string;
}

export default class Category extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    props.getCategories()
  }

  render() {
    const { category, match, onExpandClick } = this.props
    const { categories, expandedMap } = category
    const filterPathPrefix = '/list/filter&categoryLevelOne='
    let filterMap: iFilterMap = {}
    match && Object.keys(match.params).length > 0 && match.params.selectString &&
      match.params.selectString.split('&').map(function(filterPart, index){
        if (index !== 0) {
          let filterKey: string = filterPart.split('=')[0]
          let filterValue: string = filterPart.split('=')[1]
          filterMap[filterKey] = filterValue
        }
      })
    return (
      <div className="list">
        <label>Product Category</label>
      {
        categories.length > 0 &&
        categories.map((category: iCategory, index: number) =>
          (
            <div key={index}>
              <div className="row list__main">
                <Link className={'col-10' + (filterMap.categoryLevelOne == category.categoryName ? ' current' : '')}
                  to={filterPathPrefix + category.categoryName} title={category.categoryName}>
                  {category.categoryName}
                </Link>
                {
                  category.children
                  ? <span className={'col-2' + (expandedMap[category.id] ? ' icon-chevron-up' : ' icon-chevron-down')}
                    onClick={()=>onExpandClick(category.id)}></span> : ''
                }
              </div>
              <div className={expandedMap[category.id] ? 'list__children' : 'hidden'}>
              {
                category.children && category.children.map((seCategory: iCategory, index: number) =>
                  (
                    <div key={index}>
                      <div className="row list__sub">
                        <Link className={(seCategory.children ? 'col-9' : 'col-12') + (filterMap.categoryLevelTwo == seCategory.categoryName ? ' current' : '')}
                          to={filterPathPrefix + category.categoryName
                            + '&categoryLevelTwo=' + seCategory.categoryName}
                          title={seCategory.categoryName}>
                          {seCategory.categoryName}
                        </Link>
                        {
                          seCategory.children
                          ? <span className={'col-3' + (expandedMap[seCategory.id] ? ' icon-chevron-up' : ' icon-chevron-down')}
                            onClick={()=>onExpandClick(seCategory.id)}></span> : ''
                        }
                      </div>
                      <div className={expandedMap[seCategory.id] ? 'list__children' : 'hidden'}>
                        {
                        seCategory.children && seCategory.children.map((thCategory, index) => (
                          <Link key={index} className={filterMap.categoryLevelThree == thCategory.categoryName ? ' current' : ''}
                            to={filterPathPrefix + category.categoryName
                              + '&categoryLevelTwo=' + seCategory.categoryName
                              + '&categoryLevelThree=' + thCategory.categoryName}
                            title={thCategory.categoryName}>
                            {thCategory.categoryName}
                          </Link>
                        ))
                      }
                      </div>
                    </div>
                  )
                )
              }
              </div>
            </div>
          )
        )
      }
      </div>
    )
  }
}