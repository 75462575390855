import request from '../../../util/request'
import { API_URL } from '../../../config'
import { Dispatch } from 'redux'
import { iAddress } from '../../../types'
import { iAppState } from 'configureReducer'
import { setLoading } from '../../../common/loading/action'

export const GET_ADDRESS_LIST = "GET_ADDRESS_LIST"
export type GET_ADDRESS_LIST = typeof GET_ADDRESS_LIST

export const MOUSE_CHANGE = "MOUSE_CHANGE"
export type MOUSE_CHANGE = typeof MOUSE_CHANGE

export interface IGET_ADDRESS_LISTAction {
  type: GET_ADDRESS_LIST,
  addresses: Array<iAddress>
}

export interface IMOUSE_CHANGEAction {
  type: MOUSE_CHANGE,
  addresses: Array<iAddress>
}

export function getAddressList() {
  return (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    request(
      `${API_URL}/basic/webstore/shippingAddress/select`
    ).then((addresses: Array<iAddress>) => {
      dispatch({
        type: GET_ADDRESS_LIST,
        addresses
      })
      dispatch(setLoading(false))
    }, function() {
      dispatch(setLoading(false))
    })
  }
}

export function deleteAddress(addressId: number) {
  return (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    request(
      `${API_URL}/basic/webstore/shippingAddress/delete?id=${addressId}`
    ).then(() => {
      window.alert('Delete Sunccess')
      window.location.reload()
    })
  }
}

export function mouseChange(index: number, mouseOver: boolean) {
  return (dispatch: Dispatch, getState: () => iAppState) => {
    const { addressList } = getState()
    let addresses = [...addressList.addresses]
    addresses[index].mouseOver = mouseOver
    dispatch({
      type: GET_ADDRESS_LIST,
      addresses
    })
  }
}

export type AddressListAction = IGET_ADDRESS_LISTAction | IMOUSE_CHANGEAction
