import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../../configureReducer'
import Payment from './Payment'
import { getPaymentResponse, directToPayment } from '../payment/action'
import { setLoading } from '../../common/loading/action'
import { controlModal } from '../../common/globalModal/store'

const mapStateToProps = (state: iAppState) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators<any, {}>({
  getPaymentResponse,
  setLoading,
  directToPayment,
  controlModal
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Payment)