import request from '../../util/request'
import { API_URL } from '../../config'
import { Dispatch } from 'redux'
import { iProductDetail } from '../../types'
import { getCartItemsCount } from '../../common/cartItemsCount/action'
import { iAppState } from '../../configureReducer'
import { setLoading } from '../../common/loading/action'
import { getCartDetail } from '../cart/action'

export const INCREMENT = "INCREMENT"
export type INCREMENT = typeof INCREMENT

export const DECREMENT = "DECREMENT"
export type DECREMENT = typeof DECREMENT

export const SET_VALUE = "SET_VALUE"
export type SET_VALUE = typeof SET_VALUE

export const SET_PROMPT = "SET_PROMPT"
export type SET_PROMPT = typeof SET_PROMPT

export const SET_PRODUCT_DETAIL = "SET_PRODUCT_DETAIL"
export type SET_PRODUCT_DETAIL = typeof SET_PRODUCT_DETAIL

export interface ISET_PRODUCT_DETAILAction {
  type: SET_PRODUCT_DETAIL,
  product: iProductDetail
}

export interface IINCREMENTAction {
  type: INCREMENT,
  numberEachBatch: number
}

export interface IDECREMENTAction {
  type: DECREMENT,
  numberEachBatch: number
}

export interface ISETVALUEAction {
  type: SET_VALUE,
  addedQuantity: number
}

export interface ISETPROMPTAction {
  type: SET_PROMPT,
  promptMessage: string
}

export const setProductDetail = 
  (product: iProductDetail): ISET_PRODUCT_DETAILAction => ({
    type: SET_PRODUCT_DETAIL,
    product
  })

export const increment = (numberEachBatch: number): IINCREMENTAction => ({
  type: INCREMENT,
  numberEachBatch
})

export const decrement = (numberEachBatch: number): IDECREMENTAction => ({
  type: DECREMENT,
  numberEachBatch
})

export const setValue = (addedQuantity: number): ISETVALUEAction => ({
  type: SET_VALUE,
  addedQuantity
})

export const setPrompt = (promptMessage: string): ISETPROMPTAction => ({
  type: SET_PROMPT,
  promptMessage
})

export type ProductAction = ISET_PRODUCT_DETAILAction | IINCREMENTAction
  | IDECREMENTAction | ISETVALUEAction | ISETPROMPTAction

export function addToCart(productID: number, quantity: number) {
  return (dispatch: Dispatch<any>, getState: () => iAppState) => {
    dispatch(setLoading(true))
    const { user } = getState()
    if (user.hasGotUserInfo) {
      request(
        `${API_URL}/basic/webstore/shoppingCart/add`,
        {
          method: "POST",
          body: JSON.stringify({
            productID: productID,
            addedQuantity: quantity
          })
        }
      ).then(function() {
        dispatch(getCartItemsCount())
        dispatch(getCartDetail('add'))
      }, function() {
        console.log(arguments)
        dispatch(setLoading(false))
      })
    }
  }
}