import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'

class ShoppingNav extends React.Component<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props)
    this.state = {
      searchText: ''
    }
  }

  render() {
    const { location } = this.props
    let currentMenu: string = location.pathname.split('/')[1]
    return (
      <div className="shopping_nav">
        <Link to="/cart" className={currentMenu == "cart" ? 'current' : ''}>SHOPPING CART</Link>
        <span>>></span>
        <label className={currentMenu == "checkout" ? 'current' : ''}>
          {localStorage.getItem('branchID') ? 'PICKUP ORDER CHECKOUT ': 'EXPRESS ORDER CHECKOUT'}
        </label>
        <span>>></span>
        <label className={currentMenu == "billPay" ? 'current' : ''}>PAYMENT</label>
      </div>
    );
  }
}

export default withRouter(ShoppingNav)