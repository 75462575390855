import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../../configureReducer'
import Checkout from './Checkout'
import { setCheckoutDetail, getCoupon, getMemberDis } from './action'
import { saveAddress, checkAddressInput, clearAddressDetail } from '../user/address/action'
import { setLoading } from '../../common/loading/action'
import { deleteCartItem, getCartDetail } from '../cart/action'
import { directToPayment } from '../payment/action'
import { controlModal } from '../../common/globalModal/store'

const mapStateToProps = (state: iAppState) => ({
  checkout: state.checkout,
  address: state.address,
  user: state.user,
  config: state.config
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators<any, {}>({
  setCheckoutDetail,
  saveAddress,
  setLoading,
  deleteCartItem,
  checkAddressInput,
  clearAddressDetail,
  getCartDetail,
  directToPayment,
  getCoupon,
  getMemberDis,
  controlModal
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)