import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../../configureReducer'
import { withRouter } from 'react-router-dom'
import ProductDetail from './ProductDetail'
import { setProductDetail, decrement, increment, setValue, addToCart, setPrompt } from './action'
import { setLoading } from '../../common/loading/action'
import { isClubPriceDisplay } from '../list/action'
import { controlModal } from '../../common/globalModal/store'

const mapStateToProps = (state: iAppState) => ({
  product: state.product,
  loading: state.loading,
  config: state.config
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators<any, {}> ({
  setProductDetail,
  onDecrement: decrement,
  onIncrement: increment,
  setValue,
  addToCart,
  setLoading,
  isClubPriceDisplay,
  controlModal,
  setPrompt
}, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductDetail))