import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../../configureReducer'
import { getNewArrivals, getHotProducts, getCarousel, getPromotions } from './store'
import { addToCart } from '../product/action'
import Home from './Home'

const mapStateToProps = (state: iAppState) => ({
  home: state.home,
  config: state.config,
  user: state.user
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators ({
  getCarousel,
  getNewArrivals,
  getHotProducts,
  getPromotions,
  addToCart
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Home)