import React from 'react'
import { iList } from './reducer'
import { iProduct } from '../../types'
import { iListRouterParams } from '../../types/router'
import { Link, RouteComponentProps } from 'react-router-dom'
import CategoryConnect from '../category/index'
import Layout from '@components/Layout'
import Pagination from '@components/Pagination'
import ProductCard from '@components/ProductCard'
import { iConfig } from '../configStore'
import { controlModal } from '../../common/globalModal/store'
import { iUserPart } from '../user/userInfo/reducer'

// 创建类型接口
export interface IProps extends RouteComponentProps<iListRouterParams>{
  list: iList,
  getListProducts: (props: RouteComponentProps<iListRouterParams>) => void,
  addToCart: (productID: number, quantity: number) => void,
  setLoading: (loading: boolean) => void,
  isClubPriceDisplay: (activityStatus: string) => boolean,
  config: iConfig,
  user: iUserPart
}

// 使用接口代替 PropTypes 进行类型校验
export default class ListProducts extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    props.getListProducts(props)
  }

  componentDidUpdate(prevProps: IProps) {
    let { params } = this.props.match
    if (params !== prevProps.match.params) {
      this.props.setLoading(true)
      this.props.getListProducts(this.props)
    }
  }

  render() {
    const { list, match, addToCart, isClubPriceDisplay, config, user } = this.props
    const { params } = match
    let currentPath = '/list/' + params.selectString
    return (
      <Layout>
        <CategoryConnect />
        <div className="content">
          <div className="content__tips">
            {
              params.selectString && params.selectString.split('&')[0] === 'search'
              ? <span>
                  Search Result of
                  <span className="content__tips__keyword">{params.selectString.split('&')[1].split('=')[1]}</span>
                </span> : ''
            }
            <div className="content__tips__sort">
              <Link to={currentPath}
                className={params.sortString ? '' : 'current'}>
                  Popularity
              </Link>
              <span>
                <label>Price</label>
                <Link to={currentPath + '/sortBy/price'} className={"icon-arrow-circle-up sort__option"
                  + (params.sortString === 'price' ? ' current' : '')}>
                </Link>
                <Link to={currentPath + '/sortBy/priceDesc'} className={"icon-arrow-circle-down sort__option"
                  + (params.sortString === "priceDesc" ? ' current' : '')}>
                </Link>
              </span>
            </div>
          </div>
          <div className="content__products">
            {
              list.products.length > 0 ? list.products.map((product: iProduct) =>
                (
                  <ProductCard product={product} config={config} controlModal={controlModal}
                    appliedStyleClass="content__products__detail"
                    hasGotUserInfo={user.hasGotUserInfo}  />
                )
              ) : ''
            }
          </div>
          <Pagination curPage={list.curPage} allPage={list.allPage}
            pagePathParent={currentPath + (params.sortString ? ('/sortBy/' + params.sortString) : '') + '/page/'} />
        </div>
      </Layout>
    );
  }
}