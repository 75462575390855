import { SET_CATEGORY_CATEGORIES, EXPAND_CLICK, CategoryAction, iExpandedMap } from './action'
import { iCategory } from '../../types'

export interface iCategoryPart {
  categories: Array<iCategory>,
  expandedMap: iExpandedMap
}

// 处理并返回 state 
export default (
  state = {categories: [], expandedMap: {}}, action: CategoryAction
) => {
  switch (action.type) {
    case SET_CATEGORY_CATEGORIES:
      return Object.assign({}, state, {categories: action.categories})
    case EXPAND_CLICK:
      return Object.assign({}, state, {expandedMap: action.expandedMap})
    default:
      return state
  }
}