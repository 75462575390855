import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'
import { URL, API_URL } from '../config'
import { iUserPart } from '../containers/user/userInfo/reducer'
import { iCartPart } from '../containers/cart/reducer'
import { getAmountTotal } from '../util/calculation'
import request from '../util/request'
import { iProductDetail } from '../types'
import { getFixedQuantity } from '../util/util'
import { iConfig } from '../containers/configStore'

interface IProps extends RouteComponentProps {
  cartItemsCount: number,
  getCartItemsCount: () => void,
  user: iUserPart,
  getUserInfo: () => void,
  clearUserInfo: () => void,
  cart: iCartPart,
  config: iConfig,
  controlModal: (showModal: boolean, message: string | JSX.Element) => void,
  setLoading: (loading: boolean) => void,
  loading: boolean
}

interface IState {
  searchText: string,
  showModal: boolean,
  CSVFile: any,
  uploadErrorMessage: string
}

interface iCartProduct {
  productID: number,
  addedQuantity: number
}

interface iFixedSKU {
  productCode: string,
  fixedMessage: string
}

class Bar extends React.Component<IProps,IState> {
  constructor(props: IProps) {
    super(props)
    props.getUserInfo()
    props.getCartItemsCount()
    this.state = {
      searchText: '',
      showModal: false,
      CSVFile: undefined,
      uploadErrorMessage: ''
    }
  }

  onSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({searchText: event.target.value})
  }

  onSearch = () => {
    if (this.state.searchText === '') {
      return
    }
    this.props.history.push('/list/search&productName=' + encodeURIComponent(this.state.searchText))
    this.setState({searchText: ''})
  }

  onSearchKeyUp = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      this.onSearch()
    }
  }

  logout = () => {
  	localStorage.setItem('token', '')
  	this.props.clearUserInfo()
    window.location.reload()
  }

  openModal = () => {
    this.setState({showModal: true})
  }

  onCSVFileChange = (event: any) => {
    this.setState({CSVFile: event.target.files[0]})
  }

  onUpload = (CSVFile: any) => {
    let that = this
    that.props.setLoading(true)
    let formData = new FormData()
    formData.append('fileType', 'product')
    formData.append('CSVFile', CSVFile)
    request(
      `${API_URL}/basic/wholesale/checkCSV`,
      {
        method: "POST",
        body: formData
      }
    ).then(function(data) {
      if (data && data.csvInfo && data.csvInfo.productCode) {
        that.getImportProducts(data)
      } else {
        that.setState({uploadErrorMessage: 'Network error or No productCode in Csv'})
      }
    }, function() {
      that.setState({uploadErrorMessage: 'Data error'})
      that.props.setLoading(false)
    })
  }

  getImportProducts = (data: any) => {
    let that = this
    let { lowStockThreshold } = that.props.config.basicConfig
    let selectPromiseArr: Array<Promise<void>> = []
    let importProducts: Array<iCartProduct> = []
    let noMatchSku = ''
    let fixedSKUArray: Array<iFixedSKU> = []
    data.csvInfo.productCode.map(function(productCode: string, index: number) {
      selectPromiseArr.push(
        request(
          `${API_URL}/basic/wholesale/productBranch/select?productCode=${productCode}`
        ).then(function(product: iProductDetail) {
          if (product.productBranch && product.productBranch.length > 0) {
            let stock = product.productBranch[0].quantity
            let numberEachBatch = product.numberEachBatch
            let cartProduct: iCartProduct = {productID: product.productID, addedQuantity: 0}
            if (data.csvInfo && data.csvInfo.purchaseQuantity && data.csvInfo.purchaseQuantity[index]) {
              let addedQuantity = data.csvInfo.purchaseQuantity[index]
              if (addedQuantity % numberEachBatch > 0) {
                let fixedQty = getFixedQuantity(stock, Number(lowStockThreshold), addedQuantity, numberEachBatch)
                fixedSKUArray.push({productCode: productCode, fixedMessage: `from ${addedQuantity} to ${fixedQty}`})
                addedQuantity = fixedQty
              }
              cartProduct.addedQuantity = Number(addedQuantity)
            }
            importProducts.push(cartProduct)
          } else {
            noMatchSku += (noMatchSku ? ', ' : '') + productCode
          }
        }, function() {
          noMatchSku += (noMatchSku ? ', ' : '') + productCode
        })
      )
    })
    Promise.all(selectPromiseArr).then(function() {
      let addCartPromisArr: Array<Promise<void>> = []
      importProducts.map((item: iCartProduct) => {
        addCartPromisArr.push(
          request(
            `${API_URL}/basic/webstore/shoppingCart/add`,
            {
              method: "POST",
              body: JSON.stringify({
                productID: item.productID,
                addedQuantity: item.addedQuantity
              })
            }
          )
        )
      })
      Promise.all(addCartPromisArr).then(() => {
        that.props.history.push('/cart')
        if (noMatchSku !== '' || fixedSKUArray.length > 0) {
          that.promptDisplay(noMatchSku, fixedSKUArray)
        } else {
          that.props.controlModal(true, 'Import Success.')
        }
        that.setState({showModal: false, uploadErrorMessage: ''})
        that.props.setLoading(false)
      }, function () {
        that.setState({uploadErrorMessage: 'Add to cart error.'})
        that.props.setLoading(false)
      })
    })
  }

  promptDisplay = (noMatchSku: string, fixedSKUArray: Array<iFixedSKU>) => {
    let reminderText = (
      <div className="import-message">
        {
          noMatchSku ?
          <p>
            <div>Some products unmatch or out of stock.</div>
            <div>{noMatchSku}</div>
          </p> : ''
        }
        {
          fixedSKUArray.length > 0 ?
          <p>
            <div>The order quantity should be multiple inner quantity.</div>
            <div>We have fixed some quantity issues for you.</div>
            <div>
            {
              fixedSKUArray.map((item) => (
                <div>
                  <label>{item.productCode}</label>
                  <span>{item.fixedMessage}</span>
                </div>
              ))}
            </div>
          </p> : ''
        }
      </div>
    )
    this.props.controlModal(true, reminderText)
  }

  closeModal = () => {
    this.setState({showModal: false})
  }

  render() {
    const { cartItemsCount, user, cart } = this.props
    const { member } = user.userInfo
    const { cartItems, cartProMap } = cart
    return (
      <div className="bar">
        <Link to="/" className="bar__logo"></Link>
        <div className="bar__search">
          <input type="text" placeholder="Searching.."
            value={this.state.searchText} onChange={this.onSearchTextChange} onKeyUp={this.onSearchKeyUp} />
          <span onClick={this.onSearch} className="icon-search"></span>
        </div>
        <div className="bar__info">
          <span className="bar__info__cart">
            <Link to="/cart">
              <span>Cart</span>
              <label>/</label>
              <span>${getAmountTotal(cartItems, cartProMap)}</span>
              <span className="icon-shopping-cart"></span>
              <span className="bar__info__cart__count">{cartItemsCount}</span>
            </Link>
            <div className="cart__block">
              <div className="cart__block__detail">
              {
                cartItems.map((item, index) => {
                  let cartProduct = cartProMap[item.productID]
                  return (
                    <div key={index} className="cart__block__line">
                      <div className="col-8">
                        {
                          cartProduct.picPath
                          ? <img src={URL + cartProduct.picPath} width="35" />
                          : <img src="../../styles/images/example-image.jpg" width="35" />
                        }
                        <span className="text-one_line">{cartProduct.productName}</span>
                      </div>
                      <div className="col-4">
                        {item.addedQuantity}
                        <span>@</span>
                        ${cartProduct.productBranch.length > 0
                          ? cartProduct.productBranch[0].salePrice.toFixed(2)
                          : cartProduct.recommandPrice.toFixed(2)}
                      </div>
                    </div>
                  )
                })
              }
              </div>
              <div className="cart__block__total">Subtotal: ${getAmountTotal(cartItems, cartProMap)}</div>
              <Link to="/cart"><div className="button">View My Cart</div></Link>
            </div>
          </span>
          <span className="icon-upload" onClick={this.openModal}></span>
          <span>|</span>
          <span className="bar__info__user">
            <Link to={user.hasGotUserInfo ? '/user/userInfo' : '/login'}>
              <span className="icon-user"></span>
              {user.hasGotUserInfo ? member.firstName: 'Login' }
            </Link>
            <span>/</span>
            {
              user.hasGotUserInfo
              ? <a onClick={this.logout}>Logout</a>
              : <Link to='/register'>Register</Link>
            }
          </span>
        </div>
        {
          this.state.showModal
          ? 
          <div className="upload">
            <div className="modal-backdrop"></div>
            <div className="modal">
              <div className="modal-header">Cart Import</div>
              <div className="modal-body">
                <p>Choose Csv:<input type="file" onChange={this.onCSVFileChange} />
                {
                this.props.loading
                  ? <div className="button button-small button-row button-disabled">Upload</div>
                  : <div className="button button-small button-row" onClick={()=>this.onUpload(this.state.CSVFile)}>Upload</div>
                }</p>
                <p>**productCode is necessary in csv file. If needed quantity, please add to purchaseQuantity column.</p>
                <p className="error">{this.state.uploadErrorMessage}</p>
              </div>
              <div className="modal-footer">
                <span className="button button-small" onClick={this.closeModal}>Close</span>
              </div>
            </div>
          </div> : ''
        }
      </div>
    )
  }
}

export default withRouter(Bar)