import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { iProduct } from '../types'
import { URL } from '../config'
import { iAppState } from '../configureReducer'
import { isClubPriceDisplay } from '../containers/list/action'
import { addToCart } from '../containers/product/action'
import { iConfig } from '../containers/configStore'
import { controlModal } from '../common/globalModal/store'
import { getFixedQuantity, purchaseDisable } from '../util/util'

export interface IProps {
  product: iProduct,
  appliedStyleClass: string,
  addToCart: (productID: number, quantity: number) => void,
  isClubPriceDisplay: (activityStatus: string) => boolean,
  config: iConfig,
  controlModal: (showModal: boolean, message: string) => void,
  hasGotUserInfo: boolean
}

interface IState {
  addedQuantity: number,
  showModal: boolean,
  modalMessage: string
}

class ProductCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      addedQuantity: props.product.numberEachBatch,
      showModal: false,
      modalMessage: ''
    }
  }

  onPurQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { product, controlModal, config } = this.props
    let stock = product.productBranch.quantity
    let lowStockThreshold = Number(config.basicConfig.lowStockThreshold)
    let value = Number(e.target.value)
    if (isNaN(value)) {
      return
    }
    if (value === 0) {
      controlModal(true, "At least 1 piece")
      return
    }
    if (purchaseDisable(stock, value, lowStockThreshold)) {
      let quantityLeft = stock - lowStockThreshold
      controlModal(true, 'Sorry, no enough stock! Only ' + quantityLeft + ' left.')
      return
    }
    this.setState({addedQuantity: value})
  }

  onChangeButtonClick = (type: string) => {
    let addedQuantity: number = this.state.addedQuantity
    let { product, config } = this.props
    let futureQty: number = type === 'plus' ? (addedQuantity + product.numberEachBatch) : (addedQuantity - product.numberEachBatch)
    if (purchaseDisable(product.productBranch.quantity, futureQty, Number(config.basicConfig.lowStockThreshold))) {
      this.props.controlModal(true, 'Sorry, no enough stock!')
      return
    }
    switch (type) {
      case 'plus':
        addedQuantity = addedQuantity + product.numberEachBatch
        break
      case 'minus':
        addedQuantity = addedQuantity - product.numberEachBatch
        break
    }
    this.setState({addedQuantity: addedQuantity})
  }

  onAddClick = (productID: number) => {
    let addedQuantity: number = this.state.addedQuantity
    let { product, config, addToCart } = this.props
    let stock = product.productBranch.quantity
    let numberEachBatch = product.numberEachBatch
    let lowStockThreshold = Number(config.basicConfig.lowStockThreshold)
    if (addedQuantity % numberEachBatch > 0) {
      let fixedQuantity = getFixedQuantity(stock, lowStockThreshold, addedQuantity, numberEachBatch)
      this.openModal('Adjust quantity to ' + fixedQuantity + ' due to multiple of inner quantity and stock left.')
      this.setState({addedQuantity: fixedQuantity})
    } else {
      addToCart(productID, addedQuantity)
    }
  }

  openModal = (message: string) => {
    this.setState({showModal: true, modalMessage: message})
  }

  closeModal = () => {
    this.setState({showModal: false, modalMessage: ''})
  }

  onOKClick = () => {
    this.closeModal()
    this.props.addToCart(this.props.product.productID, this.state.addedQuantity)
  }

  render() {
    const { isClubPriceDisplay, product, appliedStyleClass, config, hasGotUserInfo } = this.props
    let addedQuantity = this.state.addedQuantity
    let lowStockThreshold = Number(config.basicConfig.lowStockThreshold)
    let stock = product.productBranch.quantity
    let enableBuyNo = stock - lowStockThreshold
    return (
      <div key={product.productID} className={appliedStyleClass}>
        <Link to={'/product/detail/' + product.productID} className="product__pic">
          {
            product.picPath
            ? <img src={URL + product.picPath} />
            : <img src="../../styles/images/example-image.jpg" />
          }
          {
            product.productBranch.promotionSaleStatus
              ? <div className="product__promotion"></div> : ''
          }
          {
            purchaseDisable(stock, addedQuantity, lowStockThreshold)
              ? <div className="product__soldout"></div> : ''
          }
        </Link>
        <div>
          <Link className="product__text" to={'/product/detail/' + product.productID}>
            {product.productName}
          </Link>
        </div>
        {
          hasGotUserInfo ?
          <div className="product__line">
            <label>Stock:</label>
            <span>
            {
              stock > lowStockThreshold && enableBuyNo > product.numberEachBatch
              ? enableBuyNo : 'Out Stock'
            }
            </span>
          </div> : ''
        }
        {
          hasGotUserInfo ?
            <div className="product__obvious product__detail">
              {
                isClubPriceDisplay(product.productBranch.activityStatus)
                ? ('$' + product.productBranch.activityDiscountPrice.toFixed(2)) : ''
              }
              <span>
                ${product.productBranch.salePrice.toFixed(2)}
              </span>
              {
                purchaseDisable(stock, addedQuantity, lowStockThreshold) ? '' :
                <span className="product__detail__quantity">
                  {
                    addedQuantity > product.numberEachBatch
                    ? <span className="quantity__minus" onClick={()=>this.onChangeButtonClick('minus')}>-</span>
                    : <span className="quantity__minus button-disabled">-</span>
                  }
                  <input type="text" id="quantity" value={addedQuantity} onChange={this.onPurQuantityChange} />
                  <span className="quantity__plus" onClick={()=>this.onChangeButtonClick('plus')}>+</span>
                </span>
              }
              {
                purchaseDisable(stock, addedQuantity, lowStockThreshold) ? '' :
                <span className="icon-cart-plus product__add" onClick={()=>this.onAddClick(product.productID)}></span>
              }
            </div>
          : <div className="product__line">Login to view price</div>
        }
        {
          this.state.showModal ?
          <div>
            <div className="modal">
              <div className="modal-body">
                <p className="reminder-text">{this.state.modalMessage}</p>
              </div>
              <div className="modal-footer">
                <span className="button button-small" onClick={this.onOKClick}>OK</span>
              </div>
            </div>
            <div className="modal-backdrop"></div>
          </div> : ''
        }
      </div>
    )
  }
}

const mapStateToProps = (state: iAppState) => ({})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators<any, {
  addToCart: (productID: number, quantity: number) => void,
  isClubPriceDisplay: (activityStatus: string) => boolean 
}> ({
  addToCart,
  isClubPriceDisplay,
  controlModal
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard)