import { iCartItem, iCartProMap } from '../../types'
import { CartAction, SET_CART_LOADING, GET_CART_DETAIL, CART_QUANTITY_INCREMENT,
  CART_QUANTITY_DECREMENT, SET_CART_QUANTITY, CHECK_CLICK} from './action'

export interface iCartPart {
  cartItems: Array<iCartItem>,
  cartProMap: iCartProMap,
  loading: boolean,
  allChecked: boolean
}

// 处理并返回 state 
export default (
  state = {cartItems: [], cartProMap: {}, loading: true}, action: CartAction
) => {
  let cartItems: Array<iCartItem> = [...state.cartItems]
  switch (action.type) {
    case GET_CART_DETAIL:
      return Object.assign({}, state, {cartItems: action.cartItems, cartProMap: action.cartProMap})
    case CART_QUANTITY_INCREMENT:
      cartItems[action.index].addedQuantity += action.numberEachBatch
      return Object.assign({}, state, cartItems)
    case CART_QUANTITY_DECREMENT:
      cartItems[action.index].addedQuantity -= action.numberEachBatch
      return Object.assign({}, state, cartItems)
    case SET_CART_QUANTITY:
      cartItems[action.index].addedQuantity = action.addedQuantity
      return Object.assign({}, state, cartItems)
    case CHECK_CLICK:
      return Object.assign({}, state, {cartItems: action.cartItems, allChecked: action.allChecked})
    case SET_CART_LOADING:
      return Object.assign({}, state, {loading: action.loading})
    default:
      return state
  }
}