import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../configureReducer'
import PasswordReset from '@components/PasswordReset'
import { setLoading } from '../common/loading/action'

const mapStateToProps = (state: iAppState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators ({
  setLoading
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)