import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import '../styles/main.scss'
import rootReducer from './configureReducer'
import App from './app'

// 1、创建 store
export const store = createStore(rootReducer, applyMiddleware(thunk))

ReactDOM.render(
  // 2、然后使用react-redux的Provider将props与容器连通起来
  <Provider store={ store }>
    <App />
  </Provider>,
  document.getElementById('appContainer')
);

console.log(store.getState())
