import { Dispatch } from 'redux'
import request from '../../util/request'
import { API_URL } from '../../config'
import { iProduct } from '../../types'
import { condition } from '../branchStore'

interface iCarousel {
  configID: number,
  picPath: string,
  categoryID: number,
  categoryName: string,
  categoryLevel: string
}

export const GET_CAROUSEL = "GET_CAROUSEL"
export type GET_CAROUSEL = typeof GET_CAROUSEL

export const GET_NEW_ARRIVALS = "GET_NEW_ARRIVALS"
export type GET_NEW_ARRIVALS = typeof GET_NEW_ARRIVALS

export const GET_HOT_PRODUCTS = "GET_HOT_PRODUCTS"
export type GET_HOT_PRODUCTS = typeof GET_HOT_PRODUCTS

export const GET_PROMOTIONS = "GET_PROMOTIONS"
export type GET_PROMOTIONS = typeof GET_PROMOTIONS

export interface IGET_CAROUSELAction {
  type: GET_CAROUSEL,
  carousel: Array<iCarousel>
}

export const getCarousel = () => {
  return (dispatch: Dispatch) => {
    request(
      `${API_URL}/basic/webstore/carousel/select?mainType=wholesale`
    ).then((carousel: Array<iCarousel>) => {
      dispatch({
        type: GET_CAROUSEL,
        carousel
      })
    })
  }
}

export interface IGET_NEW_ARRIVALSAction {
  type: GET_NEW_ARRIVALS,
  newArrivals: Array<iProduct>
}

export const getNewArrivals = () => {
  return (dispatch: Dispatch) => {
    request(
      `${API_URL}/basic/wholesale/productsNew/select`
    ).then((newArrivals: Array<iProduct>) => {
      dispatch({
        type: GET_NEW_ARRIVALS,
        newArrivals
      })
    })
  }
}

export interface IGET_HOT_PRODUCTSAction {
  type: GET_HOT_PRODUCTS,
  hotProducts: Array<iProduct>
}

export const getHotProducts = () => {
  return (dispatch: Dispatch) => {
    request(
      `${API_URL}/basic/wholesale/productsHot/select`
    ).then((hotProducts: Array<iProduct>) => {
      dispatch({
        type: GET_HOT_PRODUCTS,
        hotProducts
      })
    })
  }
}

export interface IGET_PROMOTIONSAction {
  type: GET_PROMOTIONS,
  promotions: Array<iProduct>
}

export const getPromotions = () => {
  return (dispatch: Dispatch) => {
    request(
      `${API_URL}/basic/webstore/productsPromotion/select${condition ? ('?' + condition) : ''}`
    ).then((promotions: Array<iProduct>) => {
      dispatch({
        type: GET_PROMOTIONS,
        promotions
      })
    })
  }
}

type HomeAction = IGET_NEW_ARRIVALSAction | IGET_HOT_PRODUCTSAction
  | IGET_CAROUSELAction | IGET_PROMOTIONSAction

export interface iHomePage {
  carousel: Array<iCarousel>,
  newArrivals: Array<iProduct>,
  hotProducts: Array<iProduct>,
  promotions: Array<iProduct>
}

export default (
  state = {newArrivals: [], hotProducts: [], promotions: []}, action: HomeAction
) => {
  switch (action.type) {
    case GET_CAROUSEL:
      return Object.assign({}, state, {carousel: action.carousel})
    case GET_NEW_ARRIVALS:
      return Object.assign({}, state, {newArrivals: action.newArrivals})
    case GET_HOT_PRODUCTS:
      return Object.assign({}, state, {hotProducts: action.hotProducts})
    case GET_PROMOTIONS:
      return Object.assign({}, state, {promotions: action.promotions})
    default:
      return state
  }
}