import { iBasicConfig } from 'containers/configStore'
import { iCartItem, iProductDetail, iCartProMap } from '../types'

const lowestFreeShipping: number = 100
const northShipping: number = 7
const southShipping: number = 14

export function getSelectedCount(items: Array<iCartItem>) {
  let selectedCount: number = 0
  items.map((item) => {
    if (item.checked) {
      selectedCount += item.addedQuantity
    }
  })
  return selectedCount
}

// original product amount
export function getAmount(item: iCartItem, product: iProductDetail) {
  let price = product.productBranch.length > 0
    ? product.productBranch[0].salePrice
    : product.recommandPrice
  let quantity = item.addedQuantity
  return (price * quantity).toFixed(2)
}

export function getAmountAfterDis(item: iCartItem, product: iProductDetail) {
  let productBranch = product.productBranch[0]
  let quantity = item.addedQuantity
  let price = product.productBranch[0].salePrice
  let discount = 0
  if (product.clubPrice) {
    price = product.clubPrice
  } else if (productBranch.promotionType) {
    switch (productBranch.promotionType) {
      case 'discount':
        discount = productBranch.promotionSaleDiscount
        break
      case 'special':
        discount = productBranch.promotionSaleSpecial
        break
      case 'buyNGiveM':
        if (quantity >= productBranch.promotionSaleBuyGiveN) {
          quantity = quantity - getBuyNGiveMGiveQty(quantity, productBranch.promotionSaleBuyGiveN, productBranch.promotionSaleBuyGiveM)
          discount = 0
        }
        break
      case 'buyNDiscount':
        if (quantity >= productBranch.promotionSaleBuyDiscountN) {
          discount = productBranch.promotionSaleBuyDiscountD
        }
        break
    }
  }
  return (price*quantity*(100 - discount)/100).toFixed(2)
}

function getBuyNGiveMGiveQty(quantity: number, n: number, m: number) {
  let buyGroupNumber = (quantity/(n + m)).toString()
  return parseInt(buyGroupNumber) * m
}

// original selected products total
export function getSelectedTotal(items: Array<iCartItem>, proMap: iCartProMap) {
  let selecedTotal: number = 0
  items.map((item) => {
    let product = proMap[item.productID]
    if (item.checked) {
      selecedTotal += Number(getAmount(item, product))
    }
  })
  return selecedTotal.toFixed(2)
}

export function getGst(amountTotal: number) {
  let rate = 0.15
  return (amountTotal * rate).toFixed(2)
}

export function getSubtotal(amountTotal: number, gst: number) {
  return (amountTotal + gst).toFixed(2)
}

export function getSelectedTotalAfterDis(items: Array<iCartItem>, proMap: iCartProMap, discount: number) {
  let otherProductTotal = 0
  let clubProductTotal = 0
  let promotionProductTotal = 0
  items && items.map((item) => {
    if (item.checked) {
      let product = proMap[item.productID]
      let productBranch = product.productBranch[0]
      if (product.clubPrice) {
        clubProductTotal = clubProductTotal + Number(getAmountAfterDis(item, product))
      } else if (productBranch.promotionType) {
        promotionProductTotal = promotionProductTotal + Number(getAmountAfterDis(item, product))
      } else {
        otherProductTotal = otherProductTotal + Number(getAmount(item, product))
      }
    }
  })
  if (discount) {
    otherProductTotal = otherProductTotal*(100 - discount)/100
  }
  return (otherProductTotal + clubProductTotal + promotionProductTotal).toFixed(2)
}

// selected products total disounted
export function getSelectedDiscounted(items: Array<iCartItem>, proMap: iCartProMap) {
  let selectedDiscounted: number = 0
  items.map((item) => {
    if (item.checked) {
      let product = proMap[item.productID]
      let amount = Number(getAmount(item, product))
      let amountAfterDis = Number(getAmountAfterDis(item, product))
      selectedDiscounted += (amount - amountAfterDis)
    }
  })
  return selectedDiscounted.toFixed(2)
}

export function getAmountTotal(items: Array<iCartItem>, proMap: iCartProMap) {
  let amountTotal: number = 0
  items.map((item) => {
    let product = proMap[item.productID]
    amountTotal += Number(getAmount(item, product))
  })
  return amountTotal.toFixed(2)
}

export function getShipping(amountTotal: number, region: number, config: iBasicConfig) {
  let shipping: number = 0
  if (localStorage.getItem('branchID')) {
    shipping = 0
  } else {
    if (region < 10) {
      if (amountTotal < Number(config.northFreeShipping)) {
        shipping = amountTotal * Number(config.northShippingRate) / 100
      }
    } else {
      if (amountTotal < Number(config.southFreeShipping)) {
        shipping = amountTotal * Number(config.southShippingRate) / 100
      }
    }
    if (region === 0) {
      shipping = 0
    }
  }
  return shipping
}