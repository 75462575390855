import React from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom"
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from './configureReducer'
import { getBasicConfig } from './containers/configStore'
import request from './util/request'
import { API_URL } from './config'
import '../styles/main.scss'
import ListConnect from './containers/list/index'
import Register from './containers/register'
import Home from './containers/home/index'
import RegisterActivate from '@components/RegisterActivate'
import Login from './containers/login'
import ProductConnect from './containers/product/index'
import CartConnect from './containers/cart/index'
import CheckoutConnect from './containers/checkout/index'
import PaymentConnect from './containers/payment/index'
import User from './containers/user/User'
import PasswordLost from './containers/passwordLost'
import PasswordReset from './containers/passwordReset'
import { getCartDetail } from './containers/cart/action'
import Contacts from '@components/introduction/Contacts'
import AboutUs from '@components/introduction/AboutUs'
import StoreLocator from '@components/introduction/StoreLocator'
import DeliveryAndReturns from '@components/introduction/DeliveryAndReturns'
import PaymentEnquiries from '@components/introduction/PaymentEnquiries'
import PrivacyPolicy from '@components/introduction/PrivacyPolicy'

interface IProps {
  getBasicConfig: () => void,
  getCartDetail: () => void
}

class app extends React.Component<IProps> {

  componentDidMount() {
    this.props.getBasicConfig()
    this.props.getCartDetail()
    request(
      `${API_URL}/basic/webstore/systemLog/create`,
      {
        method: "POST",
        body: JSON.stringify({
          logName: 'view',
          logSource: 'frontend',
          logType: 'frontend',
          subType: 'wholesale',
          logDetail: 'page view'
        })
      }
    )
  }

  render() {
    return (
      <Router>
        <Route exact path='/' component={Home} />
        <Route exact path='/list/:selectString' component={ListConnect} />
        <Route exact path='/list/:selectString/sortBy/:sortString?' component={ListConnect} />
        <Route exact path='/list/:selectString/page/:page?' component={ListConnect} />
        <Route exact path='/list/:selectString/sortBy/:sortString?/page/:page?' component={ListConnect} />
        <Route exact path='/register' component={Register} />
        <Route path='/register/acitvate/:activateString' component={RegisterActivate} />
        <Route exact path='/login' component={Login} />
        <Route path='/product/detail/:productID' component={ProductConnect} />
        <Route path='/cart' component={CartConnect} />
        <Route path='/checkout/detail' component={CheckoutConnect} />
        <Route path='/billPay/:receiptNumber' component={PaymentConnect} />
        <Route path='/user' component={User} />
        <Route path='/password/lost' component={PasswordLost} />
        <Route path='/password/reset/:resetString' component={PasswordReset} />
        <Route path='/contacts' component={Contacts} />
        <Route path='/aboutUs' component={AboutUs} />
        <Route path='/storelocator' component={StoreLocator} />
        <Route path='/deliveryReturns' component={DeliveryAndReturns} />
        <Route path='/paymentEnquiries' component={PaymentEnquiries} />
        <Route path='/privacyPolicy' component={PrivacyPolicy} />
      </Router>
    )
  }
}

const mapStateToProps = (state: iAppState) => ({
  config: state.config
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators ({
  getBasicConfig,
  getCartDetail
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(app)