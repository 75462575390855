import { AddressListAction, GET_ADDRESS_LIST, MOUSE_CHANGE } from './action'
import { iAddress } from '../../../types'

export interface iAddressListPart {
  addresses: Array<iAddress>
}

export default (
  state = {addresses: []}, action: AddressListAction
) => {
  switch (action.type) {
    case GET_ADDRESS_LIST:
      return Object.assign({}, state, {addresses: action.addresses})
    case MOUSE_CHANGE:
      return Object.assign({}, state, {addresses: action.addresses})
    default:
      return state
  }
}