import { IGET_ORDER_LISTAction, GET_ORDER_LIST, iOrderMap } from './action'
import { iOrder } from '../../../types'

export interface iOrderListPart {
  orders: Array<iOrder>,
  orderMap: iOrderMap,
  curPage: string,
  allPage: string,
  loading: boolean
}

export default (
  state = {orders: [], orderMap: {}, curPage: '1', allPage: '1', loading: true}, action: IGET_ORDER_LISTAction
) => {
  switch (action.type) {
    case GET_ORDER_LIST:
      return Object.assign({}, state, {orders: action.orders, orderMap: action.orderMap,
        curPage: action.curPage, allPage: action.allPage, loading: false})
    default:
      return state
  }
}