import React from 'react'

export interface IProps {
  loading: boolean
}

export default class Loading extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
  }

  render() {
    const { loading } = this.props
    return (
      <div>
      {
        loading ?
          <div>
            <div className="modal modal-transparent">
              <div className="modal-body">
                <span className="processing__image"></span>
              </div>
            </div>
            <div className="modal-backdrop"></div>
          </div> : ''
      }
      </div>
    )
  }
}