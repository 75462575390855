import React from 'react'
import { iAddressListPart } from './reducer'
import AdrressConnect from '../address/index'
import { iAddressCreateRes } from '../../../types/response'
import { iAddressPart } from '../address/reducer'
import DeleteConfirm from '../../../common/deleteConfirm/index'
import { regionMap } from '../address/Address'

interface IProps {
  addressList: iAddressListPart,
  getAddressList: () => void,
  saveAddress: (addressId?: number) => Promise<iAddressCreateRes>,
  getEditingItem: (addressId: number)=> void,
  address: iAddressPart,
  clearAddressDetail: () => void,
  setLoading: (loading: boolean) => void,
  deleteAddress: (addressId: number) => void,
  closeAddressList: () => void,
  onMouseChange: (index: number, mouseOver: boolean) => void,
  isCheckoutPage: boolean,
  loading: boolean,
  controlDeleteModal: (onDelete: () => void, showModal: boolean) => void,
  checkAddressInput: () => string
}

interface IState {
  showModal: boolean,
  errorMessage: string
}

export default class AddressList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    props.getAddressList()
    this.state = {
      showModal: false,
      errorMessage: ''
    }
  }

  openModal = () => {
    this.setState({showModal: true})
  }

  closeModal = () => {
    this.setState({showModal: false, errorMessage: ''})
    this.props.clearAddressDetail()
    this.props.getAddressList()
  }

  onSaveClick = () => {
    let that = this
    const { addressId } = that.props.address
    let errorMessage = that.props.checkAddressInput()
    if (errorMessage) {
      that.setState({errorMessage: errorMessage})
      return
    }
    that.props.setLoading(true)
    that.props.saveAddress(addressId ? addressId : undefined).then(() => {
      that.closeModal()
    }, function() {
      console.log(arguments)
      that.props.setLoading(false)
    })
  }

  onEditClick = (addressId: number) => {
    this.props.setLoading(true)
    this.props.getEditingItem(addressId)
    this.openModal()
  }

  onSelectClick = (addressId: number) => {
    if (!this.props.isCheckoutPage) {
      return
    }
    this.props.setLoading(true)
    this.props.getEditingItem(addressId)
    this.props.closeAddressList()
  }

  onDeleteClick = (addressId: number) => {
    this.props.controlDeleteModal(()=>this.props.deleteAddress(addressId), true)
  }

  render() {
    const { addressList, onMouseChange, isCheckoutPage, loading } = this.props
    return (
      <div className={isCheckoutPage ? '' : 'user__content'}>
        {
          isCheckoutPage ? '' :
            <div className="user__content__operate">
              <div className="button button-small" onClick={this.openModal}>Add New</div>
            </div>
        }
        <div>
          <div className="table__line table__header">
            <div className="col-2">Recipient</div>
            <div className="col-4">Address</div>
            <div className="col-2">Phone</div>
            <div className="col-2">Email</div>
            {isCheckoutPage ? '' : <div className="col-2">Operate</div>}
          </div>
          {
            addressList.addresses.length > 0 && addressList.addresses.map((address, index) => {
              let classApplied = 'table__line table__detail'
              if (isCheckoutPage) {
                classApplied += ' table__line-checkAbled'
              }
              if (address.mouseOver) {
                classApplied += ' select-current'
              }
              return (
                <div key={address.id} className={classApplied}
                  onClick={()=>this.onSelectClick(address.id)}
                  onMouseOver={()=>onMouseChange(index, true)}
                  onMouseOut={()=>onMouseChange(index, false)}>
                  <div className="col-2 table__detail__normal">
                    <div>{address.firstName + ' ' + address.lastName}</div>
                  </div>
                  <div className="col-4 table__detail__break">
                    <div>{address.address}</div>
                    <div>
                    {
                      address.townOrCity
                      + (address.stateOrCounty ? ', ' : '') + regionMap[address.stateOrCounty]
                      + (address.postcode ? ', ' : '') + address.postcode
                    }
                    </div>
                  </div>
                  <div className="col-2 table__detail__normal">
                    {address.mobilePhone}
                  </div>
                  <div className="col-2 table__detail__normal">
                    {address.email}
                  </div>
                  {
                    isCheckoutPage ? '' :
                      <div className="col-2 table__detail__normal">
                        <span className="icon-edit icon__font item-left" onClick={()=>this.onEditClick(address.id)}></span>
                        <span className="icon-trash-o icon__font" onClick={()=>this.onDeleteClick(address.id)}></span>
                      </div>
                  }
                </div>
              )
            })
          }
        </div>
        {
          !loading && addressList.addresses.length == 0 ? <p>No Saved Address</p> : ''
        }
        {
          this.state.showModal ?
          <div>
            <div className="modal modal-middle">
              <div className="modal-body">
                <AdrressConnect />
              </div>
              <div className="modal-footer">
                <p className="error">{this.state.errorMessage}</p>
                <span className="button button-small item-left" onClick={this.onSaveClick}>Save</span>
                <span className="button button-small" onClick={this.closeModal}>Cancel</span>
              </div>
            </div>
            <div className="modal-backdrop"></div>
          </div> : ''
        }
        <DeleteConfirm />
      </div>
    )
  }
}