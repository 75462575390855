import React from 'react'
import { Link } from 'react-router-dom'

interface IProps {
  curPage: string,
  allPage: string,
  pagePathParent: string,
}

export default class Pagination extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
  }

  render() {
    const { curPage, allPage, pagePathParent } = this.props
    let curPageNo: number = Number(curPage)
    let allPageNo: number = Number(allPage)
    let paginationCount: Array<number> = []
    for (let page = 1; Number(page) <= Number(allPageNo); page++) {
      paginationCount.push(page)
    }
    return (
      <div className="content__pagi">
        {
          allPageNo <= 6
          ?
            paginationCount.map((page) => (
              <Link key={page} className={curPageNo == page ? 'currentPage' : ''}
                to={pagePathParent + page}>{page}</Link>
            ))
          :
            (curPageNo < 5
            ?
              <span>
                <Link className={curPageNo == 1 ? 'currentPage' : ''} to={pagePathParent + '1'}>1</Link>
                <Link className={curPageNo == 2 ? 'currentPage' : ''} to={pagePathParent + '2'}>2</Link>
                <Link className={curPageNo == 3 ? 'currentPage' : ''} to={pagePathParent + '3'}>3</Link>
                <Link className={curPageNo == 4 ? 'currentPage' : ''} to={pagePathParent + '4'}>4</Link>
                <Link className={curPageNo == 5 ? 'currentPage' : ''} to={pagePathParent + '5'}>5</Link>
                <span className="content__pagi__points">...</span>
                <Link className={curPageNo == allPageNo ? 'currentPage' : ''} to={pagePathParent + allPageNo}>{allPageNo}</Link>
              </span>
            : 
              ((allPageNo - curPageNo) < 4
              ?
                <span>
                  <Link className={curPageNo == 1 ? 'currentPage' : ''} to={pagePathParent + '1'}>1</Link>
                  <span className="content__pagi__points">...</span>
                  <Link className={curPageNo == allPageNo - 4 ? 'currentPage' : ''}
                    to={pagePathParent + (allPageNo - 4)}>{allPageNo - 4}</Link>
                  <Link className={curPageNo == allPageNo - 3 ? 'currentPage' : ''}
                    to={pagePathParent + (allPageNo - 3)}>{allPageNo - 3}</Link>
                  <Link className={curPageNo == allPageNo - 2 ? 'currentPage' : ''}
                    to={pagePathParent + (allPageNo - 2)}>{allPageNo - 2}</Link>
                  <Link className={curPageNo == allPageNo - 1 ? 'currentPage' : ''}
                    to={pagePathParent + (allPageNo - 1)}>{allPageNo - 1}</Link>
                  <Link className={curPageNo == allPageNo ? 'currentPage' : ''}
                    to={pagePathParent + allPageNo}>{allPageNo}</Link>
                </span>
              :
                <span>
                  <Link className={curPageNo == 1 ? 'currentPage' : ''} to={pagePathParent + '1'}>1</Link>
                  <span className="content__pagi__points">...</span>
                  <Link className={curPageNo == curPageNo - 2 ? 'currentPage' : ''}
                    to={pagePathParent + (curPageNo - 2)}>{curPageNo - 2}</Link>
                  <Link className={curPageNo == curPageNo - 1 ? 'currentPage' : ''}
                    to={pagePathParent + (curPageNo - 1)}>{curPageNo - 1}</Link>
                  <Link className={curPageNo == curPageNo ? 'currentPage' : ''}
                    to={pagePathParent + curPageNo}>{curPageNo}</Link>
                  <Link className={curPageNo == curPageNo + 1 ? 'currentPage' : ''}
                    to={pagePathParent + (curPageNo - 0 + 1)}>{curPageNo - 0 + 1}</Link>
                  <Link className={curPageNo == curPageNo + 2 ? 'currentPage' : ''}
                    to={pagePathParent + (curPageNo - 0 + 2)}>{curPageNo - 0 + 2}</Link>
                  <span className="content__pagi__points">...</span>
                  <Link className={curPageNo == allPageNo ? 'currentPage' : ''} to={pagePathParent + allPageNo}>{allPageNo}</Link>
                </span>))
        }
      </div>
    )
  }
}
