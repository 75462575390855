import React from 'react'
import { iAddress } from '../../../types'
import { iBranch, iBranchInfo } from '../../branchStore'
import { regionMap } from '../address/Address'
import { RouteComponentProps, Link } from 'react-router-dom'
import { iInvoiceParams } from '../../../types/router'
import { URL } from '../../../config'

import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { iAppState } from '../../../configureReducer'
import { getOrderDetail } from '../order/action'
import { iOrderPart } from '../order/reducer'
import { getBranch } from '../../branchStore'

interface IProps extends RouteComponentProps<iInvoiceParams> {
  order: iOrderPart,
  branch: iBranch,
  getOrderDetail: (receiptNumber: string) => void,
  getBranch: () => void
}

export class Invoice extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    props.getOrderDetail(props.match.params.receiptNumber)
    props.getBranch()
  }

  onPrintClick = () => {
    window.print()
  }

  goBack = () => {
    window.history.back()
  }

  render() {
    const { order, branch } = this.props
    let orderInfo = order.orderInfo
    let orderProductMap = order.orderProductMap
    let supplier: iBranchInfo = branch.branch
    let address: iAddress = order.address
    return (
      <div className="invoice">
        <div className="invoice__action">
          <div className="button button-small" onClick={this.onPrintClick}>Print</div>
          <div className="button button-small" onClick={this.goBack}>Back</div>
        </div>
        <h3 className="reminder-text">Tax Invoice</h3>
        <div className="invoice__summary">
          <div>
            <label>Invoice No.:</label>
            <span>{orderInfo.billID}</span>
          </div>
          <div>
            <label>PO No.:</label>
            <span>{orderInfo.receiptNumber}</span>
          </div>
          <div>
            <label>Date:</label>
            <span>{orderInfo.billGenerateTime}</span>
          </div>
          <div>
            <label>Delivery No.:</label>
            <span>{orderInfo.deliveryNumber}</span>
          </div>
        </div>
        <div className="invoice__summary">
          <div className="invoice__summary__part">
            <div className="part__line">
              <label>Supplier:</label>
              <span>{supplier.tradingName}</span>
            </div>
            <div className="part__line">
              <label>GST No.:</label>
              <span>{supplier.branchGSTNo}</span>
            </div>
            <div className="part__line">
              <label>Sales:</label>
              <span>{supplier.managerID}</span>
            </div>
            <div className="part__line">
              <label>Tel:</label>
              <span>{supplier.contactNo}</span>
            </div>
            <div className="part__line">
              <label>Email:</label>
              <span>{supplier.contactEmail}</span>
            </div>
            <div className="part__line">
              <label>Web:</label>
              <span>{supplier.webUrl}</span>
            </div>
            <div className="part__line">
              <label>Bank Account:</label>
              <span>{supplier.bankAccountNo}</span>
            </div>
          </div>
          <div className="invoice__summary__part">
            <div className="part__line">
              <label>Ordered By:</label>
              <span>{address.firstName + ' ' + address.lastName}</span>
            </div>
            <div className="part__line">
              <label>Company</label>
              <span>{address.companyName}</span>
            </div>
            <div className="part__line">
              <label>Address:</label>
              <span>{
                (address.address || '')
                + (address.townOrCity ? (', ' + address.townOrCity) : '')
                + (address.stateOrCounty ? (', ' + regionMap[address.stateOrCounty]) : '')
                + (address.postcode ? (', ' + address.postcode) : '')
              }</span>
            </div>
            <div className="part__line">
              <label>Tel:</label>
              <span>{address.mobilePhone}</span>
            </div>
            <div className="part__line">
              <label>Email:</label>
              <span>{address.email}</span>
            </div>
          </div>
        </div>
        <div>
          <div className="table__header line_narrow">
            <div className="col-1">Picture</div>
            <div className="col-2">SKU</div>
            <div className="col-4">Name</div>
            <div className="col-1">Price</div>
            <div className="col-1">Ordered</div>
            <div className="col-2">Picked</div>
            <div className="col-1">Total</div>
          </div>
          {
            orderInfo && orderInfo.billContents && orderInfo.billContents.map((content) => {
              let product = orderProductMap[content.productID]
              return (
                <div key={content.billContentID} className="table__detail line_narrow">
                  <div className="col-1">
                      {
                        product.picPath
                        ? <img src={URL + product.picPath} width="30" />
                        : <img src="../../styles/images/example-image.jpg" width="30" />
                      }
                  </div>
                  <div className="col-2">
                    {product.productCode}
                  </div>
                  <div className="col-4">
                    {product.productName}
                  </div>
                  <div className="col-1">
                    ${content.purchasePrice.toFixed(2)}
                  </div>
                  <div className="col-1">
                    {content.quantity}
                  </div>
                  <div className="col-2">
                    {content.pickQuantity}
                  </div>
                  <div className="col-1">
                    ${content.amount.toFixed(2)}
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="invoice__summary invoice__total">
          <div>
            <label>{orderInfo.note ? 'Note:' : ''}</label>
            <span>{orderInfo.note}</span>
          </div>
          <div>
            <div className="total__line">
              <label>Subtotal:</label>
              <span>${orderInfo.subTotal}</span>
            </div>
            <div className="total__line">
              <label>Tax:</label>
              <span>${orderInfo.taxTotal}</span>
            </div>
            <div className="total__line">
              <label>Freight:</label>
              <span>${orderInfo.freight}</span>
            </div>
            <div className="total__line">
              <label>Amount:</label>
              <span>${orderInfo.amountTotal}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: iAppState) => ({
  order: state.order,
  branch: state.branch
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getOrderDetail,
  getBranch
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Invoice)