import React from 'react'
import request from '../../../util/request'
import { API_URL } from '../../../config'
import { Link, RouteComponentProps } from 'react-router-dom'
import { iOrderListPart } from './reducer'
import { iAddress, iOrder } from '../../../types'
import { iOrderListRouterParams } from '../../../types/router'
import Pagination from '@components/Pagination'
import DeleteConfirm from '../../../common/deleteConfirm/index'

interface IProps extends RouteComponentProps<iOrderListRouterParams> {
  orderList: iOrderListPart,
  getOrderList: (props: RouteComponentProps<iOrderListRouterParams>) => void,
  directToPayment: (receiptNumber: string, amountTotal: number, address: iAddress) => void,
  loading: boolean,
  setLoading: (loading: boolean) => void,
  updateOrderStatus: (receiptNumber: string, status: string) => void,
  controlDeleteModal: (onDelete: () => void, showModal: boolean) => void
}

interface IState {
  showModal: boolean,
  curReceiptNo: string
}

interface iOrderStatusMap {
  [key: string]: string
}

export const orderStatusMap: iOrderStatusMap = {
  new: 'New',
  paid: 'Picking',
  printed: 'Picking',
  packaged: 'Packaged',
  delivering: 'Delivered',
  finished: 'Received',
  cancelled: 'Cancelled'
}

export default class OrderList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    props.getOrderList(props)
    this.state = {
      showModal: false,
      curReceiptNo: ''
    }
  }

  componentDidUpdate(prevProps: IProps) {
    let { params } = this.props.match
    if (params !== prevProps.match.params) {
      this.props.getOrderList(this.props)
    }
  }

  onPayClick = (receiptNumber: string, amountTotal: number, addressID: number) => {
    let that = this
    that.props.setLoading(true)
    request(
      `${API_URL}/basic/webstore/shippingAddress/select?id=${addressID}`
    ).then((addressDetail: iAddress) => {
      that.props.directToPayment(receiptNumber, amountTotal, addressDetail)
    })
  }

  openModal = (receiptNumber: string) => {
    this.setState({showModal: true, curReceiptNo: receiptNumber})
  }

  closeModal = () => {
    this.setState({showModal: false, curReceiptNo: ''})
  }

  completeOrder = (receiptNumber: string) => {
    this.props.updateOrderStatus(receiptNumber, 'confirm')
    this.closeModal()
  }

  getCurrentOperate(order: iOrder) {
    const { controlDeleteModal, updateOrderStatus } = this.props
    switch(order.billStatus) {
      case 'new':
        return (
          <div className="col-2 table__detail__normal">
            <div className="button button-small" onClick={()=>
              this.onPayClick(order.receiptNumber, order.amountTotal, order.addressID)}>
                Pay Now
            </div>
          </div>
        )
      case 'delivering':
        return (
          <div className="col-2 table__detail__normal table__detail-double">
            <Link to={"/user/invoice/" + order.receiptNumber}>
              <div className="button button-small button-above">Invoice</div>
            </Link>
            <div className="button button-small" onClick={()=>
              this.openModal(order.receiptNumber)}>Received</div>
          </div>
        )
      case 'finished':
        if (order.paymentStatus === 'N') {
          return (
            <div className="col-2 table__detail__normal table__detail-double">
              <Link to={"/user/invoice/" + order.receiptNumber}>
                <div className="button button-small button-above">Invoice</div>
              </Link>
              <div className="button button-small" onClick={()=>
                this.onPayClick(order.receiptNumber, order.amountTotal, order.addressID)}>
                  Pay Now
              </div>
            </div>
          )
        } else {
          return (
            <div className="col-2 table__detail__normal">
              <Link to={"/user/invoice/" + order.receiptNumber}>
                <div className="button button-small button-above">Invoice</div>
              </Link>
            </div>
          )
        }
      case 'cancelled':
        return (
          <div className="col-2 table__detail__normal">
            <div className="button button-small" onClick={()=>controlDeleteModal(
              ()=>updateOrderStatus(order.receiptNumber, 'delete'), true)}>
                Delete
            </div>
          </div>
        )
    }
    if (order.billPayType === 'afterpay' && order.paymentStatus === 'N') {
      return (
        <div className="col-2 table__detail__normal">
          <div className="button button-small" onClick={()=>
            this.onPayClick(order.receiptNumber, order.amountTotal, order.addressID)}>
              Pay Now
          </div>
        </div>
      )
    }
  }

  render() {
    const { orderList, loading } = this.props
    return (
      <div className="user__content">
        <div className="table__line table__header">
          <div className="col-2">Order</div>
          <div className="col-1">Type</div>
          <div className="col-2">Time</div>
          <div className="col-2">Order Total</div>
          <div className="col-2">Invoice Total</div>
          <div className="col-1">Status</div>
          <div className="col-2">Operate</div>
        </div>
        {
          orderList.orders.length > 0 && orderList.orders.map((order) => (
            <div key={order.billID} className="table__line table__detail">
              <div className="col-2 table__detail__normal">
                <Link to={"/user/order/detail/" + order.receiptNumber} className="table__detail__link">
                  {order.receiptNumber}
                </Link>
              </div>
              <div className="col-1 table__detail__normal">
                {order.billPayType}
              </div>
              <div className="col-2 table__detail__normal">
                {order.billGenerateTime}
              </div>
              <div className="col-2 table__detail__normal product__obvious">
                ${order.originalTotal ? order.originalTotal.toFixed(2) : order.amountTotal.toFixed(2)}
              </div>
              <div className="col-2 table__detail__normal product__obvious">
              {
                order.billStatus == 'delivering' || order.billStatus == 'finished'
                ? ('$' + order.amountTotal.toFixed(2)) : ''
              }
              </div>
              <div className="col-1 table__detail__normal">
                {orderStatusMap[order.billStatus]}
              </div>
              {loading ? '' : this.getCurrentOperate(order)}
            </div>
          ))
        }
        {
          this.state.showModal ?
          <div>
            <div className="modal-backdrop"></div>
            <div className="modal">
              <div className="modal-body">
                <p className="reminder-text">Thanks for shopping!</p>
                <p className="reminder-text">
                  Please click "OK" after receiving the goods, otherwise the money may be lost!
                </p>
              </div>
              <div className="modal-footer">
                <span className="button button-small item-left" onClick={()=>
                  {this.completeOrder(this.state.curReceiptNo)}}>OK</span>
                <span className="button button-small" onClick={this.closeModal}>No</span>
              </div>
            </div>
          </div>
          : ''
        }
        <DeleteConfirm />
        <Pagination curPage={orderList.curPage} allPage={orderList.allPage} pagePathParent='/user/order/' />
      </div>
    )
  }
}