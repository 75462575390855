import React from 'react'
import Layout from '@components/Layout'

export default class AboutUs extends React.Component {

  render() {
    return (
      <Layout>
        <div className="about">
          <h2>About Us</h2>
          <div className="about__block">
          <p><label className="text-obvious">MOTARRO</label> is a warehouse based in Hamilton (New Zealand) that specializes in providing office art and craft supplies. With over 1500 items in stock, customers can find a wide range of products to meet their needs. The warehouse has a massive area of 10000 square meters, allowing them to store sufficient inventory to ensure they can fulfill orders quickly.</p>
          <p>Whether you're looking for stationery, painting supplies, or other office art and craft supplies, <label className="text-obvious">MOTARRO</label> has it all. We understand the importance of having the right tools and materials to get the job done correctly. Our vast selection of products caters to professionals and hobbyists alike, providing everyone with the tools they need to succeed.</p>
          <p><label className="text-obvious">MOTARRO</label> takes pride in providing quality products and exceptional customer service. Our team of experts is always available to help customers find the right products and answer any questions they may have. We also prioritize fast and efficient shipping, ensuring that customers receive their orders in a timely manner.</p>
          <p>Overall, <label className="text-obvious">MOTARRO</label> is a reliable and trusted warehouse for all your office art and craft needs. With their vast selection of products, ample storage space, and commitment to customer satisfaction, we are sure to provide you with an exceptional shopping experience.</p>
          </div>
        </div>
      </Layout>
    )
  }
}