import React from 'react'
import Layout from '@components/Layout'

interface IState {
  showTerms: boolean
}

export default class PrivacyPolicy extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props)
    this.state = {
      showTerms: false
    }
  }

  onExpandClick = () => {
    let showTerms = !this.state.showTerms
    this.setState({showTerms: showTerms})
  }

  render() {
    return (
      <Layout>
        <div className="about">
          <h2>PrivacyPolicy</h2>
          <div className="about__block">
            <h3>TERMS & CONDITIONS</h3>
            <h3>Products & Prices:</h3>
            <p>All products and services are subjected to availability and may be withdrawn at any time. We reserve the right to change any prices at anytime.</p>
            <p>Although, We do our best to ensure prices stated on the website are accurate, errors do occur, Or…… we will not be bound by the incorrect pricing stated and reserve the right to cancel your purchase.</p>
            <h3>Legal:</h3>
            <p>All written and image contents in this website are the property of Motarro.  Unauthorized, fraudulent or otherwise unlawful use of this website is expressly prohibited.  Please contact us should you wish to use any part of the site’s contents with permission.</p>
            <h3>Privacy:</h3>
            <p>Your privacy is very important to Motarro.  We have established and implemented information handling practices that we believe are consistent with the highest standards and best practice of organization doing business on the internet. This privacy policy describes the practice that applys to our site, including, specifically, the information we collect about you, when and how we collect that information and what may happen to that information.</p>
            <ul>
              <li>We will not collect personally identifiable information like your name, e-mail and mailing address and telephone number (“personal information”) without your knowledge and permission.</li>
              <li>We will not disclose your personal information to third parties unless you have authorized us to do so.</li>
              <li>We will take reasonable steps to protect the security of the personal information we collect from you.</li>
            </ul>
            <h3 className="about__obvious">Your Personal Information:</h3>
            <p>Motarro does not collect personally identifiable information on our website, such as your name, address, e-mail address or telephone number, without your knowledge. Such information is collected only when you knowingly and voluntarily submit it to us (as, for example, when you contact us via e-mail). Motarro uses such voluntarily submitted information for the purposes for which it was submitted. When you place an order at Motarro you will be asked to provide certain information about yourself, such as your name name, mailing address, e-mail address. The personal information you submit when placing your order is treated as confidential. We use this information to process your transaction, and we share the information with Visa, MasterCard, etc. in order to process your order.</p>
            <h3>We use cookies:</h3>
            <p>Cookies allow Motarro to remember you. If we didn’t use cookies you would have to enter your details each time you visited. If you really prefer not to use cookies you can set your browser preferences to not accept cookies. Most websites however including Motarro require cookies to process your orders.</p>
            <h3>Trends, Tracking and Sharing Information:</h3>
            <p>Motarro uses standard software for monitoring statistical information such as how many visitors we have to our website and what countries they are coming from. These statistics are viewed on an aggregate basis and help us get general overviews of customers treads. We do not look at individual customer data. Personal information specific to each Vendor’s sales is treated as strictly confidential and will only be reviewed or examined in the event of a dispute, claim, error or customer request.</p>
            <h3>Security:</h3>
            <p>Motarro uses stringently tested technologies to ensure the security of information transmitted via our website and maintained in our possession. When you place your order “checkout” you will see a lock icon appear on the status bar of your browse. This indicates that the data that is submitted is encrypted while in transit and so cannot be viewed by anyone other then us. n addition, when we receieve that data, it is stored on a separate location to the web server so that it can only be accessed by us.</p>
            <h3>Links:</h3>
            <p>Our site may provide links to other web sites. When you click on one of these links, you are leaving our site and entering another site that we do not control and for which we cannot be responsible. You should carefully review the privacy statements or policies on any other site that you visit because those privacy statements or policies will apply to your visit to that site and may be very different from our policy</p>
            <h3>Discounts:</h3>
            <p>The discount coupon cannot be used in conjuction with another discount promotion code.</p>
            <p>Cash back promotions are refunded back into account used to purchase the goods and cannot be refunded in cash.</p>
            <h3>Custom Printing:</h3>
            <p>Motarro would like to help our customers create products with designs that are meaningful and unifying. Our customers have diverse viewpoints and perspectives which reflect in the designs they create. When a design does not meet our standards we will work with the customer to try to create an alternate design that complies. If a design proposed by a customer may be offensive or threatening to others who view the design, changes may be required for the design or a full cancellation for the order will be required. We will not be able to print any pictures or text that lead or support to the following:</p>
            <ul>
              <li>Anti Social Activities</li>
              <li>Anti Discrimination</li>
              <li>Anti Religious</li>
              <li>Racism</li>
              <li>Pornography</li>
              <li>Terrorism</li>
            </ul>
          </div>
          <h3>Competition Content Terms & Conditions:</h3>
          <span className={this.state.showTerms ? ' icon-chevron-up' : ' icon-chevron-down'}
            onClick={this.onExpandClick}>  Click to View Details</span>
          {
            this.state.showTerms ? 
            <div className="about__inner">
              <h3>Motarro Content Terms & Conditions</h3>
              <h3>You agree that:</h3>
              <p>You are at least 18 years of age or if you are under 18 years of age, you have your parents or legal guardian’s permission to grant authorization for us to feature your Content.</p>
              <p>You are the parent / guardian with legal responsibility of any minors under 18 years of age  depicted in the Content.</p>
              <p>By entering this competition you acknowledge that the content is your own creation and you own or control all rights and does not infringe or viloate the rights of any third parties – including, but not limited to, intellectual property rights, copyrights, trademarks, right of publicity / privacy, patent, trade secrets or confidentiality obligations and have the appropriate permissions, rights, licenses, clearances and consent from any third-party or people / individuals appearing in the Content.</p>
              <p>The Content does not contain any other content that is or could be considered inappropriate, unsuitable or offensive, as determined at our sole discretion.</p>
              <h3>Our use of your Content:</h3>
              <p>You agree to grant us an unlimited, non-exclusive, worldwide, royalty-free, perpetual right and license to reproduce, edit, publish, broadcast, distribute and otherwise use your Content in connection with advertising, publicity, promotional and marketing activities related to Motarro, This includes but is not limited to, sharing and publishing your Content on social media pages, websites, newsletters, blogs, emails, mailers, signage, news media and other printed and online advertising. We may edit or modify your Content for formatting, navigation, integration or other purposes. We will endeavor to not materially distort your Content.</p>
              <p>You acknowledge our use of your Content is without compensation or payment and also grant us the right to publish your social profile information or other information you have submitted in connection with any use of your Content, including but not limited to your name, username, profile picture, likeness, original caption, biographical information and region.</p>
              <p>Use of your Content is at our sole discretion. We have no obligation to use or feature your Content. We reserve the right to remove or stop using any Content at any time, for any reason, Use of your Content does not imply endorsement or affiliation with you.</p>
              <h3>Copyright:</h3>
              <p>You will retain ownership, copyright and intellectual property rights that you have in relation to your Content. We will endeavour to credit you when your Content is published, reproduced or otherwise used, where possible.</p>
              <h3>Moderation:</h3>
              <p>We reserve the right to refuse Content, moderate or remove any Content submitted, for any reason at any time, Any Content which may be deemed libelous, defamatory, obscene, pornographic, abusive, indecent, threatening, harassing, hateful, or offensive or otherwise unlawful will be immediately moderated.</p>
              <p>We reserve the right to verify the validity of submissions and to remove any submissions where false or misleading details have been given by an Author or if an Author has behaved in a fraudulent or dishonest manner, or otherwise than in accordance with these Terms of Use.</p>
              <h3>Limitation of liability:</h3>
              <p>You hereby release, discharge and agree to hold Motarro harmless from any liability related in any way to the use of your content.</p>
              <h3>Additional Terms & Conditions:</h3>
              <p>The use of your Content is in no way sponsored, endorsed , administered by or associated with any social platforms including Facebook, Twitter, Snapchat or Instagram.  We reserve the right to update these Terms of Use from time-to-time without notice by posting revised Terms of Use on this website.</p>
              <h3>These Terms of Use are governed by the laws of New Zealand.</h3>
            </div> : ''
          }
        </div>
      </Layout>
    )
  }
}