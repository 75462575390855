import request from '../../../util/request'
import { API_URL } from '../../../config'
import { RouteComponentProps } from 'react-router-dom'
import { Dispatch } from 'redux'
import { iOrder } from '../../../types'
import { iOrderListRouterParams } from '../../../types/router'
import { setLoading } from '../../../common/loading/action'

export const GET_ORDER_LIST = "GET_ORDER_LIST"
export type GET_ORDER_LIST = typeof GET_ORDER_LIST

export interface IGET_ORDER_LISTAction {
  type: GET_ORDER_LIST,
  orders: Array<iOrder>,
  orderMap: iOrderMap,
  curPage: string,
  allPage: string
}

interface iOrderListResponse {
  datas: Array<iOrder>,
  curPage: string,
  allPage: string
}

export interface iOrderMap {
  [key: string]: iOrder
}

export function getOrderList(props: RouteComponentProps<iOrderListRouterParams>) {
  return (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    let curPage: string = props.match.params.curPage || '1'
    let orderMap: iOrderMap = {}
    request(
      `${API_URL}/basic/webstore/bill/select?curPage=${curPage}`
    ).then((res: iOrderListResponse) => {
      res.datas.map((order) => {
        orderMap[order.receiptNumber] = order
      })
      dispatch({
        type: GET_ORDER_LIST,
        orders: res.datas,
        orderMap: orderMap,
        curPage: res.curPage,
        allPage: res.allPage
      })
      dispatch(setLoading(false))
    }, function() {
      dispatch(setLoading(false))
    })
  }
}
