export function isEmailFormatValid(email: string) {
  let reg: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  return reg.test(email)
}

export function purchaseDisable(stock: number, purQuantity: number, lowStockThreshold: number, pickupOnly?: boolean) {
  if (stock > lowStockThreshold) {
    if (purQuantity > (stock - lowStockThreshold)) {
      return true
    }
  } else {
    return true
  }
  if (localStorage.getItem('branchID') === '' && pickupOnly) {
    return true
  }
}

export function getFixedQuantity(stock: number, lowStockThreshold: number, addedQuantity: number, numberEachBatch: number, ) {
  // 购买数量按每包数量向上取整
  var fixedQuantity = Math.ceil(addedQuantity / numberEachBatch) * numberEachBatch
  if (purchaseDisable(stock, fixedQuantity, lowStockThreshold)) {
    // 库存不够向下取整
    fixedQuantity = Math.floor((stock - lowStockThreshold) / numberEachBatch) * numberEachBatch
  }
  return fixedQuantity
}