import React from 'react'
import { iDeleteModal } from './store'

interface IProps {
  deleteModal: iDeleteModal,
  controlDeleteModal: (onDelete: () => void, showModal: boolean) => void,
  confirmDelete: () => void
}

export default class DeleteConfirm extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
  }

  render() {
    const { deleteModal, controlDeleteModal, confirmDelete } = this.props
    return (
      <div>
      {
        deleteModal.showModal ?
          <div>
            <div className="modal">
              <div className="modal-body">
                <p className="reminder-text">Make sure to delete?</p>
              </div>
              <div className="modal-footer">
                <span className="button button-small item-left" onClick={confirmDelete}>Yes</span>
                <span className="button button-small" onClick={()=>
                  controlDeleteModal(() => {}, false)}>No</span>
              </div>
            </div>
            <div className="modal-backdrop"></div>
          </div> : ''
      }
      </div>
    )
  }
}