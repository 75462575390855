import React from 'react'
import { iProductDetail } from '../../types'
import { iProductRouterParams } from '../../types/router'
import request from '../../util/request'
import { URL, API_URL } from '../../config'
import CategoryConnect from '../category/index'
import Layout from '@components/Layout'
import { iProductPart } from './reducer'
import { RouteComponentProps } from 'react-router-dom'
import { purchaseDisable, getFixedQuantity } from '../../util/util'
import { displayPromotion } from '../../util/promotion'
import { iConfig, iBasicConfig } from '../configStore'
import { getProductBranchNotInBranch } from '../branchStore'

export interface IProps extends RouteComponentProps<iProductRouterParams> {
  product: iProductPart,
  setProductDetail: (product: iProductDetail) => void,
  onIncrement: (numberEachBatch: number) => void,
  onDecrement: (numberEachBatch: number) => void,
  setValue: (value: number)=> void,
  addToCart: (productID: number, quantity: number) => void,
  loading: boolean,
  setLoading: (loading: boolean) => void,
  isClubPriceDisplay: (activityStatus: string) => boolean,
  config: iConfig,
  controlModal: (showModal: boolean, message: string) => void,
  setPrompt: (message: string)=> void
}

export default class ProductDetail extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
    props.setValue(1)
    props.setLoading(true)
    request(
      `${API_URL}/basic/wholesale/productBranch/select?productID=${props.match.params.productID}`
    ).then((res: iProductDetail) => {
      if (res.productBranch.length === 0) {
        res.productBranch[0] = getProductBranchNotInBranch(res.recommandPrice)
      }
      props.setValue(res.numberEachBatch)
      props.setProductDetail(res)
      props.setLoading(false)
    }, function() {
      props.setLoading(false)
    })
  }
  
  onChangeButtonClick = (type: string) => {
    let { product, config, onDecrement, onIncrement } = this.props
    let productInfo = product.productInfo
    let addedQuantity: number = product.addedQuantity
    let stock = product.productInfo.productBranch[0].quantity
    let futureQty: number = type === 'plus' ? (addedQuantity + productInfo.numberEachBatch) : (addedQuantity - productInfo.numberEachBatch)
    if (purchaseDisable(stock, futureQty, Number(config.basicConfig.lowStockThreshold))) {
      this.props.controlModal(true, 'Sorry, no enough stock!')
      return
    }
    switch (type) {
      case 'plus':
        onIncrement(productInfo.numberEachBatch)
        break
      case 'minus':
        onDecrement(productInfo.numberEachBatch)
        break
    }
  }

  onPurQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { product, controlModal, config } = this.props
    let stock = product.productInfo.productBranch[0].quantity
    let lowStockThreshold = Number(config.basicConfig.lowStockThreshold)
    let value = Number(e.target.value)
    if (isNaN(value)) {
      return
    }
    if (value === 0) {
      this.props.controlModal(true, "At least 1 piece")
      return
    }
    if (purchaseDisable(stock, value, lowStockThreshold)) {
      let quantityLeft = stock - lowStockThreshold
      controlModal(true, 'Sorry, no enough stock! Only ' + quantityLeft + ' left.')
      return
    }
    this.props.setValue(value)
  }

  onAddClick = (productID: number, addedQuantity: number, stock: number) => {
    const { lowStockThreshold } = this.props.config.basicConfig
    let numberEachBatch = this.props.product.productInfo.numberEachBatch
    if (purchaseDisable(stock, addedQuantity, Number(lowStockThreshold))) {
      this.props.controlModal(true, 'Sorry, no enough stock!')
      return
    }
    if (addedQuantity % numberEachBatch > 0) {
      let fixedQuantity = getFixedQuantity(stock, Number(lowStockThreshold), addedQuantity, numberEachBatch)
      this.props.setValue(fixedQuantity)
      this.props.setPrompt('Adjust quantity to ' + fixedQuantity + ' due to multiple of inner quantity and stock left.')
      this.props.addToCart(productID, fixedQuantity)
    } else {
      this.props.addToCart(productID, addedQuantity)
    }
  }

  goBack() {
    window.history.back()
  }

  displayItem = (label: keyof iBasicConfig) => {
    const { basicConfig } = this.props.config
    if (basicConfig[label] === 'true') {
      return true
    }
  }

  render() {
    const { product, isClubPriceDisplay, config } = this.props
    let productInfo = product.productInfo
    let productBranch = product.productInfo.productBranch
    let lowStockThreshold = Number(config.basicConfig.lowStockThreshold)
    return (
      <Layout>
        <CategoryConnect />
        <div className="content">
          <div className="content__back" onClick={this.goBack}>Back to List</div>
        {
          Object.keys(productInfo).length > 0 && productBranch && productBranch.length > 0
          ?
          <div className="content__product">
            <div className="content__product__pic">
              {
                productInfo.picPath
                ? <img src={URL + productInfo.picPath} />
                : <img src="../../styles/images/example-image.jpg" />
              }
              {
                purchaseDisable(productBranch[0].quantity, product.addedQuantity, lowStockThreshold)
                ? <div className="product__soldout"></div> : ''
              }
            </div>
            <div className="content__product__data">
              <div className="product__data__line line__name">{productInfo.productName}</div>
              {
                productBranch[0].promotionType
                ? <div className="product__data__line product__obvious">
                    <span>
                      <a className="icon-gift"></a>
                      {displayPromotion(productBranch[0])}
                    </span>
                  </div> : ''
              }
              {
                this.displayItem('sku')
                ? <div className="product__data__line">
                    <label>SKU:</label>
                    <span>{productInfo.productCode}</span>
                  </div> : ''
              }
              {
                this.displayItem('stock')
                ? <div className="product__data__line">
                    <label>Stock:</label>
                    <span>
                    {
                      productBranch[0].quantity > lowStockThreshold
                        ? (productBranch[0].quantity - lowStockThreshold) : 'Out Stock'
                    }
                    </span>
                  </div> : ''
              }
              <div className="product__data__line product__obvious">
                <label>Price:</label>
                <span className={isClubPriceDisplay(productBranch[0].activityStatus)
                  ? "product__price-deleted" : ''}>
                    ${productBranch[0].salePrice.toFixed(2)}
                </span>
              </div>
              {
                isClubPriceDisplay(productBranch[0].activityStatus)
                ? <div className="product__data__line product__obvious">
                    <label className="product__label-long">
                      Club Price:
                    </label>
                    <span>
                      <a className="icon-gift"></a>
                      ${productBranch[0].activityDiscountPrice}
                    </span>
                  </div> : ''
              }
              <div className="product__data__line">
                <label>Quantity:</label>
                <span className="product__quantity">
                  {
                    product.addedQuantity > productInfo.numberEachBatch
                    ? <span className="product__quantity__minus" onClick={()=>this.onChangeButtonClick('minus')}>-</span>
                    : <span className="product__quantity__minus button-disabled">-</span>
                  }
                  <input type="text" value={product.addedQuantity} onChange={this.onPurQuantityChange} />
                  <span className="product__quantity__plus" onClick={()=>this.onChangeButtonClick('plus')}>+</span>
                </span>
              </div>
              <div className="text-obvious">{product.promptMessage || ''}</div>
              {
                localStorage.getItem('branchID') === '' && productInfo.pickupOnly
                ? <div className="product__data__action">
                    <div className="pickup_image"></div>
                    <div className="pickup_tip">For Store Pickup Shopping only.<br />
                      Find a store, click and collect.</div>
                  </div>
                :
                <div className="button product__data__action" onClick={()=>
                  this.onAddClick(productInfo.productID, product.addedQuantity, productBranch[0].quantity)}>
                    Add to Cart
                </div>
              }
            </div>
            <div className="content__product__desc">
              <ul>
              {
                productInfo.productDesc && productInfo.productDesc.split('*').map((line) => (
                  <li>{line}</li>
                ))
              }
              </ul>
              <div>
              {
                productInfo.picPath
                ? <img src={URL + productInfo.picPath} width="600" />
                : <img src="../../styles/images/example-image.jpg" width="600" />
              }
              </div>
            </div>
          </div> : ''
        }
        </div>
      </Layout>
    );
  }
}