import React from 'react'
import { RouteComponentProps, Link } from 'react-router-dom'
import { URL } from '../../../config'
import { iOrderDetailParams } from '../../../types/router'
import { iOrderPart } from './reducer'
import { orderStatusMap } from '../orderList/OrderList'
import { iAddress } from '../../../types'
import { regionMap } from '../address/Address'
import { iUserPart } from '../userInfo/reducer'

interface iElementMap {
  [key: string]: JSX.Element
}

interface IProps extends RouteComponentProps<iOrderDetailParams> {
  order: iOrderPart,
  user: iUserPart,
  getOrderDetail: (receiptNumber: string) => void,
  directToPayment: (receiptNumber: string, amountTotal: number, address: iAddress) => void,
  loading: boolean,
  updateOrderStatus: (receiptNumber: string, status: string) => void
}

interface IState {
  showModal: boolean,
  modalType: string
}

interface IPaymentMap {
  [key: string]: string
}

const paymentMap: IPaymentMap = {
  Y: 'Paid',
  N: 'Unpaid'
}

export default class OrderDetail extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    props.getOrderDetail(props.match.params.receiptNumber)
    this.state = {
      showModal: false,
      modalType: ''
    }
  }

  openModal = (type: string) => {
    this.setState({showModal: true, modalType: type})
  }

  closeModal = () => {
    this.setState({showModal: false, modalType: ''})
  }

  updateOrder = (receiptNumber: string, status: string) => {
    this.props.updateOrderStatus(receiptNumber, status)
    this.closeModal()
  }

  goBack = () => {
    this.props.history.push('/user/order')
  }

  render() {
    const { order, directToPayment, loading, user } = this.props
    const { orderInfo, address, orderProductMap } = order
    const buttonMap: iElementMap = {
      'new': (
        <div className="bar__action">
          <div className="button button-small"
            onClick={()=>directToPayment(orderInfo.receiptNumber, orderInfo.amountTotal, address)}>
              Pay Now
          </div>
          <div className="bar__action__cancel" onClick={()=>this.openModal('cancel')}>Cancel</div>
        </div>
      ),
      'delivering': (
        <div className="button button-small" onClick={()=>this.openModal('finished')}>Complete</div>
      ),
      'afterNoPay': (
        <div className="button button-small"
          onClick={()=>directToPayment(orderInfo.receiptNumber, orderInfo.amountTotal, address)}>
            Pay Now
        </div>
      )
    }
    const modalMap: iElementMap = {
      'cancel': (
        <div className="modal">
          <div className="modal-body">
            <p className="reminder-text">You may need to make a new order, make sure to Cancel this order?</p>
          </div>
          <div className="modal-footer">
            <span className="button button-small item-left" onClick={()=>
              {this.updateOrder(orderInfo.receiptNumber, 'cancel')}}>Yes</span>
            <span className="button button-small" onClick={this.closeModal}>No</span>
          </div>
        </div>
      ),
      'finished': (
        <div className="modal">
          <div className="modal-body">
            <p className="reminder-text">Thanks for shopping!</p>
            <p className="reminder-text">
              Please click "OK" after receiving the goods, otherwise the money may be lost!
            </p>
          </div>
          <div className="modal-footer">
            <span className="button button-small item-left" onClick={()=>
              {this.updateOrder(orderInfo.receiptNumber, 'confirm')}}>OK</span>
            <span className="button button-small" onClick={this.closeModal}>No</span>
          </div>
        </div>
      ),
    }
    return (
      <div className="user__content user__order">
        <div className="user__order__title">
          <div>
            <label>Order Number:</label>
            <span>{orderInfo.receiptNumber || ''}</span>
          </div>
          <div>
            <label>Generate Time:</label>
            <span>{orderInfo.billGenerateTime || ''}</span>
          </div>
          <div>
            <span className="item-left">Status:</span>
            <span className="reminder-text">
              {orderStatusMap[orderInfo.billStatus] || ''}{orderInfo.billPayType == 'afterpay' ? ' (Afterpay)' : ''}
            </span>
          </div>
          <div>
            <span className="item-left">Payment:</span>
            <span className="reminder-text">
              {paymentMap[orderInfo.paymentStatus] || ''}
            </span>
          </div>
          <div>
            <div className="content__back" onClick={this.goBack}>Back to Orders</div>
          </div>
        </div>
        {
          loading ? '' :
          <div className="user__order__bar">
            <div>
              <div className="order__bar__line">
                <label>Recipient:</label>
                <span>
                {
                  (address.firstName || '') + ' ' + (address.lastName || '')
                  + (address.companyName ? (', ' + address.companyName) : '')
                }
                </span>
              </div>
              <div className="order__bar__line">
                <label>Phone:</label>
                <span>{address.mobilePhone || ''}</span>
              </div>
              <div className="order__bar__line">
                <label>Email:</label>
                <span>{address.email || ''}</span>
              </div>
              <div className="order__bar__line">
                <label>Address:</label>
                <span>
                {
                  (address.address || '')
                    + (address.townOrCity ? (', ' + address.townOrCity) : '')
                    + (address.stateOrCounty ? (', ' + regionMap[address.stateOrCounty]) : '')
                    + (address.postcode ? (', ' + address.postcode) : '')
                }
                </span>
              </div>
              {
                orderInfo.billStatus === 'delivering'
                ? <div className="order__bar__line">
                    <label>Delivery No.:</label>
                    <span>{orderInfo.deliveryNumber || ''}</span>
                  </div> : ''
              }
            </div>
            <div>
              {
                order.coupon
                ? <div className="order__bar__line">
                    <label>Coupon Saved:</label>
                    <span className="line__detail">-${order.coupon.value.toFixed(2)}</span>
                  </div> : ''
              }
              <div className="order__bar__line">
                <label>Freight:</label>
                <span className="line__detail">
                  ${orderInfo.freight ? orderInfo.freight.toFixed(2) : 0}
                </span>
              </div>
              <div className="order__bar__line">
                <label>Pay Total:</label>
                <span className="line__detail">
                  ${orderInfo.amountTotal ? orderInfo.amountTotal.toFixed(2) : 0}
                </span>
              </div>
              <div className="order__bar__line">
                (Includes ${orderInfo.taxTotal? orderInfo.taxTotal.toFixed(2) : 0} Tax)
              </div>
              {
              orderInfo.creditDeduct ?
                <div className="order__bar__line">
                  <label>Credit:</label>
                  <span className="line__detail">
                    -${orderInfo.creditDeduct}
                  </span>
                </div> : ''
              }
            </div>
            <div>
              {loading ? '' :
                (orderInfo.billPayType == 'afterpay' && orderInfo.paymentStatus == 'N'
                  ? buttonMap['afterNoPay']
                  : buttonMap[orderInfo.billStatus])}
            </div>
          </div>
        }
        <div>
          <div className="table__line table__header">
            <div className="col-5">Product</div>
            <div className="col-2">Quantity</div>
            <div className="col-2">Price</div>
            <div className="col-3">Total</div>
          </div>
          {
            orderInfo && orderInfo.billContents && orderInfo.billContents.map((content) => {
              let product = orderProductMap[content.productID]
              return (
                <div key={content.billContentID} className="table__line table__detail">
                  <div className="col-5 table__detail__name">
                    <Link to={'/product/detail/' + product.productID} target="_blank">
                      {
                        product.picPath
                        ? <img src={URL + product.picPath} width="80" />
                        : <img src="../../styles/images/example-image.jpg" width="80" />
                      }
                      <span>{content.productName}</span>
                    </Link>
                  </div>
                  <div className="col-2 table__detail__normal">
                    {content.quantity}
                  </div>
                  <div className="col-2 table__detail__normal product__obvious">
                    ${content.purchasePrice.toFixed(2)}
                  </div>
                  <div className="col-3 table__detail__normal product__obvious">
                    ${content.amount.toFixed(2)}
                  </div>
                </div>
              )
            })
          }
        </div>
        {
          this.state.showModal ?
          <div>
            <div className="modal-backdrop"></div>
            {modalMap[this.state.modalType]}
          </div>
          : ''
        }
      </div>
    )
  }
}