import React from 'react'
import Header from '@components/Header'
import Bar from '../containers/bar'
import Footer from './Footer'
import MenuConnect from '../containers/menu/index'
import Loading from '../common/loading/index'
import BackToTop from '@components/BackToTop'
import GlobalModal from '../common/globalModal/GlobalModal'

export default class Layout extends React.Component {

  render() {
    return (
      <div>
        <Header />
        <Bar />
        <MenuConnect />
        { this.props.children }
        <BackToTop />
        <Footer />
        <Loading />
        <GlobalModal />
      </div>
    );
  }
}