import { iProduct } from '../../types'
import { ListAction, GET_LIST_PRODUCTS } from './action'

export interface iList {
  products: Array<iProduct>,
  curPage: string,
  allPage: string
}

export default (
  state = {products: [], curPage: null, allPage: null}, action: ListAction
) => {
  switch (action.type) {
    case GET_LIST_PRODUCTS:
      return Object.assign({}, state, {products: action.products, curPage: action.curPage, allPage: action.allPage})
    default:
      return state
  }
}