import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../../../configureReducer'
import Password from './Password'
import { getUserInfo } from '../userInfo/action'
import { setLoading } from '../../../common/loading/action'

const mapStateToProps = (state: iAppState) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators ({
  getUserInfo,
  setLoading
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Password)