import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../../../configureReducer'
import { changeAddressItem } from './action'
import Address from './Address'

const mapStateToProps = (state: iAppState) => ({
  address: state.address
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators ({
  changeAddressItem
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Address)