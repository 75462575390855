import { Dispatch } from 'redux'
import request from '../util/request'
import { API_URL, WHOLESALE_BRANCH_ID } from '../config'
import { iProductBranch } from 'types'

export interface iBranchInfo {
  branchID: number,
  branchName: string,
  tradingName: string,
  branchGSTNo: string,
  managerID: number,
  contactNo: string,
  contactEmail: string,
  bankAccountNo: string,
  webUrl: string
}

const GET_BRANCHES = "GET_BRANCHES"
type GET_BRANCHES = typeof GET_BRANCHES

interface IGET_BRANCHESAction {
  type: GET_BRANCHES,
  branches: Array<iBranchInfo>
}

const GET_BRANCH = "GET_BRANCH"
type GET_BRANCH = typeof GET_BRANCH

interface IGET_BRANCHAction {
  type: GET_BRANCH,
  branch: iBranchInfo
}

export type iBranches = Array<iBranchInfo>

export interface iBranch {
  branches: iBranches,
  branch: iBranchInfo
}

export function getBranches() {
  return (dispatch: Dispatch) => {
    request(
      `${API_URL}/basic/webstore/branch/select`
    ).then((branches: iBranches) => {
      branches.sort(function(a: iBranchInfo, b: iBranchInfo){
        let x = a.branchName.toLowerCase()
        let y = b.branchName.toLowerCase()
        if (x < y) {return -1}
        if (x > y) {return 1}
        return 0
      })
      dispatch({
        type: GET_BRANCHES,
        branches
      })
    })
  }
}

export function getBranch() {
  return (dispatch: Dispatch) => {
    request(
      `${API_URL}/basic/webstore/branch/select?branchID=${WHOLESALE_BRANCH_ID}`
    ).then((branch: iBranchInfo) => {
      dispatch({
        type: GET_BRANCH,
        branch
      })
    })
  }
}

export let condition: string = ''
let branchID = localStorage.getItem('branchID')
if (branchID) {
  condition = `branchID=${branchID}`
}

export function getProductBranchNotInBranch(recommandPrice: number) {
  let productBranch: iProductBranch = {
    promotionType: '',
    quantity: 0,
    salePrice: recommandPrice,
    activityStatus: 'inactive',
    activityDiscountPrice: 0,
    salesTotalNumber: 0,
    promotionSaleBuyDiscountD: 0,
    promotionSaleBuyDiscountN: 0,
    promotionSaleBuyGiveM: 0,
    promotionSaleBuyGiveN: 0,
    promotionSaleDiscount: 0,
    promotionSaleSpecial: 0,
    buyGiveOtherBuyNum: 0,
    buyGiveOtherGiveNum: 0,
    buyGiveOtherGiveProducts: '',
    promotionSaleStatus: false,
  }
  return productBranch
}

type BranchAction = IGET_BRANCHAction | IGET_BRANCHESAction

export default (
  state = {branches: [], branch: []}, action: BranchAction
) => {
  switch (action.type) {
    case GET_BRANCHES:
      return Object.assign({}, state, {branches: action.branches})
    case GET_BRANCH:
      return Object.assign({}, state, {branch: action.branch})
    default:
      return state
  }
}