import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { iAppState } from '../../../configureReducer'
import { getOrderDetail, updateOrderStatus } from './action'
import OrderDetail from './OrderDetail'
import { directToPayment } from '../../payment/action'

const mapStateToProps = (state: iAppState) => ({
  order: state.order,
  loading: state.loading,
  user: state.user,
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getOrderDetail,
  directToPayment,
  updateOrderStatus
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail)