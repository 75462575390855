import React from 'react'
import { iUserPart } from './reducer'

interface IProps {
  user: iUserPart
  getUserInfo: () => void
}

export default class UserInfo extends React.Component<IProps> {
  constructor(props: IProps) {
    props.getUserInfo()
    super(props)
  }

  render() {
    const { user } = this.props
    const { member, card, wholesale } = user.userInfo
    return (
      <div className="user__content">
        {
          user.hasGotUserInfo
          ?
          <div className="user__basic">
            <div className="user__basic__member">
              <div className="user__basic__line">
                <label>Name:</label>
                <span>{member.firstName + ' ' + member.surName}</span>
              </div>
              <div className="user__basic__line">
                <label>Email:</label>
                <span>{member.email}</span>
              </div>
              <div className="user__basic__line">
                <label>Date:</label>
                <span>{card.setupDate}</span>
              </div>
            </div>
            <div className="user__basic__point">
              <div className="user__basic__line">
                <label>Company Name:</label>
                <span>{wholesale.companyName}</span>
              </div>
              <div className="user__basic__line">
                <label>Trading Name:</label>
                <span>{wholesale.tradingName}</span>
              </div>
              <div className="user__basic__line">
                <label>Phone No.:</label>
                <span>{wholesale.phoneNumber}</span>
              </div>
              <div className="user__basic__line">
                <label>Postal Address:</label>
                <span>{wholesale.postalAddress}</span>
              </div>
              <div className="user__basic__line">
                <label>Current  Credit:</label>
                <span>${wholesale.credit}</span>
              </div>
            </div>
          </div> : ''
        }
      </div>
    )
  }
}